import { useContext } from 'react';
import { useQuery } from 'react-query';
import { SettingsContext } from '../store/SettingsContext';
import { UserContext } from '../store/UserContext';
import useAxios from './useAxios';
import { SextforceAutoMassDmMessage } from './useMessageUsersMassDmList';
import useSubscriber from './useSubscriber';

const useMessageUsersMassDm = (massDmId: number) => {
    const userContext = useContext(UserContext);
    const settingsContext = useContext(SettingsContext);
    const { data: subscriber } = useSubscriber();
    const axios = useAxios();

    // Fetch Subscriber's Mass DM
    const fetchMassDm = async (): Promise<SextforceAutoMassDmMessage | null> => {
        const query: string = `${settingsContext.routes.massDm.base}${subscriber._id}/list/${massDmId}`;

        return axios
            .get(query)
            .then(response => response.data)
            .catch(() => {
                return null;
            });
    };

    const subscriberLists = useQuery(['subscriberMassDm', subscriber?._id, massDmId], () => fetchMassDm(), {
        refetchOnWindowFocus: false,
        staleTime: Infinity,
        enabled: userContext.jwtToken && settingsContext.apiKey && subscriber && subscriber._id ? true : false,
    });

    return { ...subscriberLists };
};

export default useMessageUsersMassDm;
