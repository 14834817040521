import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ClearIcon from '@mui/icons-material/Clear';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import PhotoLibraryIcon from '@mui/icons-material/PhotoLibrary';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import RefreshIcon from '@mui/icons-material/Refresh';
import SortIcon from '@mui/icons-material/Sort';
import {
    Box,
    Button,
    Chip,
    CircularProgress,
    Divider,
    Grid,
    IconButton,
    MenuItem,
    Stack,
    SxProps,
    Theme,
    Tooltip,
    Typography,
    useMediaQuery,
} from '@mui/material';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { OnlyFansVaultMedias } from '../../../../hooks/useSubscriberVaultAlbums';
import useSubscriberVaultMedias, { VaultMediaResponse, VaultMediaResponseList } from '../../../../hooks/useSubscriberVaultMedias';
import StyledLocalMenu from '../../../common/StyledLocalMenu';
import MessageUsersFormAddMediasSelectorGoToDateDialog from './MessageUsersFormAddMediasSelectorGoToDateDialog';
import MessageUsersFormAddMediasSelectorImageList from './MessageUsersFormAddMediasSelectorImageList';

const MediaTypeSelector = (props: {
    mediaType: OnlyFansVaultMedias.MediaType | null;
    handleChangeMediaType: (mediaType: OnlyFansVaultMedias.MediaType | null) => void;
    sx?: SxProps<Theme>;
}) => {
    const { mediaType, handleChangeMediaType, sx } = props;

    return (
        <Stack direction="row" spacing={0.5} alignItems="center" justifyContent={'flex-start'} sx={{ ...sx }}>
            <Chip label="All" color={mediaType === null ? 'primary' : 'default'} onClick={() => handleChangeMediaType(null)} />
            <Divider orientation="vertical" flexItem />
            <Chip
                label="Images"
                variant={mediaType === OnlyFansVaultMedias.MediaType.Photo ? 'filled' : 'outlined'}
                color={mediaType === OnlyFansVaultMedias.MediaType.Photo ? 'primary' : 'default'}
                onClick={() => handleChangeMediaType(OnlyFansVaultMedias.MediaType.Photo)}
            />
            <Chip
                label="Videos"
                variant={mediaType === OnlyFansVaultMedias.MediaType.Video ? 'filled' : 'outlined'}
                color={mediaType === OnlyFansVaultMedias.MediaType.Video ? 'primary' : 'default'}
                onClick={() => handleChangeMediaType(OnlyFansVaultMedias.MediaType.Video)}
            />
            <Chip
                label="Audio"
                variant={mediaType === OnlyFansVaultMedias.MediaType.Audio ? 'filled' : 'outlined'}
                color={mediaType === OnlyFansVaultMedias.MediaType.Audio ? 'primary' : 'default'}
                onClick={() => handleChangeMediaType(OnlyFansVaultMedias.MediaType.Audio)}
            />
            <Chip
                label="GIFs"
                variant={mediaType === OnlyFansVaultMedias.MediaType.GIF ? 'filled' : 'outlined'}
                color={mediaType === OnlyFansVaultMedias.MediaType.GIF ? 'primary' : 'default'}
                onClick={() => handleChangeMediaType(OnlyFansVaultMedias.MediaType.GIF)}
            />
        </Stack>
    );
};

type Props = {
    serviceName: string;
    albumId: number;
    albumName: string;
    medias: OnlyFansVaultMedias.RestructuredResponseItem[];
    setMedias: React.Dispatch<React.SetStateAction<OnlyFansVaultMedias.RestructuredResponseItem[]>>;
    mediasPreviews: number[];
    setMediasPreviews: React.Dispatch<React.SetStateAction<number[]>>;
    albumMedias: VaultMediaResponseList[];
    setAlbumMedias: React.Dispatch<React.SetStateAction<VaultMediaResponseList[]>>;
    albumHasMore: boolean;
    setAlbumHasMore: React.Dispatch<React.SetStateAction<boolean>>;
    thumbnails: any[];
    getThumbnail: (thumbId: number, thumbUrl: string) => void;
    getAudio: (url: string) => Promise<Blob | null>;
    tagCreators: boolean;
    setTagCreators: (tagCreators: boolean) => void;
    taggedCreators: { id: number; name: string }[];
    setTaggedCreators: React.Dispatch<React.SetStateAction<{ id: number; name: string }[]>>;
    taggedReleaseForms: { id: number; name: string }[];
    setTaggedReleaseForms: React.Dispatch<React.SetStateAction<{ id: number; name: string }[]>>;
    theme: Theme;
    disabled?: boolean;
};

const MessageUsersFormAddMediasSelector = (props: Props) => {
    const {
        serviceName,
        albumId,
        albumName,
        medias,
        setMedias,
        mediasPreviews,
        setMediasPreviews,
        albumMedias,
        setAlbumMedias,
        albumHasMore,
        setAlbumHasMore,
        thumbnails,
        getThumbnail,
        getAudio,
        tagCreators,
        setTagCreators,
        taggedCreators,
        setTaggedCreators,
        taggedReleaseForms,
        setTaggedReleaseForms,
        theme,
        disabled,
    } = props;

    const isLargeScreen = useMediaQuery(theme.breakpoints.up('sm'));

    // Sort Menu
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const sortMenuOpen = Boolean(anchorEl);
    const [goToDateDialogOpen, setGoToDateDialogOpen] = useState(false);
    const [sortDate, setSortDate] = useState<Date | null>(null);
    const [sortType, setSortType] = useState<OnlyFansVaultMedias.SortType>(OnlyFansVaultMedias.SortType.Recent);
    const [sortOrder, setSortOrder] = useState<OnlyFansVaultMedias.SortOrder>(OnlyFansVaultMedias.SortOrder.Desc);

    const onGoToDate = (date: Date | null) => {
        setSortDate(date);
        setGoToDateDialogOpen(false);
        handleSortMenuClose();
    };

    const handleSortMenuClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleSortMenuClose = () => {
        setAnchorEl(null);
    };

    // Album Medias Type Filter
    const [mediaType, setMediaType] = useState<OnlyFansVaultMedias.MediaType | null>(null);

    const {
        data: vaultMediasRaw,
        isLoading: vaultAlbumLoading,
        isFetching: vaultAlbumFetching,
        refetch: refetchMedias,
        hasNextPage: vaultMediasHasNextPage,
        fetchNextPage: fetchNextVaultMediasPage,
    } = useSubscriberVaultMedias(serviceName, albumId, mediaType, sortDate, sortType, sortOrder, albumId !== -1 ? true : false);

    useEffect(() => {
        if (!vaultMediasRaw || !vaultMediasRaw.pages) {
            setAlbumMedias([]);
            return;
        }

        const newResponse: VaultMediaResponse = {
            list: [],
            hasMore: vaultMediasRaw ? vaultMediasRaw.pages[vaultMediasRaw.pages.length - 1].hasMore : false,
            nextOffset: vaultMediasRaw ? vaultMediasRaw.pages[vaultMediasRaw.pages.length - 1].nextOffset : null,
        };

        // Join all the pages list results into one array and use the last page's hasMore
        vaultMediasRaw.pages.forEach((page: VaultMediaResponse) => {
            newResponse.list = [...newResponse.list, ...page.list];
        });

        if (newResponse && newResponse.list) {
            setAlbumMedias(newResponse.list);
            setAlbumHasMore(newResponse.hasMore);

            const mediasKeys = Object.keys(thumbnails);

            newResponse.list.forEach(item => {
                if (item.type !== 'audio' && !item.cachedThumb && !mediasKeys.includes(item.id.toString())) {
                    getThumbnail(item.id, item.thumb);
                }
            });
        }
    }, [vaultMediasRaw]);

    // useEffect(() => {
    // console.log('MessageUsersFormAddMediasSelector useEffect');
    // setMedias([]);
    // setMediasPreviews([]);
    // setAlbumMedias([]);
    // setAlbumHasMore(false);
    // }, [albumId, setMedias, setMediasPreviews]);

    useEffect(() => {
        // setAlbumMedias([]);
        // setAlbumHasMore(false);
    }, [sortDate, sortType, sortOrder]);

    const handleMediasSelectAllClicked = () => {
        const missingMentionedUsers: { id: number; name: string }[] = [];
        const missingReleaseForms: { id: number; name: string }[] = [];

        // Add missing mentioned users and release forms to taggedCreators and taggedReleaseForms
        albumMedias.forEach(albumMedia => {
            if (!medias.find(media => media.id === albumMedia.id)) {
                setMedias(prevMedias => {
                    return [
                        ...prevMedias,
                        {
                            id: albumMedia.id,
                            type: albumMedia.type,
                            src: albumMedia.src || '',
                            cachedThumb: albumMedia.cachedThumb,
                            createdAt: albumMedia.createdAt,
                            counters: albumMedia.counters,
                            duration: albumMedia.duration || 0,
                            canView: albumMedia.canView,
                            hasError: albumMedia.hasError,
                            thumb: albumMedia.thumb,
                        },
                    ];
                });

                // Does taggedCreators already have these mentionedUsers?
                if (albumMedia.mentionedUsers && albumMedia.mentionedUsers.length > 0) {
                    for (let i = 0; i < albumMedia.mentionedUsers.length; i += 1) {
                        const mentionedUser = albumMedia.mentionedUsers[i];

                        if (
                            !taggedCreators.find(taggedCreator => taggedCreator.id === mentionedUser.id) &&
                            !missingMentionedUsers.find(missingMentionedUser => missingMentionedUser.id === mentionedUser.id)
                        ) {
                            missingMentionedUsers.push({ id: mentionedUser.id, name: mentionedUser.name });
                        }
                    }
                }

                // Add any missing releaseFormsUsers to taggedCreators
                const releaseFromsUsers =
                    albumMedia.releaseForms && albumMedia.releaseForms.filter(releaseForm => releaseForm.type === 'user');

                if (releaseFromsUsers && releaseFromsUsers.length > 0) {
                    for (let i = 0; i < releaseFromsUsers.length; i += 1) {
                        const releaseFormUser = releaseFromsUsers[i].user;

                        if (
                            !taggedCreators.find(taggedCreator => taggedCreator.id === releaseFormUser.id) &&
                            !missingMentionedUsers.find(missingMentionedUser => missingMentionedUser.id === releaseFormUser.id)
                        ) {
                            missingMentionedUsers.push({ id: releaseFormUser.id, name: releaseFormUser.name });
                        }
                    }
                }

                // Only tag creators if the releaseForm is a document
                const releaseFormsDocuments =
                    albumMedia.releaseForms && albumMedia.releaseForms.filter(releaseForm => releaseForm.type === 'document');

                if (releaseFormsDocuments && releaseFormsDocuments.length > 0) {
                    // Does taggedReleaseForms already have these releaseFormsFiltered?
                    for (let i = 0; i < releaseFormsDocuments.length; i += 1) {
                        const releaseForm = releaseFormsDocuments[i];

                        if (
                            !taggedReleaseForms.find(taggedReleaseForm => taggedReleaseForm.id === releaseForm.id) &&
                            !missingReleaseForms.find(missingReleaseForm => missingReleaseForm.id === releaseForm.id)
                        ) {
                            missingReleaseForms.push({ id: releaseForm.id, name: releaseForm.name });
                        }
                    }
                }
            }
        });

        if (!tagCreators && (missingMentionedUsers.length > 0 || missingReleaseForms.length > 0)) {
            setTagCreators(true);
        }

        // Add the missing mentionedUsers to taggedCreators
        if (missingMentionedUsers.length > 0) {
            setTaggedCreators(prevTaggedCreators => {
                return [...prevTaggedCreators, ...missingMentionedUsers];
            });
        }

        // Add the missing releaseFormsFiltered to taggedReleaseForms
        if (missingReleaseForms.length > 0) {
            setTaggedReleaseForms(prevTaggedReleaseForms => {
                return [...prevTaggedReleaseForms, ...missingReleaseForms];
            });
        }
    };

    const handleMediasClearSelectionClicked = () => {
        setMedias([]);
        setMediasPreviews([]);
        setTagCreators(false);
        setTaggedCreators([]);
    };

    const handleChangeMediaType = (mediaType: OnlyFansVaultMedias.MediaType | null) => {
        setAlbumHasMore(false);
        setAlbumMedias([]);
        setMediaType(mediaType);
    };

    return (
        <>
            <Grid container spacing={0} flexGrow={0} alignItems="center" justifyContent="center" sx={{ mt: 1, mb: 0 }}>
                <Grid item xs={12}>
                    <Grid container spacing={1} alignItems="center" sx={{ mb: 1 }}>
                        <Grid item xs={'auto'}>
                            <PhotoLibraryIcon />
                        </Grid>
                        <Grid item xs>
                            <Typography variant="h6">{albumId === 0 ? 'All' : albumName}</Typography>
                        </Grid>
                        <Grid item xs={'auto'}>
                            <MessageUsersFormAddMediasSelectorGoToDateDialog
                                open={goToDateDialogOpen}
                                onClose={() => {
                                    setGoToDateDialogOpen(false);
                                    handleSortMenuClose();
                                }}
                                date={sortDate}
                                onGoToDate={onGoToDate}
                            />
                            <Tooltip title="Sort">
                                <span>
                                    <IconButton
                                        onClick={handleSortMenuClick}
                                        disabled={disabled || vaultAlbumLoading || vaultAlbumFetching}
                                        color="info"
                                    >
                                        <SortIcon fontSize="large" />
                                    </IconButton>
                                </span>
                            </Tooltip>
                            <StyledLocalMenu
                                id="album-sort-menu"
                                MenuListProps={{
                                    'aria-labelledby': 'album-sort-button',
                                }}
                                anchorEl={anchorEl}
                                open={sortMenuOpen}
                                onClose={handleSortMenuClose}
                            >
                                <MenuItem
                                    onClick={() => {
                                        setSortDate(null);
                                        handleSortMenuClose();
                                    }}
                                    disableRipple
                                >
                                    {sortDate ? <RadioButtonUncheckedIcon /> : <RadioButtonCheckedIcon />}
                                    All Time
                                </MenuItem>
                                <MenuItem
                                    onClick={() => {
                                        setGoToDateDialogOpen(true);
                                    }}
                                    disableRipple
                                >
                                    {sortDate ? <RadioButtonCheckedIcon /> : <RadioButtonUncheckedIcon />}
                                    {sortDate ? moment(sortDate).format('L') : 'Go to date'}
                                </MenuItem>
                                <Divider sx={{ my: 0.5 }} />
                                <MenuItem
                                    onClick={() => {
                                        setSortType(OnlyFansVaultMedias.SortType.Recent);
                                        handleSortMenuClose();
                                    }}
                                    disableRipple
                                >
                                    {sortType === 'recent' ? <RadioButtonCheckedIcon /> : <RadioButtonUncheckedIcon />}
                                    Recent
                                </MenuItem>
                                <MenuItem
                                    onClick={() => {
                                        setSortType(OnlyFansVaultMedias.SortType.MostLiked);
                                        handleSortMenuClose();
                                    }}
                                    disableRipple
                                >
                                    {sortType === 'most-liked' ? <RadioButtonCheckedIcon /> : <RadioButtonUncheckedIcon />}
                                    Most liked
                                </MenuItem>
                                <MenuItem
                                    onClick={() => {
                                        setSortType(OnlyFansVaultMedias.SortType.HighestTips);
                                        handleSortMenuClose();
                                    }}
                                    disableRipple
                                >
                                    {sortType === 'highest-tips' ? <RadioButtonCheckedIcon /> : <RadioButtonUncheckedIcon />}
                                    Highest tips
                                </MenuItem>
                                <Divider sx={{ my: 0.5 }} />
                                <MenuItem
                                    onClick={() => {
                                        setSortOrder(OnlyFansVaultMedias.SortOrder.Asc);
                                        handleSortMenuClose();
                                    }}
                                    disableRipple
                                >
                                    {sortOrder === 'asc' ? <RadioButtonCheckedIcon /> : <RadioButtonUncheckedIcon />}
                                    Ascending
                                </MenuItem>
                                <MenuItem
                                    onClick={() => {
                                        setSortOrder(OnlyFansVaultMedias.SortOrder.Desc);
                                        handleSortMenuClose();
                                    }}
                                    disableRipple
                                >
                                    {sortOrder === 'desc' ? <RadioButtonCheckedIcon /> : <RadioButtonUncheckedIcon />}
                                    Descending
                                </MenuItem>
                            </StyledLocalMenu>
                        </Grid>
                        <Grid item xs={'auto'}>
                            <Tooltip title="Select All">
                                <span>
                                    <IconButton
                                        onClick={async () => {
                                            handleMediasSelectAllClicked();
                                        }}
                                        disabled={disabled || vaultAlbumLoading || vaultAlbumFetching}
                                        color="primary"
                                    >
                                        <DoneAllIcon fontSize="large" />
                                    </IconButton>
                                </span>
                            </Tooltip>
                        </Grid>
                        <Grid item xs={'auto'}>
                            <Tooltip title="Clear Selection">
                                <span>
                                    <IconButton
                                        color="error"
                                        onClick={() => {
                                            handleMediasClearSelectionClicked();
                                        }}
                                        disabled={disabled || vaultAlbumLoading || vaultAlbumFetching}
                                    >
                                        <ClearIcon fontSize="large" />
                                    </IconButton>
                                </span>
                            </Tooltip>
                        </Grid>
                        <Grid item xs={'auto'}>
                            <Tooltip title="Reload Album content from OnlyFans">
                                <span>
                                    <IconButton
                                        onClick={async () => {
                                            const reset = async () => {
                                                setAlbumHasMore(false);
                                                setAlbumMedias([]);
                                            };

                                            await reset();
                                            refetchMedias();
                                        }}
                                        color="secondary"
                                        disabled={disabled || vaultAlbumLoading || vaultAlbumFetching}
                                    >
                                        <RefreshIcon fontSize="large" />
                                    </IconButton>
                                </span>
                            </Tooltip>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={12}>
                    <Grid container spacing={1} alignItems="center">
                        <Grid item xs>
                            {isLargeScreen && <MediaTypeSelector mediaType={mediaType} handleChangeMediaType={handleChangeMediaType} />}
                            {!isLargeScreen && <Typography color="text.secondary">Click to select</Typography>}
                        </Grid>
                        <Grid item xs={'auto'}>
                            <Chip
                                color={medias.length === 0 || medias.length > 40 ? 'error' : 'primary'}
                                icon={<CheckCircleIcon />}
                                label={`${medias.length}/40 selected`}
                                disabled={disabled}
                            />
                        </Grid>
                    </Grid>
                </Grid>

                {!isLargeScreen && (
                    <Grid item xs={12}>
                        <MediaTypeSelector
                            mediaType={mediaType}
                            handleChangeMediaType={handleChangeMediaType}
                            sx={{ justifyContent: 'space-between' }}
                        />
                    </Grid>
                )}

                <Grid item xs={12}>
                    <Grid container spacing={0} alignItems="center" sx={{ mb: 0 }}>
                        <Grid item xs={12}>
                            <Grid container spacing={0} flexGrow={0} alignItems="center" justifyContent="center" sx={{ mb: 0 }}>
                                {albumMedias && albumMedias.length > 0 && (
                                    <Grid item xs={12}>
                                        <MessageUsersFormAddMediasSelectorImageList
                                            albumMedias={albumMedias}
                                            thumbnails={thumbnails}
                                            medias={medias}
                                            setMedias={setMedias}
                                            mediasPreviews={mediasPreviews}
                                            setMediasPreviews={setMediasPreviews}
                                            getAudio={getAudio}
                                            tagCreators={tagCreators}
                                            setTagCreators={setTagCreators}
                                            taggedCreators={taggedCreators}
                                            setTaggedCreators={setTaggedCreators}
                                            taggedReleaseForms={taggedReleaseForms}
                                            setTaggedReleaseForms={setTaggedReleaseForms}
                                            theme={theme}
                                            disabled={disabled}
                                        />
                                    </Grid>
                                )}

                                {(vaultAlbumLoading || vaultAlbumFetching) && (
                                    <Grid item xs={12}>
                                        <Box textAlign={'center'} mt={albumMedias && albumMedias.length > 0 ? 0 : 2}>
                                            <Typography variant="caption">
                                                Retrieving your Album content...
                                                <br />
                                                <br />
                                                This might take a while depending on how many albums you have and OnlyFans servers speed 🤷‍♂️
                                                <br />
                                                <br />
                                                <CircularProgress size={24} />
                                            </Typography>
                                        </Box>
                                    </Grid>
                                )}

                                {!vaultAlbumLoading && !vaultAlbumFetching && albumHasMore && (
                                    <Grid item xs={12}>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            fullWidth
                                            size="large"
                                            disabled={!vaultMediasHasNextPage || disabled}
                                            onClick={() => {
                                                // refetchMedias();
                                                fetchNextVaultMediasPage();
                                            }}
                                        >
                                            Load More
                                        </Button>
                                    </Grid>
                                )}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};

export default MessageUsersFormAddMediasSelector;
