import RefreshIcon from '@mui/icons-material/Refresh';
import SortByAlphaIcon from '@mui/icons-material/SortByAlpha';
import {
    Divider,
    FormControl,
    Grid,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
    SelectChangeEvent,
    Stack,
    Theme,
    Tooltip,
    useTheme,
} from '@mui/material';
import { decode } from 'he';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useQueryClient } from 'react-query';
import { OnlyFansSubscriber } from '../../hooks/useSubscriber';
import useSubscriberMedia from '../../hooks/useSubscriberMedia';
import useSubscriberVaultAlbums, { OnlyFansVaultAlbums, OnlyFansVaultMedias } from '../../hooks/useSubscriberVaultAlbums';
import { VaultMediaResponseList } from '../../hooks/useSubscriberVaultMedias';
import MessageUsersFormAddMediasRemoveSelected from '../services/messageUsers/sendMessage/MessageUsersFormAddMediasRemoveSelected';
import MessageUsersFormAddMediasSelector from '../services/messageUsers/sendMessage/MessageUsersFormAddMediasSelector';

type Props = {
    serviceName: string;
    subscriber: OnlyFansSubscriber;
    medias: OnlyFansVaultMedias.RestructuredResponseItem[];
    setMedias: React.Dispatch<React.SetStateAction<OnlyFansVaultMedias.RestructuredResponseItem[]>>;
    mediasPreviews: number[];
    setMediasPreviews: React.Dispatch<React.SetStateAction<number[]>>;
    thumbnails: any[];
    setThumbnails: React.Dispatch<React.SetStateAction<any[]>>;
    tagCreators: boolean;
    setTagCreators: React.Dispatch<React.SetStateAction<boolean>>;
    taggedCreators: {
        id: number;
        name: string;
    }[];
    setTaggedCreators: React.Dispatch<
        React.SetStateAction<
            {
                id: number;
                name: string;
            }[]
        >
    >;
    taggedReleaseForms: {
        id: number;
        name: string;
    }[];
    setTaggedReleaseForms: React.Dispatch<
        React.SetStateAction<
            {
                id: number;
                name: string;
            }[]
        >
    >;
    selectSize: 'small' | 'medium';
};

const OnlyFansMediaSelector = (props: Props) => {
    const {
        serviceName,
        subscriber,
        medias,
        setMedias,
        mediasPreviews,
        setMediasPreviews,
        thumbnails,
        setThumbnails,
        tagCreators,
        setTagCreators,
        taggedCreators,
        setTaggedCreators,
        taggedReleaseForms,
        setTaggedReleaseForms,
        selectSize,
    } = props;

    const theme: Theme = useTheme();
    const queryClient = useQueryClient();

    const {
        data: vaultAlbums,
        fetchNextPage: fetchNextPageAlbums,
        hasNextPage: vaultAlbumHasNextPage,
        isFetchingNextPage: vaultAlbumIsFetchingNextPage,
        refetch: refetchAlbums,
        isLoading: vaultAlbumsLoading,
        isRefetching: vaultAlbumIsRefetching,
    } = useSubscriberVaultAlbums(true);

    const { getThumbnail: getSubscriberThumbnail, getAudio: getSubscriberAudio } = useSubscriberMedia();

    const [sortAlbums, setSortAlbums] = useState<boolean>(true);
    const [sortedAlbums, setSortedAlbums] = useState<OnlyFansVaultAlbums.List[] | undefined>([]);
    const [vaultAlbum, setVaultAlbum] = useState<number>(-1);
    const [vaultAlbumName, setVaultAlbumName] = useState<string | undefined>();
    const [albumMedias, setAlbumMedias] = useState<VaultMediaResponseList[]>([]);
    const [albumHasMore, setAlbumHasMore] = useState<boolean>(false);

    // Memoized sorted albums
    const processedAlbums = useMemo(() => {
        if (!vaultAlbums?.pages) return [];

        const allAlbums = vaultAlbums.pages.flatMap(page => page.list);

        if (!sortAlbums) return allAlbums;

        return [...allAlbums].sort((a, b) => a.name.localeCompare(b.name));
    }, [vaultAlbums, sortAlbums]);

    // Update sorted albums when processed albums change
    useEffect(() => {
        setSortedAlbums(processedAlbums);
    }, [processedAlbums]);

    // Fetch next page of albums when needed
    useEffect(() => {
        if (vaultAlbumHasNextPage && !vaultAlbumIsFetchingNextPage) {
            fetchNextPageAlbums();
        }
    }, [vaultAlbumHasNextPage, vaultAlbumIsFetchingNextPage, fetchNextPageAlbums]);

    // Handle refresh
    const handleRefresh = useCallback(() => {
        queryClient.removeQueries('vaultAlbums');
        setVaultAlbum(-1);
        setVaultAlbumName(undefined);
        setSortedAlbums([]);
        refetchAlbums({});
    }, [queryClient, refetchAlbums]);

    const isLoading = vaultAlbumsLoading || vaultAlbumIsFetchingNextPage || vaultAlbumHasNextPage || vaultAlbumIsRefetching;

    const getThumbnail = (thumbId: number, thumbUrl: string) => {
        getSubscriberThumbnail(subscriber, thumbId, thumbUrl)?.then(blob => {
            if (blob) {
                setThumbnails(prevState => {
                    return [...prevState, { thumbId, blob }];
                });
            } else {
                setThumbnails(prevState => {
                    return [...prevState, { thumbId, blob: null }];
                });
            }
        });
    };

    const getAudio = (url: string) => getSubscriberAudio(subscriber, url);

    return (
        <Stack direction="column" spacing={0}>
            <Grid container spacing={1} flexGrow={1} alignItems="center">
                <Grid item xs>
                    <FormControl fullWidth>
                        <InputLabel id="vaultAlbum">Vault Album</InputLabel>
                        <Select
                            labelId="vaultAlbum"
                            defaultValue={sortedAlbums && sortedAlbums.length > 0 ? sortedAlbums[0].id : -1}
                            value={vaultAlbum}
                            disabled={vaultAlbumsLoading || vaultAlbumIsFetchingNextPage}
                            onChange={(e: SelectChangeEvent<any>) => {
                                if (vaultAlbum !== e.target.value) {
                                    setVaultAlbum(e.target.value);
                                    e.target.value !== -1 &&
                                        vaultAlbum &&
                                        setVaultAlbumName(
                                            e.target.value === 0 ? 'All' : sortedAlbums?.find(item => item.id === e.target.value)?.name,
                                        );
                                    setAlbumMedias([]);
                                    setAlbumHasMore(false);
                                }
                            }}
                            fullWidth
                            size={selectSize}
                            label="Vault Album"
                            error={vaultAlbum === -1}
                        >
                            <MenuItem value={-1} disabled={isLoading}>
                                {isLoading
                                    ? `Loading Albums... ${
                                          vaultAlbums?.pages.length ? `(${(vaultAlbums.pages.length * 10).toLocaleString()})` : ''
                                      }`
                                    : 'Select Album'}
                            </MenuItem>

                            {!isLoading && (
                                <MenuItem value={0}>
                                    <strong>All</strong>
                                </MenuItem>
                            )}
                            {!isLoading &&
                                sortedAlbums &&
                                sortedAlbums.map(album => (
                                    <MenuItem value={album.id} key={album.id}>
                                        <div style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>{decode(album.name)}</div>
                                    </MenuItem>
                                ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={'auto'}>
                    <Tooltip title="Sort albums alphabetically or custom like on OnlyFans">
                        <span>
                            <IconButton
                                onClick={() => {
                                    setSortAlbums(prevValue => !prevValue);
                                }}
                            >
                                <SortByAlphaIcon fontSize="large" color={sortAlbums ? 'primary' : 'secondary'} />
                            </IconButton>
                        </span>
                    </Tooltip>
                </Grid>
                <Grid item xs={'auto'}>
                    <Tooltip title="Reload Albums from OnlyFans">
                        <span>
                            <IconButton color="secondary" onClick={handleRefresh}>
                                <RefreshIcon fontSize="large" />
                            </IconButton>
                        </span>
                    </Tooltip>
                </Grid>
            </Grid>

            {!isLoading && vaultAlbum !== -1 && vaultAlbumName && (
                <>
                    <Divider sx={{ mt: 2, mb: 1 }} />
                    <MessageUsersFormAddMediasSelector
                        serviceName={serviceName}
                        albumId={vaultAlbum}
                        albumName={vaultAlbumName}
                        medias={medias}
                        setMedias={setMedias}
                        mediasPreviews={mediasPreviews}
                        setMediasPreviews={setMediasPreviews}
                        albumMedias={albumMedias}
                        setAlbumMedias={setAlbumMedias}
                        albumHasMore={albumHasMore}
                        setAlbumHasMore={setAlbumHasMore}
                        thumbnails={thumbnails}
                        getThumbnail={getThumbnail}
                        getAudio={getAudio}
                        tagCreators={tagCreators}
                        setTagCreators={setTagCreators}
                        taggedCreators={taggedCreators}
                        setTaggedCreators={setTaggedCreators}
                        taggedReleaseForms={taggedReleaseForms}
                        setTaggedReleaseForms={setTaggedReleaseForms}
                        theme={theme}
                        disabled={false}
                    />
                </>
            )}

            {medias.length > 0 && (
                <MessageUsersFormAddMediasRemoveSelected
                    thumbnails={thumbnails}
                    medias={medias}
                    setMedias={setMedias}
                    mediasPreviews={mediasPreviews}
                    setMediasPreviews={setMediasPreviews}
                    theme={theme}
                    disabled={false}
                />
            )}
        </Stack>
    );
};

export default OnlyFansMediaSelector;
