import RefreshIcon from '@mui/icons-material/Refresh';
import SortByAlphaIcon from '@mui/icons-material/SortByAlpha';
import {
    Alert,
    Card,
    CardContent,
    Checkbox,
    FormControl,
    FormControlLabel,
    Grid,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
    SelectChangeEvent,
    Theme,
    Tooltip,
    Typography,
    useTheme,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import useSubscriberVaultAlbums, { OnlyFansVaultAlbums, OnlyFansVaultMedias } from '../../../../hooks/useSubscriberVaultAlbums';
import { VaultMediaResponseList } from '../../../../hooks/useSubscriberVaultMedias';
import MessageUsersFormAddMediasRemoveSelected from './MessageUsersFormAddMediasRemoveSelected';
import MessageUsersFormAddMediasSelector from './MessageUsersFormAddMediasSelector';

type Props = {
    serviceName: string;
    includeMedia: boolean;
    setIncludeMedia: (state: boolean) => void;
    vaultAlbum: number;
    setVaultAlbum: (albumId: number) => void;
    vaultAlbumName: string | undefined;
    setVaultAlbumName: (albumName: string | undefined) => void;
    existingMedias?: OnlyFansVaultMedias.RestructuredResponseItem[];
    medias: OnlyFansVaultMedias.RestructuredResponseItem[];
    setMedias: React.Dispatch<React.SetStateAction<OnlyFansVaultMedias.RestructuredResponseItem[]>>;
    mediasPreviews: number[];
    setMediasPreviews: React.Dispatch<React.SetStateAction<number[]>>;
    thumbnails: any[];
    getThumbnail: (thumbId: number, thumbUrl: string) => void;
    getAudio: (url: string) => Promise<Blob | null>;
    session?: any;
    tagCreators: boolean;
    setTagCreators: (tagCreators: boolean) => void;
    taggedCreators: { id: number; name: string }[];
    setTaggedCreators: React.Dispatch<React.SetStateAction<{ id: number; name: string }[]>>;
    taggedReleaseForms: { id: number; name: string }[];
    setTaggedReleaseForms: React.Dispatch<React.SetStateAction<{ id: number; name: string }[]>>;
    disabled?: boolean;
};

const MessageUsersFormAddMedias = (props: Props) => {
    const {
        serviceName,
        includeMedia,
        setIncludeMedia,
        vaultAlbum,
        setVaultAlbum,
        vaultAlbumName,
        setVaultAlbumName,
        existingMedias,
        medias,
        setMedias,
        mediasPreviews,
        setMediasPreviews,
        thumbnails,
        getThumbnail,
        getAudio,
        tagCreators,
        setTagCreators,
        taggedCreators,
        setTaggedCreators,
        taggedReleaseForms,
        setTaggedReleaseForms,
        session,
        disabled,
    } = props;
    const theme: Theme = useTheme();

    const {
        data: vaultAlbums,
        fetchNextPage: fetchNextPageAlbums,
        hasNextPage: vaultAlbumHasNextPage,
        isFetchingNextPage: vaultAlbumIsFetchingNextPage,
        refetch: refetchAlbums,
        isLoading: vaultAlbumsLoading,
    } = useSubscriberVaultAlbums(includeMedia);

    useEffect(() => {
        if (vaultAlbumHasNextPage && !vaultAlbumIsFetchingNextPage) {
            fetchNextPageAlbums();
        }
    }, [vaultAlbumHasNextPage, vaultAlbumIsFetchingNextPage, fetchNextPageAlbums]);

    const [sortAlbums, setSortAlbums] = useState<boolean>(true);
    const [sortedAlbums, setSortedAlbums] = useState<any[] | undefined>([]);
    const [albumMedias, setAlbumMedias] = useState<VaultMediaResponseList[]>([]);
    const [albumHasMore, setAlbumHasMore] = useState<boolean>(false);

    useEffect(() => {
        if (vaultAlbums) {
            if (sortAlbums) {
                // Copy the array to avoid mutating the original array
                const vaultAlbumsCopy: OnlyFansVaultAlbums.List[] = [];

                vaultAlbums.pages.forEach(page => {
                    page.list.forEach(album => {
                        vaultAlbumsCopy.push(album);
                    });
                });

                // Sort albums alphabetically
                setSortedAlbums(vaultAlbumsCopy.sort((a: any, b: any) => a.name.localeCompare(b.name)));
            } else {
                const unsortedAlbums: OnlyFansVaultAlbums.List[] = [];

                vaultAlbums.pages.forEach(page => {
                    page.list.forEach(album => {
                        unsortedAlbums.push(album);
                    });
                });

                setSortedAlbums(unsortedAlbums);
            }
        } else {
            setSortedAlbums([]);
        }
    }, [sortAlbums, vaultAlbums]);

    return (
        <>
            <Grid container spacing={1} flexGrow={0} alignItems="center" justifyContent="center" sx={{ marginBottom: 1 }}>
                <Grid item xs={12}>
                    <Card variant="elevation">
                        <CardContent>
                            <Grid container spacing={1} alignItems="center">
                                <Grid item xs>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                size="medium"
                                                checked={includeMedia}
                                                onChange={(e, checked) => {
                                                    setIncludeMedia(checked);
                                                }}
                                                disabled={disabled}
                                            />
                                        }
                                        label={<Typography variant="h6">Add Media?</Typography>}
                                    />
                                </Grid>
                                {includeMedia && !vaultAlbumsLoading && !vaultAlbumIsFetchingNextPage && (
                                    <>
                                        <Grid item xs={'auto'}>
                                            <Tooltip title="Sort albums alphabetically or custom like on OnlyFans">
                                                <span>
                                                    <IconButton
                                                        onClick={() => {
                                                            setSortAlbums(!sortAlbums);
                                                        }}
                                                        disabled={disabled}
                                                    >
                                                        <SortByAlphaIcon
                                                            fontSize="large"
                                                            color={disabled ? 'disabled' : sortAlbums ? 'primary' : 'secondary'}
                                                        />
                                                    </IconButton>
                                                </span>
                                            </Tooltip>
                                        </Grid>
                                        <Grid item xs={'auto'}>
                                            <Tooltip title="Reload Albums from OnlyFans">
                                                <span>
                                                    <IconButton
                                                        color="secondary"
                                                        onClick={() => {
                                                            refetchAlbums();
                                                        }}
                                                        disabled={disabled}
                                                    >
                                                        <RefreshIcon fontSize="large" />
                                                    </IconButton>
                                                </span>
                                            </Tooltip>
                                        </Grid>
                                    </>
                                )}
                            </Grid>

                            {session && session.payload && session.payload.mediaFiles && session.payload.mediaFiles.length > 0 && (
                                <Grid container spacing={1} alignItems="center" sx={{ marginTop: theme.spacing(1) }}>
                                    <Grid item xs={12}>
                                        <Alert severity="warning" variant="filled">
                                            Previous session's photo/video will not be included with this message. Please select the
                                            photo/video again or select a different photo/video.
                                        </Alert>
                                    </Grid>
                                </Grid>
                            )}

                            {(includeMedia || vaultAlbumIsFetchingNextPage) && (
                                <Grid
                                    container
                                    spacing={1}
                                    flexGrow={0}
                                    alignItems="center"
                                    justifyContent="center"
                                    sx={{ marginTop: 1, marginBottom: 1 }}
                                >
                                    <Grid item xs={12} flexGrow={0}>
                                        <>
                                            {sortedAlbums ? (
                                                <FormControl fullWidth disabled={disabled}>
                                                    <InputLabel id="vaultAlbum">Vault Album</InputLabel>
                                                    <Select
                                                        labelId="vaultAlbum"
                                                        defaultValue={sortedAlbums.length > 0 && sortedAlbums[0].id}
                                                        value={vaultAlbum}
                                                        disabled={vaultAlbumsLoading || vaultAlbumIsFetchingNextPage}
                                                        onChange={(e: SelectChangeEvent<any>) => {
                                                            if (vaultAlbum !== e.target.value) {
                                                                setVaultAlbum(e.target.value);
                                                                e.target.value !== -1 &&
                                                                    vaultAlbum &&
                                                                    setVaultAlbumName(
                                                                        e.target.value === 0
                                                                            ? 'All'
                                                                            : sortedAlbums.find(item => item.id === e.target.value)?.name,
                                                                    );
                                                                setAlbumMedias([]);
                                                                setAlbumHasMore(false);
                                                            }
                                                        }}
                                                        fullWidth
                                                        size="medium"
                                                        label="Vault Album"
                                                        error={vaultAlbum === -1}
                                                    >
                                                        <MenuItem
                                                            value={-1}
                                                            disabled={vaultAlbumsLoading || vaultAlbumIsFetchingNextPage}
                                                            sx={{ color: theme.palette.grey[800] }}
                                                        >
                                                            {vaultAlbumsLoading || vaultAlbumIsFetchingNextPage
                                                                ? `Loading Albums... ${
                                                                      vaultAlbums && vaultAlbums.pages.length > 0
                                                                          ? `(${(vaultAlbums.pages.length * 10).toLocaleString()})`
                                                                          : ''
                                                                  }`
                                                                : 'Select Album'}
                                                        </MenuItem>
                                                        {!vaultAlbumsLoading && !vaultAlbumIsFetchingNextPage && (
                                                            <MenuItem value={0}>
                                                                <strong>All</strong>
                                                            </MenuItem>
                                                        )}
                                                        {!vaultAlbumsLoading &&
                                                            !vaultAlbumIsFetchingNextPage &&
                                                            sortedAlbums &&
                                                            sortedAlbums.map((list: any) => (
                                                                <MenuItem value={list.id} key={list.id}>
                                                                    <div style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                                                        {list.name}
                                                                    </div>
                                                                </MenuItem>
                                                            ))}
                                                    </Select>
                                                </FormControl>
                                            ) : (
                                                <Alert severity="error">Could not load lists!</Alert>
                                            )}
                                        </>
                                    </Grid>
                                </Grid>
                            )}

                            {includeMedia &&
                                !vaultAlbumsLoading &&
                                !vaultAlbumIsFetchingNextPage &&
                                vaultAlbum !== -1 &&
                                vaultAlbumName && (
                                    <MessageUsersFormAddMediasSelector
                                        serviceName={serviceName}
                                        albumId={vaultAlbum}
                                        albumName={vaultAlbumName}
                                        medias={medias}
                                        setMedias={setMedias}
                                        mediasPreviews={mediasPreviews}
                                        setMediasPreviews={setMediasPreviews}
                                        albumMedias={albumMedias}
                                        setAlbumMedias={setAlbumMedias}
                                        albumHasMore={albumHasMore}
                                        setAlbumHasMore={setAlbumHasMore}
                                        thumbnails={thumbnails}
                                        getThumbnail={getThumbnail}
                                        getAudio={getAudio}
                                        tagCreators={tagCreators}
                                        setTagCreators={setTagCreators}
                                        taggedCreators={taggedCreators}
                                        setTaggedCreators={setTaggedCreators}
                                        taggedReleaseForms={taggedReleaseForms}
                                        setTaggedReleaseForms={setTaggedReleaseForms}
                                        theme={theme}
                                        disabled={disabled}
                                    />
                                )}

                            {medias.length > 0 && (
                                <MessageUsersFormAddMediasRemoveSelected
                                    thumbnails={thumbnails}
                                    medias={medias}
                                    setMedias={setMedias}
                                    mediasPreviews={mediasPreviews}
                                    setMediasPreviews={setMediasPreviews}
                                    theme={theme}
                                    disabled={disabled}
                                />
                            )}
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </>
    );
};

export default MessageUsersFormAddMedias;
