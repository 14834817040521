import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import { Grid, ImageList, ImageListItem, ImageListItemBar, Skeleton, Typography, useMediaQuery } from '@mui/material';
import { alpha, Theme } from '@mui/system';
import moment from 'moment';
import { useState } from 'react';
import { OnlyFansVaultMedias } from '../../../../hooks/useSubscriberVaultAlbums';
import { mediaTypeIcon, secondsToMinutesAndSecondsString } from '../../../../utils/common';
import AudioPlayerDialog from '../../../common/AudioPlayerDialog';

type Props = {
    thumbnails: any[];
    medias: OnlyFansVaultMedias.RestructuredResponseItem[];
    mediasPreviews: number[];
    setMediasPreviews: React.Dispatch<React.SetStateAction<number[]>>;
    getAudio: (url: string) => Promise<Blob | null>;
    theme: Theme;
    disabled?: boolean;
};

const MessageUsersPriceLockPreviewSelector = (props: Props) => {
    const { thumbnails, medias, mediasPreviews, setMediasPreviews, getAudio, theme, disabled } = props;
    const isLargeScreen = useMediaQuery(theme.breakpoints.up('sm'));

    const [playerOpen, setPlayerOpen] = useState(false);
    const [selectedAudioUrl, setSelectedAudioUrl] = useState('');

    const handleMediaClicked = (mediaId: number) => {
        if (mediasPreviews.includes(mediaId)) {
            setMediasPreviews((prevMedias: number[]) => {
                return prevMedias.filter(m => m !== mediaId);
            });
        } else {
            setMediasPreviews((prevMedias: number[]) => {
                return [...prevMedias, mediaId];
            });
        }
    };

    return (
        <>
            <ImageList cols={isLargeScreen ? 4 : 3} rowHeight={164}>
                {medias.map(media => (
                    <ImageListItem
                        key={media.id}
                        sx={{
                            cursor: disabled ? 'default' : 'pointer',
                            borderStyle: 'solid',
                            borderWidth: '2px',
                            borderColor: medias.find(m => m.id === media.id) ? theme.palette.secondary.main : theme.palette.common.white,
                            ':hover': disabled
                                ? {}
                                : {
                                      borderStyle: 'solid',
                                      borderWidth: '2px',
                                      borderColor: theme.palette.primary.main,
                                  },
                            ...(disabled && {
                                opacity: 0.5,
                            }),
                        }}
                    >
                        {media.type !== 'audio' ? (
                            media.cachedThumb ? (
                                <img
                                    src={media.cachedThumb}
                                    alt={media.id.toString()}
                                    loading="lazy"
                                    height={150}
                                    width={150}
                                    onClick={() => {
                                        if (!disabled) {
                                            handleMediaClicked(media.id);
                                        }
                                    }}
                                />
                            ) : thumbnails.find(thumbnail => thumbnail.thumbId === media.id && thumbnail.blob) ? (
                                <img
                                    src={URL.createObjectURL(
                                        thumbnails.find(thumbnail => thumbnail.thumbId === media.id && thumbnail.blob).blob,
                                    )}
                                    alt={media.id.toString()}
                                    loading="lazy"
                                    height={150}
                                    width={150}
                                    onClick={() => {
                                        if (!disabled) {
                                            handleMediaClicked(media.id);
                                        }
                                    }}
                                />
                            ) : (
                                <Skeleton />
                            )
                        ) : (
                            <Grid
                                container
                                justifyContent="center"
                                alignItems="center"
                                sx={{
                                    backgroundColor: theme.palette.grey[300],
                                    height: '100%',
                                }}
                                onClick={() => {
                                    if (!disabled) {
                                        handleMediaClicked(media.id);
                                    }
                                }}
                            >
                                <Grid item xs={12} sx={{ paddingBottom: theme.spacing(5) }}>
                                    <Grid container justifyContent="center" alignItems="center">
                                        <Grid item xs={12}>
                                            <div style={{ textAlign: 'center' }}>
                                                <PlayCircleIcon
                                                    onClick={e => {
                                                        e.stopPropagation();

                                                        if (media.src && !disabled) {
                                                            setSelectedAudioUrl(media.src);
                                                            setPlayerOpen(true);
                                                        }
                                                    }}
                                                    fontSize="large"
                                                    sx={{
                                                        ':hover': {
                                                            color: theme.palette.primary.main,
                                                        },
                                                        height: theme.spacing(4),
                                                    }}
                                                />
                                            </div>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <div style={{ textAlign: 'center' }}>
                                                <Typography variant="body2" sx={{ color: theme.palette.grey[600] }}>
                                                    {secondsToMinutesAndSecondsString(media.duration || 0)}
                                                </Typography>
                                            </div>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        )}
                        <ImageListItemBar
                            title={moment(media.createdAt).calendar({
                                sameDay: '[Today]',
                                nextDay: '[Tomorrow]',
                                nextWeek: 'MMM Mo',
                                lastDay: '[Yesterday]',
                                lastWeek: 'MMM Mo',
                                sameElse: 'MMM Mo',
                            })}
                            subtitle={`${media.counters.likesCount.toLocaleString()} Likes`}
                            actionIcon={mediaTypeIcon(media.type, theme)}
                            onClick={() => {
                                if (!disabled) {
                                    handleMediaClicked(media.id);
                                }
                            }}
                            sx={{
                                backgroundColor: mediasPreviews.includes(media.id) ? alpha(theme.palette.secondary.main, 0.8) : '#00000080',
                                '.MuiImageListItemBar-actionIcon': {
                                    marginRight: theme.spacing(1),
                                },
                            }}
                        />
                    </ImageListItem>
                ))}
            </ImageList>
            <AudioPlayerDialog open={playerOpen} setOpen={setPlayerOpen} url={selectedAudioUrl} getAudio={getAudio} />
        </>
    );
};

export default MessageUsersPriceLockPreviewSelector;
