import LoadingButton from '@mui/lab/LoadingButton';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    Grid,
    Stack,
    Theme,
    Typography,
    useMediaQuery,
    useTheme,
} from '@mui/material';
import { useDialog } from 'muibox';
import { useSnackbar } from 'notistack';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';
import useAxios from '../../../../../hooks/useAxios';
import useSextforceBigBrotherRestrictedWords from '../../../../../hooks/useSextforceBigBrotherRestrictedWords';
import { OnlyFansMetrics } from '../../../../../hooks/useSextforceMetricsCampaignsOverview';
import useSextforceMetricsWelcomeMessage from '../../../../../hooks/useSextforceMetricsWelcomeMessage';
import useSubscriberMedia from '../../../../../hooks/useSubscriberMedia';
import { OnlyFansVaultMedias } from '../../../../../hooks/useSubscriberVaultAlbums';
import { SettingsContext } from '../../../../../store/SettingsContext';
import { UserContext } from '../../../../../store/UserContext';
import {
    createEmptyOnlyFansSubscriberMessageUsersAutoMessageDetails,
    OnlyFansSubscriberMessageUsersAutoMessageDetails,
} from '../../../../../types/messageUsersAutoSendWelcomeMessage';
import { handleHttpError, loadDraftsJSEditorStateFromHtml } from '../../../../../utils/common';
import { getRestrictedWordsInMessage } from '../../../../../utils/messageUsers';
import StyledCardTitleBar from '../../../../common/StyledCardTitleBar';
import MessageUsersFormAddMedias from '../../../messageUsers/sendMessage/MessageUsersFormAddMedias';
import MessageUsersFormEditor from '../../../messageUsers/sendMessage/MessageUsersFormEditor';
import MessageUsersPriceLock from '../../../messageUsers/sendMessage/MessageUsersPriceLock';
import MessageUsersTagCreators from '../../../messageUsers/sendMessage/MessageUsersTagCreators';

type Props = {
    subscriber: any;
    trial: OnlyFansMetrics;
    open: boolean;
    onClose: () => void;
};

const SextforceMetricsTrialsWelcomeMessageDialog = (props: Props) => {
    const { subscriber, trial, open, onClose } = props;
    const userContext = useContext(UserContext);
    const settingsContext = useContext(SettingsContext);
    const theme: Theme = useTheme();
    const isLargeScreen = useMediaQuery(theme.breakpoints.up('sm'));
    const params = useParams();
    const dialog = useDialog();
    const { enqueueSnackbar } = useSnackbar();
    const axios = useAxios();
    const queryClient = useQueryClient();

    // Restricted words
    const { data: restrictedWords, isLoading: isLoadingRestrictedWords } = useSextforceBigBrotherRestrictedWords();

    // Welcome Message
    const { data: welcomeMessage } = useSextforceMetricsWelcomeMessage(trial._id);

    // Loading state
    const [isSaving, setIsSaving] = useState(false);

    // Message details
    const [messageDetails, setMessageDetails] = useState<OnlyFansSubscriberMessageUsersAutoMessageDetails>(
        trial.welcomeMessage?.messageDetails || createEmptyOnlyFansSubscriberMessageUsersAutoMessageDetails(true),
    );

    // Media
    const { getThumbnail: getSubscriberThumbnail, getAudio: getSubscriberAudio } = useSubscriberMedia();

    // Form
    const [includeMedia, setIncludeMedia] = useState<boolean>(messageDetails.mediaFiles.length > 0 ? true : false);
    const [vaultAlbum, setVaultAlbum] = useState<number>(-1);
    const [medias, setMedias] = useState<OnlyFansVaultMedias.RestructuredResponseItem[]>(messageDetails.mediaFiles || []);
    const [vaultAlbumName, setVaultAlbumName] = useState<string | undefined>(undefined);
    const [mediaFilesPreviews, setMediaFilesPreviews] = useState<number[]>(messageDetails.mediaFilesPreviews || []);
    const [thumbnails, setThumbnails] = useState<any[]>([]);
    const [tagCreators, setTagCreators] = useState<boolean>(messageDetails.taggedCreators.length > 0 ? true : false);
    const [taggedCreators, setTaggedCreators] = useState<{ id: number; name: string }[]>(messageDetails.taggedCreators || []);
    const [taggedReleaseForms, setTaggedReleaseForms] = useState<{ id: number; name: string }[]>(messageDetails.taggedReleaseForms || []);
    const [lockMessage, setLockMessage] = useState<boolean>(
        messageDetails.lockMessagePrice !== null && messageDetails.lockMessagePrice !== 0 ? true : false,
    );
    const [lockMessagePrice, setLockMessagePrice] = useState<number>(messageDetails.lockMessagePrice || 0);
    const [lockedText, setLockedText] = useState<boolean>(messageDetails.lockedText || false);
    const [replaceUsername, setReplaceUsername] = useState<string>(messageDetails.replaceUsername || '');
    const [editorState, setEditorState] = useState(loadDraftsJSEditorStateFromHtml(messageDetails.message));
    const [message, setMessage] = useState<string>(messageDetails.message || '');
    const [messageHasRestrictedWords, setMessageHasRestrictedWords] = useState<boolean>(false);
    const [messageFoundRestrictedWords, setMessageFoundRestrictedWords] = useState<string[]>([]);
    const [saveTemplate, setSaveTemplate] = useState<boolean>(false);
    const [saveTemplateName, setSaveTemplateName] = useState<string>('');

    const taggedCreatorsCount = messageDetails.taggedCreators.length;
    const taggedReleaseFormsCount = messageDetails.taggedReleaseForms ? messageDetails.taggedReleaseForms.length : 0;
    const totalTagged = taggedCreatorsCount + taggedReleaseFormsCount;

    useEffect(() => {
        if (open && welcomeMessage) {
            setMessageDetails(welcomeMessage?.messageDetails || createEmptyOnlyFansSubscriberMessageUsersAutoMessageDetails(true));
            setIncludeMedia(welcomeMessage?.messageDetails?.mediaFiles.length > 0 ? true : false);
            setVaultAlbum(-1);
            setVaultAlbumName(undefined);
            setMedias(welcomeMessage?.messageDetails?.mediaFiles || []);
            setMediaFilesPreviews(welcomeMessage?.messageDetails?.mediaFilesPreviews || []);
            setThumbnails([]);
            setTagCreators(
                welcomeMessage &&
                    (welcomeMessage.messageDetails?.taggedCreators.length > 0 ||
                        (welcomeMessage.messageDetails?.taggedReleaseForms && welcomeMessage.messageDetails?.taggedReleaseForms.length > 0))
                    ? true
                    : false,
            );
            setTaggedCreators(welcomeMessage ? welcomeMessage.messageDetails?.taggedCreators : []);
            setTaggedReleaseForms(
                welcomeMessage && welcomeMessage.messageDetails?.taggedReleaseForms
                    ? welcomeMessage.messageDetails?.taggedReleaseForms
                    : [],
            );
            setLockMessage(
                welcomeMessage &&
                    welcomeMessage.messageDetails?.lockMessagePrice !== null &&
                    welcomeMessage.messageDetails.lockMessagePrice !== 0
                    ? true
                    : false,
            );
            setLockMessagePrice(welcomeMessage ? welcomeMessage.messageDetails?.lockMessagePrice || 0 : 0);
            setLockedText(welcomeMessage ? welcomeMessage.messageDetails?.lockedText || false : false);
            setReplaceUsername(welcomeMessage ? welcomeMessage.messageDetails?.replaceUsername || '' : '');
            setEditorState(loadDraftsJSEditorStateFromHtml(welcomeMessage ? welcomeMessage.messageDetails?.message : ''));
            setMessage(welcomeMessage ? welcomeMessage.messageDetails?.message : '');
            setMessageHasRestrictedWords(false);
            setMessageFoundRestrictedWords([]);
            setSaveTemplate(false);
            setSaveTemplateName('');
        }
    }, [welcomeMessage, open]);

    // Check the message doesn't contain any OnlyFans restricted words
    const checkForRestrictedWords = useCallback(
        (text: string) => {
            if (text && !isLoadingRestrictedWords && restrictedWords && subscriber) {
                const restrictedWordsFound = getRestrictedWordsInMessage(subscriber, text, restrictedWords);

                setMessageHasRestrictedWords(restrictedWordsFound.length > 0 ? true : false);
                setMessageFoundRestrictedWords(restrictedWordsFound);
            } else {
                setMessageHasRestrictedWords(false);
                setMessageFoundRestrictedWords([]);
            }
        },
        [isLoadingRestrictedWords, restrictedWords, subscriber],
    );

    useEffect(() => {
        setMessageDetails(prevState => {
            return {
                ...prevState,
                mediaFiles: medias,
            };
        });
    }, [medias]);

    useEffect(() => {
        setMessageDetails(prevState => {
            return {
                ...prevState,
                mediaFilesPreviews,
            };
        });
    }, [mediaFilesPreviews]);

    useEffect(() => {
        setMessageDetails(prevState => {
            return {
                ...prevState,
                taggedCreators: taggedCreators,
            };
        });
    }, [taggedCreators]);

    useEffect(() => {
        setMessageDetails(prevState => {
            return {
                ...prevState,
                taggedReleaseForms: taggedReleaseForms,
            };
        });
    }, [taggedReleaseForms]);

    useEffect(() => {
        setMessageDetails(prevState => {
            return {
                ...prevState,
                lockedText,
            };
        });
    }, [lockedText]);

    useEffect(() => {
        setMessageDetails(prevState => {
            return {
                ...prevState,
                lockMessagePrice,
            };
        });
    }, [lockMessagePrice]);

    useEffect(() => {
        setMessageDetails(prevState => {
            return {
                ...prevState,
                replaceUsername,
            };
        });
    }, [replaceUsername]);

    useEffect(() => {
        setMessageDetails(prevState => {
            return {
                ...prevState,
                message,
            };
        });
    }, [message]);

    // Media
    const getThumbnail = (thumbId: number, thumbUrl: string) => {
        getSubscriberThumbnail(subscriber, thumbId, thumbUrl)?.then(blob => {
            if (blob) {
                setThumbnails(prevState => {
                    return [...prevState, { thumbId, blob }];
                });
            } else {
                setThumbnails(prevState => {
                    return [...prevState, { thumbId, blob: null }];
                });
            }
        });
    };

    const getAudio = (url: string) => getSubscriberAudio(subscriber, url);

    // Save the message details
    const handleSave = () => {
        const updateWelcomeMessage = async () => {
            if (userContext.jwtToken && settingsContext.apiKey && 'userId' in params && params.userId && subscriber) {
                const query = `${settingsContext.routes.metrics.base}${params.userId}/trials/${trial._id}/autoWelcomeMessage`;

                setIsSaving(true);

                if (trial.welcomeMessage) {
                    // Update the existing message details
                    const updatedMessageDetails = await axios
                        .put(query, {
                            ...messageDetails,
                            ...(!tagCreators && { taggedCreators: [], taggedReleaseForms: [] }),
                        })
                        .then(async response => {
                            if (response.status === 200) {
                                return response.data;
                            }

                            return Promise.reject(response.data.message);
                        })
                        .catch(error => {
                            console.error(error);

                            handleHttpError(error, dialog);

                            return null;
                        });

                    setIsSaving(false);

                    if (updatedMessageDetails && updatedMessageDetails._id) {
                        dialog
                            .alert({
                                title: 'Trial Auto Welcome Message',
                                message: 'Trial Auto Welcome Message updated successfully.',
                            })
                            .then(() => {
                                onClose();

                                // Invalidate the cache
                                queryClient.invalidateQueries('metricsTrialsOverviewPaginated');
                            })
                            .catch(() => {});
                    } else {
                        dialog
                            .alert({
                                title: 'Trial Auto Welcome Message',
                                message: 'Trial Auto Welcome Message could not be updated.',
                            })
                            .then(() => {})
                            .catch(() => {});
                    }
                } else {
                    // Create a new message details
                    const createdMessageDetails = await axios
                        .post(query, {
                            ...messageDetails,
                            ...(!tagCreators && { taggedCreators: [], taggedReleaseForms: [] }),
                        })
                        .then(async response => {
                            if (response.status === 200) {
                                return response.data;
                            }

                            return Promise.reject(response.data.message);
                        })
                        .catch(error => {
                            console.error(error);

                            handleHttpError(error, dialog);

                            return null;
                        });

                    setIsSaving(false);

                    if (createdMessageDetails && createdMessageDetails._id) {
                        enqueueSnackbar('Trial Auto Welcome Message saved successfully.', {
                            variant: 'success',
                            autoHideDuration: 3000,
                            anchorOrigin: {
                                vertical: 'top',
                                horizontal: 'center',
                            },
                        });

                        onClose();

                        // Invalidate the cache
                        queryClient.invalidateQueries('metricsTrialsOverviewPaginated');
                    } else {
                        enqueueSnackbar('Trial Auto Welcome Message could not be saved.', {
                            variant: 'error',
                            autoHideDuration: 3000,
                            anchorOrigin: {
                                vertical: 'top',
                                horizontal: 'center',
                            },
                        });
                    }
                }
                setIsSaving(false);
            }
        };

        updateWelcomeMessage();
    };

    const handleDelete = async () => {
        if (!(userContext.jwtToken && settingsContext.apiKey && 'userId' in params && params.userId && subscriber)) {
            return;
        }

        // Create a Promise that resolves based on user interaction
        const userConfirmation = new Promise(resolve => {
            dialog
                .confirm({
                    title: 'Delete Trial Auto Welcome Message',
                    message: 'Are you sure you want to delete this trial Auto Welcome Message?',
                    ok: {
                        text: 'Yes',
                        color: 'error',
                        variant: 'contained',
                    },
                    cancel: {
                        text: 'No',
                        color: 'secondary',
                        variant: 'outlined',
                    },
                })
                .then(() => {
                    resolve(true);
                })
                .catch(() => {
                    resolve(false);
                });
        });

        // Wait for user response
        const isConfirmed = await userConfirmation;

        if (!isConfirmed || !trial.welcomeMessage) {
            return;
        }

        try {
            setIsSaving(true);

            const query = `${settingsContext.routes.metrics.base}${params.userId}/trials/${trial._id}/autoWelcomeMessage`;

            const response = await axios.delete(query);

            setIsSaving(false);

            if (response.status === 200) {
                await new Promise(resolve => {
                    enqueueSnackbar('Trial Auto Welcome Message deleted successfully.', {
                        variant: 'success',
                        autoHideDuration: 3000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'center',
                        },
                    });

                    onClose();
                    queryClient.invalidateQueries('metricsTrialsOverviewPaginated');
                });
            } else {
                enqueueSnackbar('Trial Auto Welcome Message could not be deleted.', {
                    variant: 'error',
                    autoHideDuration: 3000,
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'center',
                    },
                });
            }
        } catch (error) {
            setIsSaving(false);
            console.error(error);
            handleHttpError(error, dialog);

            enqueueSnackbar('Trial Auto Welcome Message could not be deleted.', {
                variant: 'error',
                autoHideDuration: 3000,
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'center',
                },
            });
        }
    };

    // Close the dialog
    const handleClose = (event: {}, reason: 'backdropClick' | 'escapeKeyDown') => {
        if (reason && reason === 'backdropClick') {
            return;
        }

        onClose();
    };

    return (
        <Dialog
            open={open}
            onClose={onClose}
            maxWidth="sm"
            fullWidth
            disableEscapeKeyDown
            sx={{
                ...(!isLargeScreen && {
                    // container
                    '& .MuiDialog-paper': {
                        marginLeft: 2,
                        marginRight: 2,
                        maxWidth: 'calc(100% - 16px)',
                        width: 'calc(100% - 16px)',
                    },
                }),
            }}
        >
            <StyledCardTitleBar title="Add Auto Welcome Message" theme={theme} />
            <DialogContent
                sx={{
                    ...(!isLargeScreen && {
                        padding: 2,
                        '& .MuiDialogContentText-root': {
                            marginBottom: 2,
                        },
                    }),
                }}
            >
                <Typography variant="h6" gutterBottom component="span" display="block">
                    {trial.foreignId} - {trial?.payload?.name || 'No Name'}
                </Typography>
                <DialogContentText>
                    <Typography variant="body2" gutterBottom component="span" display="block">
                        This message will be sent automatically whenever a fan claims this trial. You can include media, tag creators, and
                        lock the message as a PPV.
                    </Typography>
                    <Typography variant="body2" gutterBottom fontWeight="bold" component="span" display="block">
                        For this feature to work, you must have the "Auto Welcome Message" setting turned on your Free Trial Links page.
                    </Typography>
                </DialogContentText>

                <Grid container alignItems={'center'} justifyContent={'center'} spacing={1} sx={{ mt: 1 }}>
                    <Grid item xs={12}>
                        <MessageUsersFormAddMedias
                            serviceName="autoSendWelcomeMessage"
                            includeMedia={includeMedia}
                            setIncludeMedia={setIncludeMedia}
                            vaultAlbum={vaultAlbum}
                            setVaultAlbum={setVaultAlbum}
                            vaultAlbumName={vaultAlbumName}
                            setVaultAlbumName={setVaultAlbumName}
                            medias={medias}
                            setMedias={setMedias}
                            mediasPreviews={mediaFilesPreviews}
                            setMediasPreviews={setMediaFilesPreviews}
                            thumbnails={thumbnails}
                            getThumbnail={getThumbnail}
                            getAudio={getAudio}
                            tagCreators={tagCreators}
                            setTagCreators={setTagCreators}
                            taggedCreators={taggedCreators}
                            setTaggedCreators={setTaggedCreators}
                            taggedReleaseForms={taggedReleaseForms}
                            setTaggedReleaseForms={setTaggedReleaseForms}
                            session={null}
                            disabled={false}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        {includeMedia && (
                            <MessageUsersTagCreators
                                subscriber={subscriber}
                                tagCreators={tagCreators}
                                setTagCreators={setTagCreators}
                                taggedCreators={taggedCreators}
                                setTaggedCreators={setTaggedCreators}
                                taggedReleaseForms={taggedReleaseForms}
                                setTaggedReleaseForms={setTaggedReleaseForms}
                                session={null}
                                disabled={false}
                            />
                        )}
                    </Grid>

                    <Grid item xs={12}>
                        <MessageUsersPriceLock
                            subscriber={subscriber}
                            lockMessage={lockMessage}
                            setLockMessage={setLockMessage}
                            lockMessagePrice={lockMessagePrice}
                            setLockMessagePrice={setLockMessagePrice}
                            lockedText={lockedText}
                            setLockedText={setLockedText}
                            medias={medias}
                            thumbnails={thumbnails}
                            mediasPreviews={mediaFilesPreviews}
                            setMediasPreviews={setMediaFilesPreviews}
                            session={null}
                            disabled={false}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <MessageUsersFormEditor
                            subscriber={subscriber}
                            message={message}
                            setMessage={setMessage}
                            editorState={editorState}
                            setEditorState={setEditorState}
                            messageHasRestrictedWords={messageHasRestrictedWords}
                            messageFoundRestrictedWords={messageFoundRestrictedWords}
                            checkForRestrictedWords={checkForRestrictedWords}
                            usernameReplacement={replaceUsername}
                            setUsernameReplacement={setReplaceUsername}
                            taggedCreators={taggedCreators}
                            taggedReleaseForms={taggedReleaseForms}
                            lockMessage={lockMessage}
                            lockMessagePrice={lockMessagePrice}
                            lockedText={lockedText}
                            saveTemplate={saveTemplate}
                            setSaveTemplate={setSaveTemplate}
                            saveTemplateName={saveTemplateName}
                            setSaveTemplateName={setSaveTemplateName}
                            borderless={false}
                            session={null}
                            theme={theme}
                            disabled={false}
                        />
                    </Grid>
                </Grid>
            </DialogContent>

            <DialogActions
                sx={{
                    borderTop: '1px solid rgba(0, 0, 0, 0.12)',
                }}
            >
                <Stack direction="row" spacing={2} flexGrow={1} alignItems="center" justifyContent="space-between">
                    <LoadingButton
                        variant="contained"
                        color="error"
                        size="medium"
                        loading={isSaving}
                        disabled={!trial.welcomeMessage}
                        onClick={() => {
                            handleDelete();
                        }}
                    >
                        Delete
                    </LoadingButton>
                    <div>
                        <Stack direction="row" spacing={1} alignItems="center">
                            <Button
                                color="secondary"
                                size="medium"
                                disabled={isSaving}
                                onClick={() => {
                                    handleClose({}, 'escapeKeyDown');
                                }}
                            >
                                Close
                            </Button>
                            <LoadingButton
                                color="secondary"
                                variant="contained"
                                size="medium"
                                loading={isSaving}
                                disabled={
                                    messageHasRestrictedWords ||
                                    (includeMedia && !medias.length) ||
                                    (tagCreators && totalTagged === 0) ||
                                    (lockMessage && lockMessagePrice < 3) ||
                                    (lockMessage && lockedText && message.trim().length === 0) ||
                                    (lockMessage && !lockedText && medias.length === 0) ||
                                    (saveTemplate && (!saveTemplateName || saveTemplateName.trim().length === 0)) ||
                                    (includeMedia &&
                                        (medias.length === 0 ||
                                            medias.length > 40 ||
                                            (medias.length !== 0 && medias.length === mediaFilesPreviews.length))) ||
                                    (message.trim().length === 0 && medias.length === 0)
                                }
                                onClick={() => {
                                    handleSave();
                                }}
                            >
                                {trial.welcomeMessage ? 'Update' : 'Create'}
                            </LoadingButton>
                        </Stack>
                    </div>
                </Stack>
            </DialogActions>
        </Dialog>
    );
};

export default SextforceMetricsTrialsWelcomeMessageDialog;
