import { Alert, Card, CardContent, Grid, Typography } from '@mui/material';
import { useEffect } from 'react';
import { OnlyFansLists } from '../../../../types/onlyFansTypes';
import OnlyFansListsMutiSelector from '../../../common/OnlyFansListsMultiSelector';

type Props = {
    targetLists: { id: number | OnlyFansLists.Type; name: string }[];
    setTargetLists: (targetLists: { id: number | OnlyFansLists.Type; name: string }[]) => void;
    session?: any;
    noBorder?: boolean;
};

const MessageUsersFormTargetList = (props: Props) => {
    const { targetLists, setTargetLists, session, noBorder } = props;

    useEffect(() => {
        if (session && session.payload && session.payload.targetLists) {
            setTargetLists(session.payload.targetLists);
        }
    }, [session, setTargetLists]);

    return (
        <>
            <Grid container spacing={1} flexGrow={0} alignItems="center" justifyContent="center" sx={{ marginBottom: 1 }}>
                <Grid item xs={12}>
                    <Card
                        variant="elevation"
                        sx={{
                            ...(noBorder
                                ? {
                                      // Remove border
                                      border: 0,
                                      boxShadow: 'none',
                                      // Remove padding
                                      '& .MuiCardContent-root': {
                                          padding: 0,
                                      },
                                  }
                                : {}),
                        }}
                    >
                        <CardContent>
                            <Grid container spacing={1} flexGrow={0} alignItems="center" justifyContent="center">
                                <Grid item xs={12}>
                                    <Typography variant="h6" sx={{ marginBottom: 1 }}>
                                        Select Target Lists
                                    </Typography>
                                </Grid>
                            </Grid>

                            <OnlyFansListsMutiSelector targetLists={targetLists} setTargetLists={setTargetLists} />

                            <Alert severity="info">
                                Please note that only current fans and expired fans you follow in these lists will be able to receive the
                                message
                            </Alert>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </>
    );
};

export default MessageUsersFormTargetList;
