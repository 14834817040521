import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import SettingsIcon from '@mui/icons-material/Settings';
import {
    Card,
    CardContent,
    Grid,
    ImageList,
    ImageListItem,
    ImageListItemBar,
    Theme,
    Typography,
    useMediaQuery,
    useTheme,
} from '@mui/material';
import dinero from 'dinero.js';
import { ContentState, EditorState } from 'draft-js';
import moment from 'moment';
import { useState } from 'react';
import 'react-h5-audio-player/lib/styles.css';
import { OnlyFansVaultMedias } from '../../../../hooks/useSubscriberVaultAlbums';
import { OnlyFansSubscriberMessageUsersAutoMessageDetails } from '../../../../types/messageUsersAutoSendWelcomeMessage';
import { loadDraftsJSEditorStateFromHtml, mediaTypeIcon, secondsToMinutesAndSecondsString } from '../../../../utils/common';
import AudioPlayerDialog from '../../../common/AudioPlayerDialog';
import MessageUsersFormEditiorPreview from '../sendMessage/MessageUsersFormEditiorPreview';

const SmallImageList = ({
    medias,
    isLargeScreen,
    theme,
    setPlayerOpen,
    setSelectedAudioUrl,
    getAudio,
}: {
    medias: OnlyFansVaultMedias.RestructuredResponseItem[];
    isLargeScreen: boolean;
    theme: Theme;
    setPlayerOpen: (value: boolean) => void;
    setSelectedAudioUrl: (value: string) => void;
    getAudio: (url: string) => Promise<Blob | null>;
}) => {
    return (
        <ImageList cols={isLargeScreen ? 5 : 4} rowHeight={124}>
            {medias.map(item => (
                <ImageListItem key={item.id}>
                    {item.type !== 'audio' ? (
                        <img src={item.cachedThumb || ''} alt={item.id.toString()} loading="lazy" height={120} width={120} />
                    ) : (
                        <Grid
                            container
                            justifyContent="center"
                            alignItems="center"
                            sx={{
                                backgroundColor: theme.palette.grey[300],
                                height: '100%',
                            }}
                        >
                            <Grid item xs={12} sx={{ paddingBottom: theme.spacing(5) }}>
                                <Grid container justifyContent="center" alignItems="center">
                                    <Grid item xs={12}>
                                        <div style={{ textAlign: 'center' }}>
                                            <PlayCircleIcon
                                                onClick={e => {
                                                    e.stopPropagation();
                                                    if (item.src) {
                                                        setSelectedAudioUrl(item.src);
                                                        setPlayerOpen(true);
                                                    }
                                                }}
                                                fontSize="large"
                                                sx={{
                                                    height: theme.spacing(4),
                                                }}
                                            />
                                        </div>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <div style={{ textAlign: 'center' }}>
                                            <Typography variant="body2" sx={{ color: theme.palette.grey[600] }}>
                                                {secondsToMinutesAndSecondsString(item.duration || 0)}
                                            </Typography>
                                        </div>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    )}
                    <ImageListItemBar
                        title={moment(item.createdAt).calendar({
                            sameDay: '[Today]',
                            nextDay: '[Tomorrow]',
                            nextWeek: 'MMM Mo',
                            lastDay: '[Yesterday]',
                            lastWeek: 'MMM Mo',
                            sameElse: 'MMM Mo',
                        })}
                        subtitle={`${item.counters.likesCount.toLocaleString()} Likes`}
                        actionIcon={mediaTypeIcon(item.type, theme)}
                        sx={{
                            '.MuiImageListItemBar-actionIcon': {
                                marginRight: theme.spacing(1),
                            },
                        }}
                    />
                </ImageListItem>
            ))}
        </ImageList>
    );
};

type Props = {
    messageDetails: OnlyFansSubscriberMessageUsersAutoMessageDetails;
    getAudio: (url: string) => Promise<Blob | null>;
};

const AutoSendWelcomeMessageCurrentSettings = (props: Props) => {
    const { messageDetails, getAudio } = props;
    const theme: Theme = useTheme();

    const isLargeScreen = useMediaQuery(theme.breakpoints.up('sm'));
    const [playerOpen, setPlayerOpen] = useState(false);
    const [selectedAudioUrl, setSelectedAudioUrl] = useState('');

    // Only filter the medias files which are in the previews array
    const previewMedias = messageDetails.mediaFiles.filter(media => messageDetails.mediaFilesPreviews.includes(media.id));

    const editorState: EditorState =
        messageDetails && messageDetails.message && messageDetails.message.length > 0
            ? loadDraftsJSEditorStateFromHtml(messageDetails.message)
            : EditorState.createWithContent(ContentState.createFromText(''));

    return (
        <>
            <Grid container spacing={1} flexGrow={1} alignItems="center" justifyContent="center" sx={{ mb: 2 }}>
                <Grid item xs={12}>
                    <Card sx={{ backgroundColor: theme.palette.grey[200] }}>
                        <CardContent>
                            <Grid container spacing={1} alignItems="center">
                                <Grid item xs={'auto'}>
                                    <SettingsIcon />
                                </Grid>
                                <Grid item xs>
                                    <Typography variant="h6">Current Settings</Typography>
                                </Grid>
                                {messageDetails.mediaFiles && messageDetails.mediaFiles.length > 0 && (
                                    <>
                                        <Grid item xs={12}>
                                            {messageDetails.mediaFiles.length} Media Files
                                        </Grid>
                                        <Grid item xs={12}>
                                            <SmallImageList
                                                medias={messageDetails.mediaFiles || []}
                                                isLargeScreen={isLargeScreen}
                                                theme={theme}
                                                setPlayerOpen={setPlayerOpen}
                                                setSelectedAudioUrl={setSelectedAudioUrl}
                                                getAudio={getAudio}
                                            />
                                        </Grid>
                                    </>
                                )}
                                {messageDetails.taggedCreators && messageDetails.taggedCreators.length > 0 && (
                                    <>
                                        <Grid item xs={12}>
                                            {messageDetails.taggedCreators.length} Tagged Creators
                                        </Grid>
                                        <Grid item xs={12}>
                                            {messageDetails.taggedCreators.map(creator => (
                                                <Typography variant="body2" key={creator.id}>
                                                    {creator.name}
                                                </Typography>
                                            ))}
                                        </Grid>
                                    </>
                                )}
                                {messageDetails.lockMessagePrice !== null && messageDetails.lockMessagePrice > 0 && (
                                    <>
                                        <Grid item xs="auto">
                                            Lock Message Price
                                        </Grid>
                                        <Grid item xs>
                                            {dinero({
                                                amount: Math.trunc(messageDetails.lockMessagePrice * 100),
                                                precision: 2,
                                                currency: 'USD',
                                            }).toFormat()}
                                        </Grid>
                                    </>
                                )}
                                {messageDetails.mediaFilesPreviews && messageDetails.mediaFilesPreviews.length > 0 && (
                                    <>
                                        <Grid item xs={12}>
                                            {messageDetails.mediaFilesPreviews.length} Previews
                                        </Grid>
                                        <Grid item xs={12}>
                                            <SmallImageList
                                                medias={previewMedias || []}
                                                isLargeScreen={isLargeScreen}
                                                theme={theme}
                                                setPlayerOpen={setPlayerOpen}
                                                setSelectedAudioUrl={setSelectedAudioUrl}
                                                getAudio={getAudio}
                                            />
                                        </Grid>
                                    </>
                                )}
                                {messageDetails.message && messageDetails.message.length > 0 && (
                                    <>
                                        <Grid item xs={12}>
                                            Message
                                        </Grid>
                                        <Grid item xs={12}>
                                            <MessageUsersFormEditiorPreview
                                                editorState={editorState}
                                                lockMessage={
                                                    messageDetails.lockMessagePrice !== null && messageDetails.lockMessagePrice > 0
                                                }
                                                lockMessagePrice={messageDetails.lockMessagePrice || 0}
                                                lockedText={messageDetails.lockedText}
                                                taggedCreators={messageDetails.taggedCreators}
                                                taggedReleaseForms={messageDetails.taggedReleaseForms || []}
                                                theme={theme}
                                            />
                                        </Grid>
                                    </>
                                )}
                            </Grid>
                        </CardContent>
                    </Card>
                    <AudioPlayerDialog open={playerOpen} setOpen={setPlayerOpen} url={selectedAudioUrl} getAudio={getAudio} />
                </Grid>
            </Grid>
        </>
    );
};

export default AutoSendWelcomeMessageCurrentSettings;
