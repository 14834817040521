import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import ClearIcon from '@mui/icons-material/Clear';
import LoadingButton from '@mui/lab/LoadingButton';
import {
    alpha,
    Button,
    Checkbox,
    Divider,
    FormControl,
    FormControlLabel,
    Grid,
    InputLabel,
    MenuItem,
    Paper,
    Radio,
    RadioGroup,
    Select,
    TextField,
    Typography,
    useTheme,
} from '@mui/material';
import { LocalizationProvider, MobileDatePicker, MobileDateTimePicker, MobileTimePicker } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { ContentState, EditorState } from 'draft-js';
import moment from 'moment';
import { useDialog } from 'muibox';
import { useSnackbar } from 'notistack';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import useAxios from '../../../../hooks/useAxios';
import {
    emptyOnlyFansMassDMMessageRequest,
    OnlyFansMassDMMessageSchedule,
    OnlyFansMassDMMessageScheduleSchema,
} from '../../../../hooks/useMessageUsersMassDmList';
import useSextforceBigBrotherRestrictedWords from '../../../../hooks/useSextforceBigBrotherRestrictedWords';
import { OnlyFansSubscriber } from '../../../../hooks/useSubscriber';
import useSubscriberLists from '../../../../hooks/useSubscriberLists';
import { OnlyFansVaultMedias } from '../../../../hooks/useSubscriberVaultAlbums';
import { SettingsContext } from '../../../../store/SettingsContext';
import { UserContext } from '../../../../store/UserContext';
import { handleHttpError } from '../../../../utils/common';
import { getRestrictedWordsInMessage } from '../../../../utils/messageUsers';
import OnlyFansListsMutiSelector from '../../../common/OnlyFansListsMultiSelector';
import OnlyFansListsSelector from '../../../common/OnlyFansListsSelector';
import OnlyFansMediaSelector from '../../../common/OnlyFansMediaSelector';
import OnlyFansMessageEditorWithPreview from '../../../common/OnlyFansMessageEditorWithPreview';
import OnlyFansTagCreatorsSelector from '../../../common/OnlyFansTagCreatorsSelector';
import SextforceColorSelector from '../../../common/SextforceColorSelector';
import StyledCard from '../../../common/StyledCard';
import StyledCardTitleBar from '../../../common/StyledCardTitleBar';
import SelectTimezone from '../../../forms/helpers/SelectTimezone';
import MessageUsersPriceLockAndPreviewSelector from '../sendMessage/MessageUsersPriceLockAndPreviewSelector';

const TitleGridItem = (props: { title: string | React.ReactNode; description?: string | React.ReactNode }) => {
    const { title, description } = props;

    return (
        <Grid item xs={12}>
            <Typography variant="inherit">{title}</Typography>
            {description && (
                <Typography variant="body2" color={'text.secondary'} marginTop={0.5}>
                    {description}
                </Typography>
            )}
        </Grid>
    );
};

const TitleGridItemWithCheckBox = (props: {
    title: string | React.ReactNode;
    description?: string | React.ReactNode;
    checked: boolean;
    setChecked: (disabled: boolean) => void;
    disabled?: boolean;
}) => {
    const { title, description, checked, setChecked, disabled } = props;

    return (
        <Grid item xs={12}>
            <FormControlLabel
                control={
                    <Checkbox
                        size="medium"
                        checked={checked}
                        disabled={disabled}
                        onChange={(e, checked) => {
                            setChecked(checked);
                        }}
                    />
                }
                label={
                    <Typography variant="inherit" color={disabled ? 'text.disabled' : 'text.primary'}>
                        {title}
                    </Typography>
                }
            />
            {description && (
                <Typography variant="body2" color={disabled ? 'text.disabled' : 'text.secondary'} marginTop={0.5}>
                    {description}
                </Typography>
            )}
        </Grid>
    );
};

const momentPeriods: { [key: string]: string } = {
    minutes: 'Minutes',
    hours: 'Hours',
    days: 'Days',
    weeks: 'Weeks',
    months: 'Months',
};

/**
 * Props for the MessageUsersMassDmAutoMassDmMessageForm component.
 * @property {OnlyFansSubscriber} subscriber - The subscriber data.
 * @property {() => void} refetchMassDms - Function to refetch the Mass DMs.
 * @property {() => void} handleCancel - Function to handle the cancel action.
 * @property {(show: boolean) => void} setShowAddNewForm - Function to set the visibility of the add new form.
 */
type Props = {
    subscriber: OnlyFansSubscriber;
    refetchMassDms: () => void;
    handleCancel: () => void;
    setShowAddNewForm: (show: boolean) => void;
};

/**
 * Component for creating and scheduling a Mass DM message.
 *
 * @param {Props} props - The properties for the component.
 * @param {any} props.subscriber - The subscriber data.
 * @param {() => void} props.refetchMassDms - Function to refetch the Mass DMs.
 * @param {() => void} props.handleCancel - Function to handle the cancel action.
 * @param {(show: boolean) => void} props.setShowAddNewForm - Function to set the visibility of the add new form.
 */
const MessageUsersMassDmAutoMassDmMessageForm = (props: Props) => {
    const { subscriber, refetchMassDms, handleCancel, setShowAddNewForm } = props;
    const userContext = useContext(UserContext);
    const settingsContext = useContext(SettingsContext);
    const params = useParams();
    const axios = useAxios();
    const { enqueueSnackbar } = useSnackbar();
    const dialog = useDialog();
    const theme = useTheme();

    const [timezone, setTimezone] = useState<string>(moment.tz.guess());
    const [date, setDate] = useState<moment.Moment | null>(null);
    const [time, setTime] = useState<moment.Moment | null>(null);
    const [formData, setFormData] = useState<OnlyFansMassDMMessageSchedule>(emptyOnlyFansMassDMMessageRequest);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const errors = OnlyFansMassDMMessageScheduleSchema.validate(formData, { abortEarly: false }).error?.details;

    const { data: lists } = useSubscriberLists(true);
    const { data: restrictedWords, isLoading: isLoadingRestrictedWords } = useSextforceBigBrotherRestrictedWords();

    const [includeMedia, setIncludeMedia] = useState<boolean>(false);
    const [medias, setMedias] = useState<OnlyFansVaultMedias.RestructuredResponseItem[]>([]);
    const [mediasPreviews, setMediasPreviews] = useState<number[]>([]);
    const [taggedCreators, setTaggedCreators] = useState<{ id: number; name: string }[]>([]);
    const [taggedReleaseForms, setTaggedReleaseForms] = useState<{ id: number; name: string }[]>([]);
    const [thumbnails, setThumbnails] = useState<any[]>([]);
    const [tagCreators, setTagCreators] = useState<boolean>(false);
    const [lockMessage, setLockMessage] = useState<boolean>(false);
    const [lockMessagePrice, setLockMessagePrice] = useState<number>(0);
    const [lockedText, setLockedText] = useState<boolean>(false);
    const [message, setMessage] = useState<string>('');
    const [editorState, setEditorState] = useState(EditorState.createWithContent(ContentState.createFromText('')));
    const [messageHasRestrictedWords, setMessageHasRestrictedWords] = useState<boolean>(false);
    const [messageFoundRestrictedWords, setMessageFoundRestrictedWords] = useState<string[]>([]);
    const [saveTemplate, setSaveTemplate] = useState<boolean>(false);
    const [saveTemplateName, setSaveTemplateName] = useState<string>('');

    useEffect(() => {
        // update the formData medias, mediasPreviews, taggedCreators, taggedReleaseForms
        setFormData({
            ...formData,
            medias: medias.map(media => media.id),
            mediasPreviews,
            taggedCreators: tagCreators ? taggedCreators : [],
            taggedReleaseForms: tagCreators ? taggedReleaseForms : [],
            text: message,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [medias, mediasPreviews, tagCreators, taggedCreators, taggedReleaseForms, message]);

    const massDmAdd = async (newMassDm: OnlyFansMassDMMessageSchedule) => {
        if (!userContext.jwtToken || !settingsContext.apiKey) {
            return;
        }

        setIsSubmitting(true);

        const data = await axios
            .post(`${settingsContext.routes.massDm.base}${params.userId}/schedule`, newMassDm)
            .then(async response => {
                setIsSubmitting(false);
                return response.data as OnlyFansMassDMMessageSchedule;
            })
            .catch(error => {
                setIsSubmitting(false);
                handleHttpError(error, dialog);

                return null;
            });

        return data;
    };

    const handleSubmit = () => {
        massDmAdd(formData).then(data => {
            if (!data) {
                return;
            }

            enqueueSnackbar('Mass DM created successfully', { variant: 'success' });

            refetchMassDms();

            // Reset the form
            setFormData(emptyOnlyFansMassDMMessageRequest);

            setShowAddNewForm(false);
        });
    };

    const handleDateTimeChanged = useCallback(
        (newDate: moment.Moment, newTime: moment.Moment) => {
            const localDateTime = moment.tz(`${newDate.format('YYYY-MM-DD')} ${newTime.format('HH:mm')}`, timezone).local();

            setFormData({ ...formData, scheduleDate: localDateTime.toDate() });
        },
        [timezone, formData],
    );

    useEffect(() => {
        if (date && time) {
            handleDateTimeChanged(date, time);
        } else {
            setFormData({ ...formData, scheduleDate: null });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [date, time, timezone]);

    // Check the message doesn't contain any OnlyFans restricted words
    const checkForRestrictedWords = useCallback(
        (text: string) => {
            if (!text || isLoadingRestrictedWords || !restrictedWords || !subscriber) {
                setMessageHasRestrictedWords(false);
                setMessageFoundRestrictedWords([]);
                return;
            }

            const restrictedWordsFound = getRestrictedWordsInMessage(subscriber, text, restrictedWords);

            setMessageHasRestrictedWords(restrictedWordsFound.length > 0);
            setMessageFoundRestrictedWords(restrictedWordsFound);
        },
        [isLoadingRestrictedWords, restrictedWords, subscriber],
    );

    return (
        <>
            <LocalizationProvider dateAdapter={AdapterMoment}>
                <StyledCard noCard noBottomMargin>
                    <StyledCardTitleBar title="New Mass DM" theme={theme} />
                </StyledCard>
                <StyledCard>
                    <Grid container alignItems={'center'} justifyContent={'center'} spacing={2}>
                        <TitleGridItem title="Name" description="Name this Mass DM so it's recognisable." />
                        <Grid item xs={12}>
                            <TextField
                                id="name"
                                name="name"
                                value={formData.name}
                                fullWidth
                                onChange={e => setFormData({ ...formData, name: e.target.value })}
                                error={!!errors?.find(error => error.context?.key === 'name')}
                                helperText={errors ? errors.find(error => error.context?.key === 'name')?.message : undefined}
                            />
                        </Grid>

                        <TitleGridItem
                            title="Category Color"
                            description="Choose an identifying color for this repost. This will be used to help identify the category or topic of the repost."
                        />
                        <Grid item xs={12}>
                            <SextforceColorSelector
                                colorId={formData.colorId}
                                setColorId={colorId => setFormData({ ...formData, colorId })}
                                sx={{ mt: 0.5 }}
                            />
                        </Grid>

                        <TitleGridItem title="Send To" description="Select the target list(s) to send this Mass DM to." />
                        <Grid item xs={12}>
                            <OnlyFansListsMutiSelector
                                targetLists={formData.userLists.map(listId => {
                                    return { id: listId, name: lists?.find(list => list.id === listId)?.name || 'Unknown' };
                                })}
                                setTargetLists={targetLists => setFormData({ ...formData, userLists: targetLists.map(list => list.id) })}
                                listItemBackgroundColor={alpha(theme.palette.primary.main, 0.3)}
                                sx={{
                                    backgroundColor: alpha(theme.palette.primary.main, 0.1),
                                }}
                            />
                            {errors && errors.find(error => error.context?.key === 'userLists') && (
                                <Typography variant="body2" color={'error'} fontSize={'0.7rem'} sx={{ ml: '14px' }}>
                                    {errors.find(error => error.context?.key === 'userLists')?.message}
                                </Typography>
                            )}
                        </Grid>

                        <TitleGridItem title="Exclude" description="Select the list(s) of users to exclude from this Mass DM." />
                        <Grid item xs={12}>
                            <OnlyFansListsMutiSelector
                                targetLists={formData.excludedLists.map(listId => {
                                    return { id: listId, name: lists?.find(list => list.id === listId)?.name || 'Unknown' };
                                })}
                                setTargetLists={targetLists =>
                                    setFormData({ ...formData, excludedLists: targetLists.map(list => list.id) })
                                }
                                listItemBackgroundColor={alpha(theme.palette.error.main, 0.3)}
                                sx={{
                                    backgroundColor: alpha(theme.palette.error.main, 0.1),
                                }}
                            />
                        </Grid>

                        <TitleGridItemWithCheckBox title="Include Media" checked={includeMedia} setChecked={setIncludeMedia} />
                        {includeMedia && (
                            <Grid item xs={12}>
                                <Paper variant="outlined" sx={{ p: 2, backgroundColor: alpha(theme.palette.info.main, 0.1) }}>
                                    <OnlyFansMediaSelector
                                        serviceName="autoMassDm"
                                        subscriber={subscriber}
                                        medias={medias}
                                        setMedias={setMedias}
                                        mediasPreviews={mediasPreviews}
                                        setMediasPreviews={setMediasPreviews}
                                        thumbnails={thumbnails}
                                        setThumbnails={setThumbnails}
                                        tagCreators={tagCreators}
                                        setTagCreators={setTagCreators}
                                        taggedCreators={taggedCreators}
                                        setTaggedCreators={setTaggedCreators}
                                        taggedReleaseForms={taggedReleaseForms}
                                        setTaggedReleaseForms={setTaggedReleaseForms}
                                        selectSize="small"
                                    />
                                </Paper>
                            </Grid>
                        )}

                        {includeMedia && (
                            <>
                                <TitleGridItemWithCheckBox title="Tag Creators" checked={tagCreators} setChecked={setTagCreators} />
                                {tagCreators && (
                                    <Grid item xs={12}>
                                        <Paper variant="outlined" sx={{ p: 2, backgroundColor: alpha(theme.palette.info.main, 0.1) }}>
                                            <OnlyFansTagCreatorsSelector
                                                subscriber={subscriber}
                                                tagCreators={tagCreators}
                                                setTagCreators={setTagCreators}
                                                taggedCreators={taggedCreators}
                                                setTaggedCreators={setTaggedCreators}
                                                taggedReleaseForms={taggedReleaseForms}
                                                setTaggedReleaseForms={setTaggedReleaseForms}
                                                session={null}
                                                disabled={false}
                                            />
                                        </Paper>
                                    </Grid>
                                )}
                            </>
                        )}

                        <TitleGridItemWithCheckBox title="Lock Message (PPV)" checked={lockMessage} setChecked={setLockMessage} />
                        {lockMessage && (
                            <Grid item xs={12}>
                                <Paper variant="outlined" sx={{ p: 2, backgroundColor: alpha(theme.palette.warning.main, 0.1) }}>
                                    <MessageUsersPriceLockAndPreviewSelector
                                        subscriber={subscriber}
                                        lockMessagePrice={lockMessagePrice}
                                        setLockMessagePrice={setLockMessagePrice}
                                        lockedText={lockedText}
                                        setLockedText={setLockedText}
                                        thumbnails={thumbnails}
                                        medias={medias}
                                        mediasPreviews={mediasPreviews}
                                        setMediasPreviews={setMediasPreviews}
                                    />
                                </Paper>
                            </Grid>
                        )}

                        <TitleGridItem
                            title="Message"
                            description="Enter the message to send below. Use the toolbar icons for styling the text in bold, italic, change
                                        its color and font size."
                        />
                        <Grid item xs={12}>
                            <OnlyFansMessageEditorWithPreview
                                message={message}
                                setMessage={setMessage}
                                editorState={editorState}
                                setEditorState={setEditorState}
                                messageHasRestrictedWords={messageHasRestrictedWords}
                                messageFoundRestrictedWords={messageFoundRestrictedWords}
                                checkForRestrictedWords={checkForRestrictedWords}
                                taggedCreators={taggedCreators}
                                taggedReleaseForms={taggedReleaseForms}
                                lockMessage={lockMessage}
                                lockMessagePrice={formData.price || 0}
                                lockedText={lockedText}
                                saveTemplate={saveTemplate}
                                setSaveTemplate={setSaveTemplate}
                                saveTemplateName={saveTemplateName}
                                setSaveTemplateName={setSaveTemplateName}
                            />
                        </Grid>

                        <TitleGridItem
                            title="When should this Mass DM go out?"
                            description="Please note that if you've exceeded the 50 Mass DM limit, this message will not be sent."
                        />
                        <Grid item xs={12}>
                            <Paper variant="outlined" sx={{ p: 2, backgroundColor: alpha(theme.palette.success.main, 0.05) }}>
                                <RadioGroup
                                    name="massDmSchedule"
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                        if (event.target.value === 'now') {
                                            setDate(null);
                                            setTime(null);
                                        } else {
                                            setDate(moment());
                                            setTime(moment());
                                        }
                                    }}
                                >
                                    <FormControlLabel value="now" control={<Radio />} label="Now" checked={!formData.scheduleDate} />
                                    <FormControlLabel
                                        value="later"
                                        control={<Radio />}
                                        label="Later..."
                                        checked={!!formData.scheduleDate}
                                    />
                                </RadioGroup>

                                {formData.scheduleDate && (
                                    <Grid container alignItems={'center'} justifyContent={'center'} spacing={1} sx={{ pl: 3 }}>
                                        <Grid item xs={12}>
                                            <SelectTimezone
                                                timezone={timezone}
                                                setTimezone={setTimezone}
                                                fullWidth={true}
                                                disabled={!formData.scheduleDate}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <MobileDatePicker
                                                renderInput={props => (
                                                    <TextField
                                                        fullWidth
                                                        {...props}
                                                        error={moment(formData.scheduleDate).isBefore(moment())}
                                                    />
                                                )}
                                                label="Date"
                                                value={moment(date)}
                                                disabled={!formData.scheduleDate}
                                                onChange={newValue => {
                                                    setDate(newValue as moment.Moment);
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <MobileTimePicker
                                                label="Time"
                                                ampm={true}
                                                value={moment(time)}
                                                disabled={!formData.scheduleDate}
                                                onChange={newValue => {
                                                    setTime(newValue as moment.Moment);
                                                }}
                                                renderInput={params => (
                                                    <TextField
                                                        fullWidth
                                                        {...params}
                                                        error={moment(formData.scheduleDate).isBefore(moment())}
                                                    />
                                                )}
                                                DialogProps={{
                                                    sx: {
                                                        '.MuiPickersToolbar-content .Mui-selected': {
                                                            color: theme.palette.secondary.main,
                                                        },
                                                    },
                                                }}
                                            />
                                        </Grid>
                                        {moment(formData.scheduleDate).isBefore(moment()) && (
                                            <Grid item xs={12}>
                                                <Typography variant="body2" color={'error'} fontSize={'0.7rem'} sx={{ ml: '14px' }}>
                                                    Scheduled date must be in the future.
                                                </Typography>
                                            </Grid>
                                        )}
                                    </Grid>
                                )}
                            </Paper>
                        </Grid>

                        <TitleGridItemWithCheckBox
                            title="Repeat Mass DM"
                            checked={
                                typeof formData.repeatEveryAmount !== 'undefined' && typeof formData.repeatEveryUnit !== 'undefined'
                                    ? true
                                    : false
                            }
                            setChecked={(checked: boolean) => {
                                if (checked) {
                                    setFormData({ ...formData, repeatEveryAmount: 1, repeatEveryUnit: 'days' });
                                } else {
                                    setFormData({ ...formData, repeatEveryAmount: undefined, repeatEveryUnit: undefined });
                                }
                            }}
                        />

                        {typeof formData.repeatEveryAmount !== 'undefined' && typeof formData.repeatEveryUnit !== 'undefined' && (
                            <Grid item xs={12}>
                                <Paper variant="outlined" sx={{ p: 2, backgroundColor: alpha(theme.palette.info.main, 0.05) }}>
                                    <Grid container spacing={1} flexGrow={1} alignItems="center">
                                        <Grid item xs={6} sm="auto">
                                            <TextField
                                                label="Every"
                                                type="number"
                                                value={formData.repeatEveryAmount}
                                                onChange={e => {
                                                    setFormData({
                                                        ...formData,
                                                        repeatEveryAmount: Number.isNaN(Number.parseInt(e.target.value, 10))
                                                            ? 0
                                                            : Number.parseInt(e.target.value, 10),
                                                    });
                                                }}
                                                InputProps={{ inputProps: { min: 1 } }}
                                            />
                                        </Grid>
                                        <Grid item xs={6} sm="auto">
                                            <FormControl fullWidth>
                                                <InputLabel>Period</InputLabel>
                                                <Select
                                                    label="Period"
                                                    value={formData.repeatEveryUnit}
                                                    onChange={e => {
                                                        setFormData({
                                                            ...formData,
                                                            repeatEveryUnit: e.target.value as moment.unitOfTime.DurationConstructor,
                                                        });
                                                    }}
                                                    fullWidth
                                                    size="small"
                                                >
                                                    {Object.keys(momentPeriods).map(item => (
                                                        <MenuItem value={item} key={item}>
                                                            {momentPeriods[item]}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        {errors && errors.find(error => error.context?.key === 'repeatEveryAmount') && (
                                            <Grid item xs={12}>
                                                <Typography variant="body2" color={'error'} fontSize={'0.7rem'} sx={{ ml: '14px' }}>
                                                    {errors.find(error => error.context?.key === 'repeatEveryAmount')?.message}
                                                </Typography>
                                            </Grid>
                                        )}
                                        <Grid item xs={12}>
                                            Stop repeating on:
                                        </Grid>
                                        <Grid item xs={12} sm="auto">
                                            <MobileDateTimePicker
                                                renderInput={props => (
                                                    <TextField
                                                        {...props}
                                                        error={formData.repeatEndDate && formData.repeatEndDate < new Date() ? true : false}
                                                        helperText={
                                                            formData.repeatEndDate && formData.repeatEndDate < new Date()
                                                                ? 'End date must be in the future'
                                                                : ''
                                                        }
                                                        fullWidth
                                                        InputProps={{
                                                            endAdornment: formData.repeatEndDate ? (
                                                                <ClearIcon
                                                                    onClick={e => {
                                                                        e.preventDefault();
                                                                        e.stopPropagation();

                                                                        setFormData({ ...formData, repeatEndDate: undefined });
                                                                    }}
                                                                />
                                                            ) : (
                                                                <CalendarMonthIcon />
                                                            ),
                                                        }}
                                                    />
                                                )}
                                                label="Repeat Until"
                                                ampm={true}
                                                value={formData.repeatEndDate ? moment(formData.repeatEndDate) : null}
                                                minDate={moment()}
                                                onChange={newValue => {
                                                    setFormData({ ...formData, repeatEndDate: newValue?.toDate() || undefined });
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                </Paper>
                            </Grid>
                        )}

                        <TitleGridItem
                            title="Unsend Mass DM"
                            description="Automatically unsend the Mass DM after a certain amount of time or views."
                        />
                        <Grid item xs={12}>
                            <Paper variant="outlined" sx={{ p: 2, backgroundColor: alpha(theme.palette.info.main, 0.05) }}>
                                <Grid container spacing={1} flexGrow={1} alignItems="center">
                                    <TitleGridItemWithCheckBox
                                        title="Unsend Mass DM before repeating"
                                        checked={formData.autoUnsendBeforeRepeat ? true : false}
                                        disabled={formData.repeatEveryAmount === undefined && formData.repeatEveryUnit === undefined}
                                        setChecked={(checked: boolean) => {
                                            if (checked) {
                                                setFormData({
                                                    ...formData,
                                                    autoUnsendBeforeRepeat: true,
                                                });
                                            } else {
                                                setFormData({
                                                    ...formData,
                                                    autoUnsendBeforeRepeat: false,
                                                });
                                            }
                                        }}
                                    />

                                    <TitleGridItemWithCheckBox
                                        title="After a certain amount of time"
                                        checked={
                                            typeof formData.autoUnsendTimerPeriodAmount !== 'undefined' &&
                                            typeof formData.autoUnsendTimerPeriod !== 'undefined'
                                                ? true
                                                : false
                                        }
                                        setChecked={(checked: boolean) => {
                                            if (checked) {
                                                setFormData({
                                                    ...formData,
                                                    autoUnsendTimerPeriodAmount: 1,
                                                    autoUnsendTimerPeriod: 'days',
                                                });
                                            } else {
                                                setFormData({
                                                    ...formData,
                                                    autoUnsendTimerPeriodAmount: undefined,
                                                    autoUnsendTimerPeriod: undefined,
                                                });
                                            }
                                        }}
                                    />
                                    {typeof formData.autoUnsendTimerPeriodAmount !== 'undefined' &&
                                        typeof formData.autoUnsendTimerPeriod !== 'undefined' && (
                                            <Grid item xs={12}>
                                                <Grid container spacing={1} flexGrow={1} alignItems="center" sx={{ ml: 3 }}>
                                                    <Grid item xs={6} sm="auto">
                                                        <TextField
                                                            label={momentPeriods[formData.autoUnsendTimerPeriod]}
                                                            type="number"
                                                            value={formData.autoUnsendTimerPeriodAmount}
                                                            onChange={e => {
                                                                setFormData({
                                                                    ...formData,
                                                                    autoUnsendTimerPeriodAmount: Number.isNaN(
                                                                        Number.parseInt(e.target.value, 10),
                                                                    )
                                                                        ? 0
                                                                        : Number.parseInt(e.target.value, 10),
                                                                });
                                                            }}
                                                            InputProps={{ inputProps: { min: 1 } }}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={6} sm="auto">
                                                        <FormControl fullWidth>
                                                            <InputLabel>Period</InputLabel>
                                                            <Select
                                                                label="Period"
                                                                value={formData.autoUnsendTimerPeriod}
                                                                onChange={e => {
                                                                    setFormData({
                                                                        ...formData,
                                                                        autoUnsendTimerPeriod: e.target
                                                                            .value as moment.unitOfTime.DurationConstructor,
                                                                    });
                                                                }}
                                                                fullWidth
                                                                size="small"
                                                            >
                                                                {Object.keys(momentPeriods).map(item => (
                                                                    <MenuItem value={item} key={item}>
                                                                        {momentPeriods[item]}
                                                                    </MenuItem>
                                                                ))}
                                                            </Select>
                                                        </FormControl>
                                                    </Grid>
                                                    {errors && errors.find(error => error.context?.key === 'autoUnsendTimerPeriodAmount') && (
                                                        <Grid item xs={12}>
                                                            <Typography
                                                                variant="body2"
                                                                color={'error'}
                                                                fontSize={'0.7rem'}
                                                                sx={{ ml: '14px' }}
                                                            >
                                                                {
                                                                    errors.find(
                                                                        error => error.context?.key === 'autoUnsendTimerPeriodAmount',
                                                                    )?.message
                                                                }
                                                            </Typography>
                                                        </Grid>
                                                    )}
                                                </Grid>
                                            </Grid>
                                        )}

                                    <TitleGridItemWithCheckBox
                                        title="When the Mass DM has been viewed"
                                        checked={typeof formData.autoUnsendViews !== 'undefined' ? true : false}
                                        setChecked={(checked: boolean) => {
                                            if (checked) {
                                                setFormData({
                                                    ...formData,
                                                    autoUnsendViews: 1000,
                                                });
                                            } else {
                                                setFormData({
                                                    ...formData,
                                                    autoUnsendViews: undefined,
                                                });
                                            }
                                        }}
                                    />
                                    {typeof formData.autoUnsendViews !== 'undefined' && (
                                        <Grid item xs={12} sm="auto">
                                            <Grid item xs={12}>
                                                <Grid container spacing={1} flexGrow={1} alignItems="center" sx={{ ml: 3 }}>
                                                    <Grid item xs={12}>
                                                        <TextField
                                                            label="Views"
                                                            type="number"
                                                            value={formData.autoUnsendViews}
                                                            onChange={e => {
                                                                setFormData({
                                                                    ...formData,
                                                                    autoUnsendViews: Number.isNaN(Number.parseInt(e.target.value, 10))
                                                                        ? 0
                                                                        : Number.parseInt(e.target.value, 10),
                                                                });
                                                            }}
                                                            InputProps={{ inputProps: { min: 1 } }}
                                                        />
                                                    </Grid>
                                                    {errors && errors.find(error => error.context?.key === 'autoUnsendViews') && (
                                                        <Grid item xs={12}>
                                                            <Typography
                                                                variant="body2"
                                                                color={'error'}
                                                                fontSize={'0.7rem'}
                                                                sx={{ ml: '14px' }}
                                                            >
                                                                {errors.find(error => error.context?.key === 'autoUnsendViews')?.message}
                                                            </Typography>
                                                        </Grid>
                                                    )}
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    )}

                                    <TitleGridItemWithCheckBox
                                        title="Add purchasers to list befre unsending"
                                        checked={formData.addPurchasersToUserList ? true : false}
                                        setChecked={(checked: boolean) => {
                                            if (checked) {
                                                setFormData({
                                                    ...formData,
                                                    addPurchasersToUserList: true,
                                                });
                                            } else {
                                                setFormData({
                                                    ...formData,
                                                    addPurchasersToUserList: false,
                                                });
                                            }
                                        }}
                                    />
                                    {formData.addPurchasersToUserList && (
                                        <Grid item xs={12}>
                                            <Grid container spacing={1} flexGrow={1} alignItems="center" sx={{ ml: 3, pr: 3 }}>
                                                <Grid item xs={12}>
                                                    <OnlyFansListsSelector
                                                        size="small"
                                                        includeBuiltInLists={false}
                                                        targetList={lists?.find(list => list.id === formData.addPurchasersToUserListId)}
                                                        setTargetList={listId => {
                                                            if (listId && typeof listId.id === 'number') {
                                                                setFormData({
                                                                    ...formData,
                                                                    addPurchasersToUserListId: listId?.id || -1,
                                                                });
                                                            } else {
                                                                setFormData({
                                                                    ...formData,
                                                                    addPurchasersToUserListId: undefined,
                                                                });
                                                            }
                                                        }}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    )}
                                </Grid>
                            </Paper>
                        </Grid>

                        <Grid item xs={12}>
                            <Divider />
                        </Grid>
                        <Grid item xs={8}>
                            <LoadingButton
                                variant="contained"
                                color="secondary"
                                fullWidth
                                size="large"
                                loading={isSubmitting}
                                disabled={
                                    !!errors ||
                                    (includeMedia && formData.medias.length === 0) ||
                                    (tagCreators && taggedCreators.length === 0 && taggedReleaseForms.length === 0) ||
                                    (lockMessage && lockMessagePrice < 3) ||
                                    (lockedText && formData.text.trim().length === 0) ||
                                    (formData.text.trim().length === 0 && formData.medias.length === 0) ||
                                    isSubmitting
                                }
                                onClick={handleSubmit}
                            >
                                {formData.scheduleDate ? 'Schedule Mass DM' : 'Send Mass DM'}
                            </LoadingButton>
                        </Grid>
                        <Grid item xs={4}>
                            <Button variant="outlined" color="error" fullWidth size="large" onClick={handleCancel}>
                                Cancel
                            </Button>
                        </Grid>
                    </Grid>
                </StyledCard>
            </LocalizationProvider>
        </>
    );
};

export default MessageUsersMassDmAutoMassDmMessageForm;
