import { Button, Dialog, DialogActions, DialogContent, DialogContentText, FormControl, Grid, TextField, Theme } from '@mui/material';
import { useState } from 'react';
import StyledCardTitleBar from '../../../../common/StyledCardTitleBar';

type Props = {
    open: boolean;
    onClose: () => void;
    handleSubmit: (name: string) => void;
    theme: Theme;
};

const SextforceLiveStreamAddTippersToListCreateListDialog = (props: Props) => {
    const { open, onClose, handleSubmit, theme } = props;

    const [name, setName] = useState<string>('');

    const handleClose = (event: {}, reason: 'backdropClick' | 'escapeKeyDown') => {
        if (reason && reason === 'backdropClick') {
            return;
        }

        onClose();
    };

    return (
        <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth disableEscapeKeyDown scroll="paper">
            <StyledCardTitleBar title={`Create New List`} theme={theme} />
            <DialogContent>
                <DialogContentText>Please enter a name for the new list</DialogContentText>
                <Grid container alignItems={'center'} justifyContent={'center'} spacing={1} sx={{ mt: 1 }}>
                    <Grid item xs={12}>
                        <FormControl fullWidth>
                            <TextField
                                id="name"
                                name="name"
                                label="Name"
                                defaultValue={name}
                                variant="outlined"
                                onChange={event => {
                                    // Max length of 64 characters
                                    if (event.target.value.length > 64) {
                                        return;
                                    }

                                    setName(event.target.value);
                                }}
                                value={name}
                                helperText={`${name.length}/64`}
                                error={name.trim().length === 0}
                                fullWidth
                                autoFocus
                            />
                        </FormControl>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions
                sx={{
                    backgroundColor: theme.palette.grey[100],
                }}
            >
                <Button
                    color="error"
                    size="medium"
                    onClick={() => {
                        handleClose({}, 'escapeKeyDown');
                    }}
                >
                    Close
                </Button>
                <Button
                    color="primary"
                    size="medium"
                    type="submit"
                    disabled={!name || name.trim().length === 0}
                    onClick={event => {
                        event.preventDefault();
                        handleSubmit(name);
                    }}
                >
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default SextforceLiveStreamAddTippersToListCreateListDialog;
