import { d2f } from '../../../../utils/common';
import { TotalSales } from './campaigns/details/SextforceMetricsCampaign';

export interface MetricOverviewTrialStats {
    metricId: string;
    claimedToday?: number;
    counters?: { claimsCount?: number; subscribeCounts?: number };
    earningsTotal?: { $numberDecimal: string } | number;
}

export interface MetricOverviewCampaignStats {
    metricId: string;
    claimedToday?: number;
    counters?: { countSubscribers?: number; countTransitions?: number };
    earningsTotal?: { $numberDecimal: string } | number;
}

export interface MetricOverviewRecentEarnings {
    _id: string;
    total: { $numberDecimal: string } | number;
    totalNet: { $numberDecimal: string } | number;
    totalWithoutSubscriptions: { $numberDecimal: string } | number;
    totalNetWithoutSubscriptions: { $numberDecimal: string } | number;
}

export const countSpendersInCampaignOrTrial = (data: any[]): number => {
    if (!data || data.length === 0) {
        return 0;
    }

    let spenders: number = 0;

    data.forEach((user: any) => {
        const amount: number = d2f(user.total);

        if (amount !== 0) {
            spenders += 1;
        }
    });

    return spenders;
};

export const parseCampaignStats = (
    data: {
        _id: string;
        foreignId: number;
        userId: number;
        userName: string;
        name?: string;
        subscribedAt: string;
        total?: object;
        totalMessage?: object | number;
        totalPost?: object | number;
        totalSubscription?: object | number;
        totalTip?: object | number;
        totalResubscription?: object | number;
        totalStream?: object | number;
        totalWithoutSubscriptions?: object | number;
    }[],
    counters: any,
    totalSales: TotalSales,
    showEarningsWithSubscriptions: boolean,
) => {
    const subscribed: number =
        counters && counters.metricsDatas && counters.metricsDatas.length > 0
            ? counters.metricsDatas[counters.metricsDatas.length - 1].payload?.countSubscribers
            : (data && data.length) || 0;

    const clicked: number =
        counters && counters.metricsDatas && counters.metricsDatas.length > 0
            ? counters.metricsDatas[counters.metricsDatas.length - 1].payload.countTransitions
            : 0;

    const spenders: number = countSpendersInCampaignOrTrial(data);

    const earningsWithSubscriptions: number = (totalSales && totalSales.total) || 0;
    const earningsWithoutSubscriptions: number = (totalSales && totalSales.totalWithoutSubscriptions) || 0;

    const earningsGross: number = showEarningsWithSubscriptions ? earningsWithSubscriptions : earningsWithoutSubscriptions;
    const earningsNet: number = earningsGross - earningsGross * 0.2;

    const cpf: number = subscribed > 0 ? Math.round(earningsNet / subscribed) : 0;
    const cpc: number = clicked > 0 ? Math.round(earningsNet / clicked) : 0;
    const conversionRateClickToSubscriber: number = subscribed > 0 ? (subscribed / clicked) * 100 : 0;
    const conversionRateSubscriberToSale: number = spenders > 0 ? (spenders / subscribed) * 100 : 0;

    return {
        subscribed,
        clicked,
        spenders,
        earningsGross,
        earningsNet,
        cpf,
        cpc,
        conversionRateClickToSubscriber,
        conversionRateSubscriberToSale,
    };
};

export const parseTrialStats = (
    data: {
        _id: string;
        foreignId: number;
        userId: number;
        userName: string;
        name?: string;
        subscribedAt: string;
        total?: object;
        totalMessage?: object | number;
        totalPost?: object | number;
        totalSubscription?: object | number;
        totalTip?: object | number;
        totalResubscription?: object | number;
        totalStream?: object | number;
        totalWithoutSubscriptions?: object | number;
    }[],
    counters: any,
    totalSales: TotalSales,
    showEarningsWithSubscriptions: boolean,
) => {
    const claimed: number = Math.max(
        counters && counters.metricsDatas && counters.metricsDatas.length > 0
            ? counters.metricsDatas[counters.metricsDatas.length - 1].payload.claimsCount
            : 0,
        (data && data.length) || 0,
    );

    const clicked: number =
        counters && counters.metricsDatas && counters.metricsDatas.length > 0
            ? counters.metricsDatas[counters.metricsDatas.length - 1].payload.countTransitions
            : 0;

    const spenders: number = countSpendersInCampaignOrTrial(data);

    const earningsWithSubscriptions: number = (totalSales && totalSales.total) || 0;
    const earningsWithoutSubscriptions: number = (totalSales && totalSales.totalWithoutSubscriptions) || 0;

    const earningsGross: number = showEarningsWithSubscriptions ? earningsWithSubscriptions : earningsWithoutSubscriptions;
    const earningsNet: number = earningsGross - earningsGross * 0.2;

    const cpf: number = claimed > 0 ? Math.round(earningsNet / claimed) : 0;
    const cpc: number = clicked > 0 ? Math.round(earningsNet / clicked) : 0;
    const conversionRateClickToSubscriber: number = claimed > 0 ? (claimed / clicked) * 100 : 0;
    const conversionRateSubscriberToSale: number = spenders > 0 ? (spenders / claimed) * 100 : 0;

    return {
        claimed,
        clicked,
        spenders,
        earningsGross,
        earningsNet,
        cpf,
        cpc,
        conversionRateClickToSubscriber,
        conversionRateSubscriberToSale,
    };
};
