import { Backdrop, Card, CardContent, CircularProgress, Grid, Theme, Typography } from '@mui/material';
import useTheme from '@mui/material/styles/useTheme';
import { ContentState, EditorState } from 'draft-js';
import moment from 'moment';
import { useDialog } from 'muibox';
import { useSnackbar } from 'notistack';
import { useCallback, useContext, useEffect, useState } from 'react';
import { Link, NavigateFunction, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import BackNavigationButton from '../../../components/common/BackNavigationButton';
import PageContainer from '../../../components/common/PageContainter';
import SharedCreditCard from '../../../components/common/SharedCreditCard';
import MessageUsersPriceBar from '../../../components/services/messageUsers/MessageUsersPriceBar';
import MessageUsersForm from '../../../components/services/messageUsers/sendMessage/MessageUsersForm';
import useAxios from '../../../hooks/useAxios';
import useMessageUsersCurrentSessions from '../../../hooks/useMessageUsersCurrentSessions';
import useMessageUsersScheduledSessions from '../../../hooks/useMessageUsersScheduledSessions';
import useSextforceBigBrotherRestrictedWords from '../../../hooks/useSextforceBigBrotherRestrictedWords';
import useSubscriber, { OnlyFansSubscriptionsUserType } from '../../../hooks/useSubscriber';
import useSubscriberLists from '../../../hooks/useSubscriberLists';
import useSubscriberMedia from '../../../hooks/useSubscriberMedia';
import { OnlyFansVaultMedias } from '../../../hooks/useSubscriberVaultAlbums';
import { SettingsContext } from '../../../store/SettingsContext';
import { UserContext } from '../../../store/UserContext';
import { OnlyFansLists } from '../../../types/onlyFansTypes';
import { d2f, handleHttpError, handleHttpErrorResponse, isSextforceActive } from '../../../utils/common';
import { getRestrictedWordsInMessage } from '../../../utils/messageUsers';

const MessageUsersSendMessage = () => {
    const userContext = useContext(UserContext);
    const settingsContext = useContext(SettingsContext);
    const theme: Theme = useTheme();
    const navigate: NavigateFunction = useNavigate();
    const dialog = useDialog();
    const axios = useAxios();
    let [searchParams] = useSearchParams();
    const params = useParams();
    const { enqueueSnackbar } = useSnackbar();

    const { data: subscriber, isLoading: subscriberLoading } = useSubscriber();
    const { refetch: refetchMessageUsersCurrentSessions } = useMessageUsersCurrentSessions();
    const { refetch: refetchMessageUsersScheduledSessions } = useMessageUsersScheduledSessions();
    const { getThumbnail: getSubscriberThumbnail, getAudio: getSubscriberAudio } = useSubscriberMedia();
    const { data: restrictedWords, isLoading: isLoadingRestrictedWords } = useSextforceBigBrotherRestrictedWords();

    const [messageUsersCurrentSession, setMessageUsersCurrentSession] = useState<any | null>(null);
    const [unitCost, setUnitCost] = useState<number>(0);

    const [scheduleMessage, setScheduleMessage] = useState<boolean>(false);
    const [scheduleMessageDate, setScheduleMessageDate] = useState<Date | null>(null);
    const [colorId, setColorId] = useState<string | undefined>(undefined);
    const [repeatMessage, setRepeatMessage] = useState<boolean>(false);
    const [repeatEveryAmount, setRepeatEveryAmount] = useState<number>(1);
    const [repeatEveryUnit, setRepeatEveryUnit] = useState<string>('hours');
    const [repeatEndDate, setRepeatEndDate] = useState<Date | null>(null);

    const [targetUsers, setTargetUsers] = useState<OnlyFansSubscriptionsUserType>(
        (localStorage.getItem('messageUsersTargetUsers') as OnlyFansSubscriptionsUserType) || 'onlineActive',
    );
    const [targetLists, setTargetLists] = useState<{ id: number | OnlyFansLists.Type; name: string }[]>([]);
    const [targetRecentSpendersDateFrom, setTargetRecentSpendersDateFrom] = useState<Date | null>(
        moment().subtract(1, 'months').startOf('day').toDate(),
    );
    const [targetRecentSpendersDateTo, setTargetRecentSpendersDateTo] = useState<Date | null>(moment().toDate());
    const [targetRecentFansDateFrom, setTargetRecentFansDateFrom] = useState<Date | null>(
        moment().subtract(1, 'months').startOf('day').toDate(),
    );
    const [targetRecentFansDateTo, setTargetRecentFansDateTo] = useState<Date | null>(moment().toDate());

    const [excludeCreators, setExcludeCreators] = useState<boolean>(true);

    const [excludeUnreadChats, setExcludeUnreadChats] = useState<boolean>(false);

    const [excludeLimitMessage, setExcludeLimitMessage] = useState<boolean>(false);
    const [excludeLimitMessageCount, setExcludeLimitMessageCount] = useState<number>(1);

    const [excludeUsersList, setExcludeUsersList] = useState<boolean>(false);
    const [excludeUsersLists, setExcludeUsersLists] = useState<any[]>([]);

    const [excludeUsersSpent, setExcludeUsersSpent] = useState<boolean>(false);
    const [excludeSpentCondition, setExcludeSpentCondition] = useState<string>('eq');
    const [excludeSpentAmount, setExcludeSpentAmount] = useState<number>(0);

    const [excludeUsersSpentPeriod, setExcludeUsersSpentPeriod] = useState<boolean>(false);
    const [excludeSpentPeriod, setExcludeSpentPeriod] = useState<string>('months');
    const [excludeSpentPeriodAmount, setExcludeSpentPeriodAmount] = useState<number>(1);

    const [excludeUsersMessagedIn, setExcludeUsersMessagedIn] = useState<boolean>(false);
    const [excludeUsersMessagedInPeriod, setExcludeUsersMessagedInPeriod] = useState<string>('hours');
    const [excludeUsersMessagedInPeriodAmount, setExcludeUsersMessagedInPeriodAmount] = useState<number>(1);

    const [excludeFans, setExcludeFans] = useState<boolean>(false);

    const [includeMedia, setIncludeMedia] = useState<boolean>(false);
    const [medias, setMedias] = useState<OnlyFansVaultMedias.RestructuredResponseItem[]>([]);
    const [thumbnails, setThumbnails] = useState<any[]>([]);
    const [mediasPreviews, setMediasPreviews] = useState<number[]>([]);

    const [tagCreators, setTagCreators] = useState<boolean>(false);
    const [taggedCreators, setTaggedCreators] = useState<{ id: number; name: string }[]>([]);
    const [taggedReleaseForms, setTaggedReleaseForms] = useState<{ id: number; name: string }[]>([]);

    const [lockMessage, setLockMessage] = useState<boolean>(false);
    const [lockMessagePrice, setLockMessagePrice] = useState<number>(0);
    const [lockedText, setLockedText] = useState<boolean>(false);

    const [autoUnsendTimer, setAutoUnsendTimer] = useState<boolean>(false);
    const autoUnsendTimerPeriod: string = 'hours';
    const [autoUnsendTimerPeriodAmount, setAutoUnsendTimerPeriodAmount] = useState<number>(4);

    const [delayBetweenMessages, setDelayBetweenMessages] = useState<number>(
        localStorage.getItem('messageUsersDelayBetweenMessages')
            ? parseInt(localStorage.getItem('messageUsersDelayBetweenMessages') as string)
            : 5000,
    );

    const [message, setMessage] = useState<string>('');
    const [editorState, setEditorState] = useState(EditorState.createWithContent(ContentState.createFromText(message)));
    const [messageHasRestrictedWords, setMessageHasRestrictedWords] = useState<boolean>(false);
    const [messageFoundRestrictedWords, setMessageFoundRestrictedWords] = useState<string[]>([]);
    const [usernameReplacement, setUsernameReplacement] = useState<string>('');
    const [saveTemplate, setSaveTemplate] = useState<boolean>(false);
    const [saveTemplateName, setSaveTemplateName] = useState<string>('');

    const [isSendingMessage, setIsSendingMessage] = useState<boolean>(false);

    const {
        data: lists,
        isLoading: listsLoading,
        isFetching: listsFetching,
        refetch: refetchLists,
    } = useSubscriberLists(targetUsers === 'lists' || excludeUsersList ? true : false);

    useEffect(() => {
        if (userContext.jwtToken && settingsContext.apiKey && 'userId' in params && params.userId && searchParams.has('sessionId')) {
            const fetchSessionDetails = async () => {
                const query: string = `${settingsContext.routes.messageUsers.base}${params.userId}/session?sessionId=${searchParams.get(
                    'sessionId',
                )}`;

                await fetch(query, {
                    method: 'get',
                    headers: {
                        Authorization: userContext.jwtToken,
                        apiKey: settingsContext.apiKey,
                    },
                })
                    .then(async response => {
                        if (response.ok) {
                            return response.json();
                        } else {
                            handleHttpErrorResponse(response, dialog);
                        }
                    })
                    .then(data => {
                        if (!data || !('_id' in data) || !data._id) {
                            navigate(`/subscribers/${subscriber._id}/${settingsContext.services.messageUsers.homeUrl}/sendMessage`);
                        } else {
                            setMessageUsersCurrentSession(data);
                        }
                    })
                    .catch(error => {
                        console.error(error);
                        handleHttpError(error, dialog);
                    });
            };

            fetchSessionDetails();
        }
    }, [
        dialog,
        navigate,
        params,
        searchParams,
        settingsContext.apiKey,
        settingsContext.routes.messageUsers.base,
        settingsContext.services.messageUsers.homeUrl,
        subscriber,
        userContext.jwtToken,
    ]);

    useEffect(() => {
        if (subscriber) {
            if (isSextforceActive(subscriber)) {
                setUnitCost(0);
            } else {
                setUnitCost(
                    d2f(
                        targetUsers === 'onlineActive'
                            ? subscriber._config.messageOnlineUsers.prices.unitCost
                            : subscriber._config.messageUsers.prices.unitCost,
                    ),
                );
            }
        }
    }, [subscriber, targetUsers]);

    useEffect(() => {
        localStorage.setItem('messageUsersTargetUsers', targetUsers);
    }, [targetUsers]);

    useEffect(() => {
        // Save the value to local storage
        localStorage.setItem('messageUsersDelayBetweenMessages', delayBetweenMessages.toString());
    }, [delayBetweenMessages]);

    // Check the message doesn't contain any OnlyFans restricted words
    const checkForRestrictedWords = useCallback(
        (text: string) => {
            if (text && !isLoadingRestrictedWords && restrictedWords && subscriber) {
                // const lowerCaseMessage = text.toLowerCase();
                // const wordsInMessage = lowerCaseMessage.split(/\b\w+\b/g);

                // const restrictedWordsFound = wordsInMessage.filter(word => restrictedWords.has(word));

                // if (subscriber.sextforce?.bigBrother?.restrictedWordsWhiteList?.includes(restrictedWordsFound)) {
                //     // Remove any words that are in the whitelist
                //     restrictedWordsFound.filter(word => !subscriber.sextforce?.bigBrother?.restrictedWordsWhiteList?.includes(word));
                // }
                const restrictedWordsFound = getRestrictedWordsInMessage(subscriber, text, restrictedWords);

                setMessageHasRestrictedWords(restrictedWordsFound.length > 0 ? true : false);
                setMessageFoundRestrictedWords(restrictedWordsFound);
            } else {
                setMessageHasRestrictedWords(false);
                setMessageFoundRestrictedWords([]);
            }
        },
        [isLoadingRestrictedWords, restrictedWords, subscriber],
    );

    const getThumbnail = (thumbId: number, thumbUrl: string) => {
        getSubscriberThumbnail(subscriber, thumbId, thumbUrl)?.then(blob => {
            if (blob) {
                setThumbnails(prevState => {
                    return [...prevState, { thumbId, blob }];
                });
            } else {
                setThumbnails(prevState => {
                    return [...prevState, { thumbId, blob: null }];
                });
            }
        });
    };

    const getAudio = (url: string) => getSubscriberAudio(subscriber, url);

    // Start the Send Message session
    const sendMessage = () => {
        const queueSendMessage = async () => {
            setIsSendingMessage(true);

            const query: string = `${settingsContext.routes.messageUsers.base}${params.userId}/sendMessage`;

            await axios
                .post(query, {
                    scheduleMessageDate: scheduleMessage ? scheduleMessageDate : undefined,
                    targetUsers,
                    targetListId: undefined,
                    targetListName: undefined,
                    targetLists,
                    targetRecentSpendersDateFrom,
                    targetRecentSpendersDateTo,
                    targetRecentFansDateFrom,
                    targetRecentFansDateTo,
                    excludeCreators,
                    excludeUnreadChats,
                    excludeLimitMessageCount: excludeLimitMessage ? excludeLimitMessageCount : undefined,
                    excludeLists: excludeUsersList ? excludeUsersLists : undefined,
                    excludeSpentCondition: excludeUsersSpent ? excludeSpentCondition : undefined,
                    excludeSpentAmount: excludeUsersSpent ? excludeSpentAmount : undefined,
                    excludeSpentPeriod: excludeUsersSpentPeriod ? excludeSpentPeriod : undefined,
                    excludeSpentPeriodAmount: excludeUsersSpentPeriod ? excludeSpentPeriodAmount : undefined,
                    excludeMessagedInPeriod: excludeUsersMessagedIn ? excludeUsersMessagedInPeriod : undefined,
                    excludeMessagedInPeriodAmount: excludeUsersMessagedIn ? excludeUsersMessagedInPeriodAmount : undefined,
                    excludeFans: targetUsers === 'onlineFollowing' ? excludeFans : false,
                    lockMessagePrice: lockMessage ? lockMessagePrice : undefined,
                    lockedText: lockMessage && lockedText ? lockedText : undefined,
                    autoUnsendTimerPeriod: autoUnsendTimer ? autoUnsendTimerPeriod : undefined,
                    autoUnsendTimerPeriodAmount: autoUnsendTimer ? autoUnsendTimerPeriodAmount : undefined,
                    message,
                    usernameReplacement: usernameReplacement.trim().length > 0 ? usernameReplacement.trim() : undefined,
                    saveTemplateName: saveTemplate && saveTemplateName.trim().length > 0 ? saveTemplateName.trim() : undefined,
                    medias: medias.map(media => media.id),
                    mediasPreviews,
                    taggedCreators: tagCreators ? taggedCreators : undefined,
                    taggedReleaseForms: taggedReleaseForms ? taggedReleaseForms : undefined,
                    delayBetweenMessages: delayBetweenMessages ? delayBetweenMessages : undefined,
                    colorId,
                    repeatEveryAmount: repeatMessage ? repeatEveryAmount : undefined,
                    repeatEveryUnit: repeatMessage ? repeatEveryUnit : undefined,
                    repeatEndDate:
                        repeatMessage && repeatEndDate && !moment(repeatEndDate).isSame('1970-01-01T00:00:00.000Z')
                            ? repeatEndDate
                            : undefined,
                })
                .then(response => {
                    if (response.data && response.status === 200) {
                        const result = response.data as { success: boolean; scheduleMessageDate?: Date };

                        if (result.success) {
                            if (result.scheduleMessageDate) {
                                enqueueSnackbar('Message scheduled successfully!', { variant: 'success' });

                                refetchMessageUsersScheduledSessions();
                            } else {
                                enqueueSnackbar('Message submitted successfully!', { variant: 'success' });

                                refetchMessageUsersCurrentSessions();

                                if (repeatMessage) {
                                    refetchMessageUsersScheduledSessions();
                                }
                            }
                        } else {
                            enqueueSnackbar('Failed to send Message. Please try again.', { variant: 'error' });
                        }
                    } else {
                        enqueueSnackbar(
                            `Failed to send Message - ${
                                response.data && response.data.message ? response.data.message : 'Unknown error'
                            } Please try again.`,
                            { variant: 'error' },
                        );
                    }

                    setIsSendingMessage(false);
                })
                .catch(async error => {
                    console.error(error);

                    setIsSendingMessage(false);

                    enqueueSnackbar(
                        `Failed to send Message - ${
                            error.response && error.response.data && error.response.data.message
                                ? error.response.data.message
                                : 'Unknown error'
                        } Please try again.`,
                        { variant: 'error' },
                    );
                });

            setIsSendingMessage(false);
        };

        queueSendMessage();
    };

    // Reset the whole form to default state
    const resetForm = () => {
        setMessageUsersCurrentSession(null);

        setScheduleMessage(false);
        setScheduleMessageDate(null);
        setColorId(undefined);
        setRepeatMessage(false);
        setRepeatEveryAmount(1);
        setRepeatEveryUnit('hours');
        setRepeatEndDate(null);

        setTargetUsers((localStorage.getItem('messageUsersTargetUsers') as OnlyFansSubscriptionsUserType) || 'onlineFollowing');
        setTargetRecentSpendersDateFrom(moment().subtract(1, 'months').startOf('day').toDate());
        setTargetRecentSpendersDateTo(moment().toDate());
        setTargetRecentFansDateFrom(moment().subtract(1, 'months').startOf('day').toDate());
        setTargetRecentFansDateTo(moment().toDate());

        setExcludeCreators(true);

        setExcludeUnreadChats(false);

        setExcludeLimitMessage(false);
        setExcludeLimitMessageCount(1);

        setExcludeUsersList(false);
        setExcludeUsersLists([]);

        setExcludeUsersSpent(false);
        setExcludeSpentCondition('eq');
        setExcludeSpentAmount(0);

        setExcludeUsersSpentPeriod(false);
        setExcludeSpentPeriod('months');
        setExcludeSpentPeriodAmount(1);

        setExcludeUsersMessagedIn(false);
        setExcludeUsersMessagedInPeriod('hours');
        setExcludeUsersMessagedInPeriodAmount(1);

        setIncludeMedia(false);
        setMedias([]);
        setMediasPreviews([]);

        setTagCreators(false);
        setTaggedCreators([]);
        setTaggedReleaseForms([]);

        setLockMessage(false);
        setLockMessagePrice(0);
        setLockedText(false);

        setAutoUnsendTimer(false);
        setAutoUnsendTimerPeriodAmount(4);

        setDelayBetweenMessages(
            localStorage.getItem('messageUsersDelayBetweenMessages')
                ? parseInt(localStorage.getItem('messageUsersDelayBetweenMessages') as string)
                : 5000,
        );

        setUsernameReplacement('');
        setMessage('');
        setEditorState(EditorState.createWithContent(ContentState.createFromText('')));
        setMessageHasRestrictedWords(false);
        setMessageFoundRestrictedWords([]);
        setSaveTemplate(false);
        setSaveTemplateName('');

        if (searchParams.has('sessionId')) {
            navigate(`/subscribers/${subscriber._id}/${settingsContext.services.messageUsers.homeUrl}/sendMessage`, { replace: false });
        }
    };

    // Reset the form when the subscriber changes
    useEffect(() => {
        resetForm();
    }, [subscriber?._id]);

    return (
        <PageContainer>
            {!subscriberLoading && subscriber && '_id' in subscriber && subscriber._id && (
                <>
                    <Typography variant="h5" sx={{ marginBottom: theme.spacing(2) }}>
                        <BackNavigationButton url={`/subscribers/${subscriber._id}/${settingsContext.services.messageUsers.homeUrl}`} />{' '}
                        Send Message for {subscriber.username}
                    </Typography>

                    {unitCost !== 0 && (
                        <Grid container spacing={1} flexGrow={0} justifyContent="center" sx={{ marginBottom: theme.spacing(2) }}>
                            <Grid item xs={12} md={6}>
                                <SharedCreditCard showTopUpButton={false} />
                            </Grid>
                        </Grid>
                    )}

                    <Grid
                        container
                        spacing={1}
                        flexGrow={0}
                        alignItems="center"
                        justifyContent="center"
                        sx={{ marginBottom: theme.spacing(1) }}
                    >
                        <Grid item xs={12} md={6}>
                            <Card variant="elevation">
                                <CardContent>
                                    <Grid container spacing={1} flexGrow={0} justifyContent="center">
                                        <Grid item xs={12}>
                                            <Typography variant="body1">
                                                Please use the form below to send individual messages to the target group of users. Each
                                                user in the group, who can receive messages from you, will receive a regular message from
                                                you which will appear in their Priority inbox.
                                                <br />
                                                <br />
                                                Sending a message to the users who are <strong>currently online</strong> is a special
                                                feature. The system will double check that each fan is indeed online before sending the
                                                message, making the targetting much stronger and more cost effective.
                                                <br />
                                                <br />
                                                For more information and tips, please check out the{' '}
                                                <Link
                                                    to={`/subscribers/${subscriber._id}/services/messageUsers/welcome`}
                                                    style={{ color: theme.palette.primary.main, textDecoration: 'none' }}
                                                >
                                                    <strong>Welcome</strong>
                                                </Link>{' '}
                                                page.
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <MessageUsersPriceBar unitCost={unitCost} includedWith="Sextforce" theme={theme} />
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>

                    <MessageUsersForm
                        subscriber={subscriber}
                        loading={false}
                        session={messageUsersCurrentSession}
                        unitCost={unitCost}
                        lists={lists}
                        listsLoading={listsLoading || listsFetching}
                        refetchLists={refetchLists}
                        scheduleMessage={scheduleMessage}
                        setScheduleMessage={setScheduleMessage}
                        scheduleMessageDate={scheduleMessageDate}
                        setScheduleMessageDate={setScheduleMessageDate}
                        colorId={colorId}
                        setColorId={setColorId}
                        repeatMessage={repeatMessage}
                        setRepeatMessage={setRepeatMessage}
                        repeatEveryAmount={repeatEveryAmount}
                        setRepeatEveryAmount={setRepeatEveryAmount}
                        repeatEveryUnit={repeatEveryUnit}
                        setRepeatEveryUnit={setRepeatEveryUnit}
                        repeatEndDate={repeatEndDate}
                        setRepeatEndDate={setRepeatEndDate}
                        targetUsers={targetUsers}
                        setTargetUsers={setTargetUsers}
                        targetLists={targetLists}
                        setTargetLists={setTargetLists}
                        targetRecentSpendersDateFrom={targetRecentSpendersDateFrom}
                        setTargetRecentSpendersDateFrom={setTargetRecentSpendersDateFrom}
                        targetRecentSpendersDateTo={targetRecentSpendersDateTo}
                        setTargetRecentSpendersDateTo={setTargetRecentSpendersDateTo}
                        targetRecentFansDateFrom={targetRecentFansDateFrom}
                        setTargetRecentFansDateFrom={setTargetRecentFansDateFrom}
                        targetRecentFansDateTo={targetRecentFansDateTo}
                        setTargetRecentFansDateTo={setTargetRecentFansDateTo}
                        excludeCreators={excludeCreators}
                        setExcludeCreators={setExcludeCreators}
                        excludeUnreadChats={excludeUnreadChats}
                        setExcludeUnreadChats={setExcludeUnreadChats}
                        excludeLimitMessage={excludeLimitMessage}
                        setExcludeLimitMessage={setExcludeLimitMessage}
                        excludeLimitMessageCount={excludeLimitMessageCount}
                        setExcludeLimitMessageCount={setExcludeLimitMessageCount}
                        excludeUsersList={excludeUsersList}
                        setExcludeUsersList={setExcludeUsersList}
                        excludedLists={excludeUsersLists}
                        setExcludedLists={setExcludeUsersLists}
                        excludeUsersSpent={excludeUsersSpent}
                        setExcludeUsersSpent={setExcludeUsersSpent}
                        excludeSpentCondition={excludeSpentCondition}
                        setExcludeSpentCondition={setExcludeSpentCondition}
                        excludeSpentAmount={excludeSpentAmount}
                        setExcludeSpentAmount={setExcludeSpentAmount}
                        excludeUsersSpentPeriod={excludeUsersSpentPeriod}
                        setExcludeUsersSpentPeriod={setExcludeUsersSpentPeriod}
                        excludeSpentPeriod={excludeSpentPeriod}
                        setExcludeSpentPeriod={setExcludeSpentPeriod}
                        excludeSpentPeriodAmount={excludeSpentPeriodAmount}
                        setExcludeSpentPeriodAmount={setExcludeSpentPeriodAmount}
                        excludeUsersMessagedIn={excludeUsersMessagedIn}
                        setExcludeUsersMessagedIn={setExcludeUsersMessagedIn}
                        excludeUsersMessagedInPeriod={excludeUsersMessagedInPeriod}
                        setExcludeUsersMessagedInPeriod={setExcludeUsersMessagedInPeriod}
                        excludeUsersMessagedInPeriodAmount={excludeUsersMessagedInPeriodAmount}
                        setExcludeUsersMessagedInPeriodAmount={setExcludeUsersMessagedInPeriodAmount}
                        excludeFans={excludeFans}
                        setExcludeFans={setExcludeFans}
                        includeMedia={includeMedia}
                        setIncludeMedia={setIncludeMedia}
                        medias={medias}
                        setMedias={setMedias}
                        thumbnails={thumbnails}
                        mediasPreviews={mediasPreviews}
                        setMediasPreviews={setMediasPreviews}
                        setThumbnails={setThumbnails}
                        getThumbnail={getThumbnail}
                        getAudio={getAudio}
                        tagCreators={tagCreators}
                        setTagCreators={setTagCreators}
                        taggedCreators={taggedCreators}
                        setTaggedCreators={setTaggedCreators}
                        taggedReleaseForms={taggedReleaseForms}
                        setTaggedReleaseForms={setTaggedReleaseForms}
                        lockMessage={lockMessage}
                        setLockMessage={setLockMessage}
                        lockMessagePrice={lockMessagePrice}
                        setLockMessagePrice={setLockMessagePrice}
                        lockedText={lockedText}
                        setLockedText={setLockedText}
                        autoUnsendTimer={autoUnsendTimer}
                        setAutoUnsendTimer={setAutoUnsendTimer}
                        autoUnsendTimerPeriodAmount={autoUnsendTimerPeriodAmount}
                        setAutoUnsendTimerPeriodAmount={setAutoUnsendTimerPeriodAmount}
                        delayBetweenMessages={delayBetweenMessages}
                        setDelayBetweenMessages={setDelayBetweenMessages}
                        message={message}
                        setMessage={setMessage}
                        editorState={editorState}
                        setEditorState={setEditorState}
                        messageHasRestrictedWords={messageHasRestrictedWords}
                        messageFoundRestrictedWords={messageFoundRestrictedWords}
                        checkForRestrictedWords={checkForRestrictedWords}
                        usernameReplacement={usernameReplacement}
                        setUsernameReplacement={setUsernameReplacement}
                        saveTemplate={saveTemplate}
                        setSaveTemplate={setSaveTemplate}
                        saveTemplateName={saveTemplateName}
                        setSaveTemplateName={setSaveTemplateName}
                        isSendingMessage={isSendingMessage}
                        sendMessage={sendMessage}
                        resetForm={resetForm}
                        theme={theme}
                    />
                </>
            )}

            <Backdrop sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }} open={isSendingMessage}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </PageContainer>
    );
};

export default MessageUsersSendMessage;
