import CircleIcon from '@mui/icons-material/Circle';
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined';
import { Button, FormControl, MenuItem, Select, Stack, SxProps, Theme, Typography } from '@mui/material';
import { useState } from 'react';
import useSextforceAutoRepostColors from '../../hooks/useSextforceAutoRepostColors';
import SextforceAutoRepostColorsDialog from '../services/sextforce/autoRepost/SextforceAutoRepostColorsDialog';

type Props = {
    colorId?: string;
    setColorId: (colorId: string | undefined) => void;
    size?: 'small' | 'medium';
    sx?: SxProps<Theme>;
    selectSx?: SxProps<Theme>;
    buttonSx?: SxProps<Theme>;
};

const SextforceColorSelector = ({ colorId, setColorId, size, sx, selectSx, buttonSx }: Props) => {
    const { data: sextforceAutoRepostColors, isLoading: isSextforceAutoRepostColorsLoading } = useSextforceAutoRepostColors();

    const [showColorsDialog, setShowColorsDialog] = useState(false);

    return (
        <Stack direction="row" spacing={1} alignItems={'center'} justifyContent={'space-between'} sx={sx}>
            <FormControl fullWidth>
                <Select
                    id="color"
                    size={size}
                    value={colorId || 'none'}
                    onChange={e => setColorId(e.target.value === 'none' ? undefined : (e.target.value as string))}
                    sx={selectSx}
                >
                    <MenuItem value="none">
                        <Stack direction="row" spacing={1} alignItems={'center'} justifyContent={'flex-start'}>
                            <CircleOutlinedIcon
                                color="disabled"
                                sx={{
                                    diaplay: 'inline-block',
                                    fontSize: 20,
                                }}
                            />
                            <Typography variant="body1" color={'text.primary'}>
                                None
                            </Typography>
                        </Stack>
                    </MenuItem>
                    {isSextforceAutoRepostColorsLoading && <MenuItem disabled>Loading...</MenuItem>}
                    {!isSextforceAutoRepostColorsLoading &&
                        sextforceAutoRepostColors &&
                        sextforceAutoRepostColors.map(color => (
                            <MenuItem key={color._id} value={color._id}>
                                <Stack direction="row" spacing={1} alignItems={'center'} justifyContent={'flex-start'}>
                                    <CircleIcon
                                        sx={{
                                            diaplay: 'inline-block',
                                            color: color.color,
                                            fontSize: 20,
                                        }}
                                    />
                                    <Typography variant="body1" color={'text.primary'}>
                                        {color.name}
                                    </Typography>
                                </Stack>
                            </MenuItem>
                        ))}
                </Select>
            </FormControl>
            <Button
                variant="contained"
                color="info"
                size={size === 'small' ? 'medium' : 'large'}
                onClick={() => {
                    setShowColorsDialog(true);
                }}
                sx={buttonSx}
            >
                Edit...
            </Button>
            <SextforceAutoRepostColorsDialog open={showColorsDialog} onClose={() => setShowColorsDialog(false)} />
        </Stack>
    );
};

export default SextforceColorSelector;
