import { alpha, Box, Table, TableBody, TableCell, TableHead, TableRow, Theme, Typography, useTheme } from '@mui/material';
import moment from 'moment';
import { useDialog } from 'muibox';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import 'react-circular-progressbar/dist/styles.css';
import { OnlyFansWSChatQueueUpdateMessage } from '../../../../hooks/useSubscriberMassDmQueue';
import MessageUsersAutoMassDmQueueListRow from './MessageUsersAutoMassDmQueueListRow';

interface Props {
    massDmQueue: OnlyFansWSChatQueueUpdateMessage.ChatQueueUpdate[];
    refetchMassDmQueue: () => void;
}

const MessageUsersAutoMassDmQueueList = (props: Props) => {
    const { massDmQueue, refetchMassDmQueue } = props;
    const theme: Theme = useTheme();
    const dialog = useDialog();
    const { enqueueSnackbar } = useSnackbar();

    const [list, setList] = useState<OnlyFansWSChatQueueUpdateMessage.ChatQueueUpdate[]>(massDmQueue);
    const [lastUpdatedDate, setLastUpdatedDate] = useState<Date | null>(null);

    const [isAnimating, setIsAnimating] = useState(false);

    useEffect(() => {
        setIsAnimating(true);
        const timer = setTimeout(() => {
            setIsAnimating(false);
        }, 500); // Animation duration

        return () => clearTimeout(timer);
    }, [lastUpdatedDate]); // Empty dependency array since we want to trigger on mount/key change

    useEffect(() => {
        if (massDmQueue) {
            setList(massDmQueue);
            setLastUpdatedDate(new Date());
        }

        return () => {
            setList([]);
            setLastUpdatedDate(null);
        };
    }, [massDmQueue]);

    const handleCancel = (id: number) => {
        dialog
            .confirm({
                title: 'Cancel Mass DM',
                message: 'Are you sure you want to cancel this Mass DM?',
            })
            .then(() => {
                // Cancel the Mass DM
                enqueueSnackbar('Mass DM has been cancelled.', { variant: 'success' });

                // Refetch the Mass DM queue
                refetchMassDmQueue();
            })
            .catch(() => {
                // Do nothing
            });
    };

    return (
        <Box>
            <Box
                sx={{
                    display: 'block',
                    overflowX: 'auto',
                }}
            >
                <Table
                    size="small"
                    sx={{
                        '& td': {
                            borderBottom: 'none',
                            paddingRight: 0,
                        },
                        // Bold header
                        '& th': {
                            fontWeight: 'bold',
                            paddingRight: 0,
                        },
                        '& th:last-child, & td:last-child': {
                            paddingRight: 2,
                        },
                        // No wrapping
                        whiteSpace: 'nowrap',
                    }}
                >
                    <TableHead>
                        <TableRow>
                            <TableCell width={30} align="center">
                                %
                            </TableCell>
                            <TableCell width={140}>Created At</TableCell>
                            <TableCell>Name</TableCell>
                            <TableCell width={90} align="right">
                                Sent
                            </TableCell>
                            <TableCell width={90} align="right">
                                Total
                            </TableCell>
                            <TableCell width={48} align="center">
                                Cancel
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {list.map((queueItem, index) => (
                            <MessageUsersAutoMassDmQueueListRow key={queueItem.id} queueItem={queueItem} handleCancel={handleCancel} />
                        ))}
                    </TableBody>
                </Table>
            </Box>
            {lastUpdatedDate && (
                <Typography
                    variant="caption"
                    color="textSecondary"
                    key={lastUpdatedDate.valueOf()}
                    sx={{
                        pl: 2,
                    }}
                >
                    <span
                        style={{
                            // animate flash background color from success to transparent when updated
                            transition: theme.transitions.create('background-color', {
                                duration: theme.transitions.duration.short,
                                easing: theme.transitions.easing.easeOut,
                            }),
                            backgroundColor: isAnimating ? alpha(theme.palette.success.main, 0.5) : 'transparent',
                        }}
                    >
                        Last updated: {moment(lastUpdatedDate).format('L LT')}
                    </span>
                </Typography>
            )}
        </Box>
    );
};

export default MessageUsersAutoMassDmQueueList;
