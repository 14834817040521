import ClearIcon from '@mui/icons-material/Clear';
import { Divider, Grid, ImageList, ImageListItem, ImageListItemBar, Skeleton, Typography, useMediaQuery } from '@mui/material';
import { Theme } from '@mui/system';
import moment from 'moment';
import { OnlyFansVaultMedias } from '../../../../hooks/useSubscriberVaultAlbums';
import { mediaTypeIcon, secondsToMinutesAndSecondsString } from '../../../../utils/common';

type Props = {
    thumbnails: any[];
    medias: OnlyFansVaultMedias.RestructuredResponseItem[];
    setMedias: React.Dispatch<React.SetStateAction<OnlyFansVaultMedias.RestructuredResponseItem[]>>;
    mediasPreviews: number[];
    setMediasPreviews: React.Dispatch<React.SetStateAction<number[]>>;
    theme: Theme;
    disabled?: boolean;
};

const MessageUsersFormAddMediasRemoveSelected = (props: Props) => {
    const { thumbnails, medias, setMedias, mediasPreviews, setMediasPreviews, theme, disabled } = props;
    const isLargeScreen = useMediaQuery(theme.breakpoints.up('sm'));

    const handleRemoveMediaClicked = (mediaId: number) => {
        if (medias.find(media => media.id === mediaId)) {
            setMedias((prevMedias: OnlyFansVaultMedias.RestructuredResponseItem[]) => {
                return prevMedias.filter(m => m.id !== mediaId);
            });
        }

        if (mediasPreviews.includes(mediaId)) {
            setMediasPreviews((prevMedias: number[]) => {
                return prevMedias.filter(m => m !== mediaId);
            });
        }
    };

    return (
        <>
            <Divider sx={{ mb: 1, mt: 1 }} />
            <Typography variant="subtitle1" sx={{ mb: 1, mt: 1 }}>
                <ClearIcon color="error" sx={{ fontSize: '1.2rem', verticalAlign: 'middle' }} />
                Remove Selected Medias
            </Typography>
            <Typography variant="body1" sx={{ mb: 1, mt: 1 }}>
                Click on a media to remove it from the attachement list.
            </Typography>
            <ImageList cols={isLargeScreen ? 6 : 3} rowHeight={110}>
                {medias.map(media => (
                    <ImageListItem
                        key={media.id}
                        sx={{
                            cursor: disabled ? 'default' : 'pointer',
                            borderStyle: 'solid',
                            borderWidth: '2px',
                            borderColor: theme.palette.common.primary,
                            ...(disabled && {
                                opacity: 0.5,
                            }),
                            ':hover': disabled
                                ? {}
                                : {
                                      borderStyle: 'solid',
                                      borderWidth: '2px',
                                      borderColor: theme.palette.error.main,
                                      '& .MuiImageListItemBar-root': {
                                          backgroundColor: theme.palette.error.main,
                                      },
                                  },
                        }}
                    >
                        {media.type !== 'audio' ? (
                            media.cachedThumb ? (
                                <img
                                    src={media.cachedThumb}
                                    alt={media.id.toString()}
                                    loading="lazy"
                                    // height={110}
                                    // width={110}
                                    onClick={() => {
                                        if (!disabled) {
                                            handleRemoveMediaClicked(media.id);
                                        }
                                    }}
                                />
                            ) : thumbnails.find(thumbnail => thumbnail.thumbId === media.id && thumbnail.blob) ? (
                                <img
                                    src={URL.createObjectURL(
                                        thumbnails.find(thumbnail => thumbnail.thumbId === media.id && thumbnail.blob).blob,
                                    )}
                                    alt={media.id.toString()}
                                    loading="lazy"
                                    // height={100}
                                    // width={100}
                                    onClick={() => {
                                        if (!disabled) {
                                            handleRemoveMediaClicked(media.id);
                                        }
                                    }}
                                />
                            ) : (
                                <Skeleton />
                            )
                        ) : (
                            <Grid
                                container
                                justifyContent="center"
                                alignItems="center"
                                sx={{
                                    backgroundColor: theme.palette.grey[300],
                                    height: '100%',
                                }}
                                onClick={() => {
                                    if (!disabled) {
                                        handleRemoveMediaClicked(media.id);
                                    }
                                }}
                            >
                                <Grid item xs={12} sx={{ paddingBottom: theme.spacing(5) }}>
                                    <Grid container justifyContent="center" alignItems="center">
                                        <Grid item xs={12}>
                                            <div style={{ textAlign: 'center' }}>
                                                <Typography variant="body2" sx={{ color: theme.palette.grey[600] }}>
                                                    {secondsToMinutesAndSecondsString(media.duration || 0)}
                                                </Typography>
                                            </div>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        )}
                        <ImageListItemBar
                            title={moment(media.createdAt).calendar({
                                sameDay: '[Today]',
                                nextDay: '[Tomorrow]',
                                nextWeek: 'MMM Mo',
                                lastDay: '[Yesterday]',
                                lastWeek: 'MMM Mo',
                                sameElse: 'MMM Mo',
                            })}
                            // subtitle={`${media.counters.likesCount.toLocaleString()} Likes`}
                            actionIcon={mediaTypeIcon(media.type, theme)}
                            onClick={() => {
                                if (!disabled) {
                                    handleRemoveMediaClicked(media.id);
                                }
                            }}
                            sx={{
                                backgroundColor: '#00000080',
                                // Make the font size smaller
                                '.MuiImageListItemBar-title': {
                                    fontSize: '0.8rem',
                                },
                                '.MuiImageListItemBar-actionIcon': {
                                    marginTop: theme.spacing(0.5),
                                    marginRight: theme.spacing(1),
                                    // Make the icon smaller
                                    '& svg': {
                                        height: '1.2rem',
                                        width: '1.2rem',
                                    },
                                },
                            }}
                        />
                    </ImageListItem>
                ))}
            </ImageList>
        </>
    );
};

export default MessageUsersFormAddMediasRemoveSelected;
