import { Card, CardContent, Checkbox, FormControlLabel, Grid, Typography } from '@mui/material';
import { useEffect } from 'react';
import { OnlyFansSubscriber } from '../../../../hooks/useSubscriber';
import { OnlyFansVaultMedias } from '../../../../hooks/useSubscriberVaultAlbums';
import MessageUsersPriceLockAndPreviewSelector from './MessageUsersPriceLockAndPreviewSelector';

type Props = {
    subscriber: OnlyFansSubscriber;
    lockMessage: boolean;
    setLockMessage: (lockMessage: boolean) => void;
    lockMessagePrice: number;
    setLockMessagePrice: (price: number) => void;
    lockedText: boolean;
    setLockedText: (lockedText: boolean) => void;
    thumbnails: any[];
    medias: OnlyFansVaultMedias.RestructuredResponseItem[];
    mediasPreviews: number[];
    setMediasPreviews: React.Dispatch<React.SetStateAction<number[]>>;
    session?: any;
    disabled?: boolean;
};

const MessageUsersPriceLock = (props: Props) => {
    const {
        subscriber,
        lockMessage,
        setLockMessage,
        lockMessagePrice,
        setLockMessagePrice,
        lockedText,
        setLockedText,
        thumbnails,
        medias,
        mediasPreviews,
        setMediasPreviews,
        session,
        disabled,
    } = props;

    useEffect(() => {
        if (session && session.payload && session.payload.lockMessage) {
            setLockMessage(session.payload.lockMessage);
            setLockMessagePrice(session.payload.lockMessagePrice);
            setLockedText(session.payload.lockedText);
        }
    }, [session, setLockMessage, setLockMessagePrice, setLockedText]);

    return (
        <Grid container spacing={1} flexGrow={0} alignItems="center" justifyContent="center" sx={{ marginBottom: 1 }}>
            <Grid item xs={12}>
                <Card variant="elevation">
                    <CardContent>
                        <Grid container spacing={2} flexGrow={0} alignItems="center" justifyContent="center">
                            <Grid item xs={12}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            size="medium"
                                            checked={lockMessage}
                                            onChange={(e, checked) => {
                                                setLockMessage(checked);
                                            }}
                                            disabled={disabled}
                                        />
                                    }
                                    label={<Typography variant="h6">Lock Message (PPV)?</Typography>}
                                />
                            </Grid>
                            {lockMessage && (
                                <Grid item xs={12}>
                                    <MessageUsersPriceLockAndPreviewSelector
                                        subscriber={subscriber}
                                        lockMessagePrice={lockMessagePrice}
                                        setLockMessagePrice={setLockMessagePrice}
                                        lockedText={lockedText}
                                        setLockedText={setLockedText}
                                        thumbnails={thumbnails}
                                        medias={medias}
                                        mediasPreviews={mediasPreviews}
                                        setMediasPreviews={setMediasPreviews}
                                        session={session}
                                        disabled={disabled}
                                    />
                                </Grid>
                            )}
                        </Grid>
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    );
};

export default MessageUsersPriceLock;
