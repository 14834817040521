import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ClearIcon from '@mui/icons-material/Clear';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import {
    Checkbox,
    Chip,
    Divider,
    FormControl,
    FormControlLabel,
    Grid,
    IconButton,
    InputAdornment,
    InputLabel,
    OutlinedInput,
    Stack,
    Theme,
    Tooltip,
    Typography,
    useTheme,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { OnlyFansSubscriber } from '../../../../hooks/useSubscriber';
import useSubscriberMedia from '../../../../hooks/useSubscriberMedia';
import { OnlyFansVaultMedias } from '../../../../hooks/useSubscriberVaultAlbums';
import MessageUsersPriceLockPreviewSelector from './MessageUsersPriceLockPreviewSelector';

type Props = {
    subscriber: OnlyFansSubscriber;
    lockMessagePrice: number;
    setLockMessagePrice: (price: number) => void;
    lockedText: boolean;
    setLockedText: (lockedText: boolean) => void;
    thumbnails: any[];
    medias: OnlyFansVaultMedias.RestructuredResponseItem[];
    mediasPreviews: number[];
    setMediasPreviews: React.Dispatch<React.SetStateAction<number[]>>;
    session?: any;
    disabled?: boolean;
};

const MessageUsersPriceLockAndPreviewSelector = (props: Props) => {
    const {
        subscriber,
        lockMessagePrice,
        setLockMessagePrice,
        lockedText,
        setLockedText,
        thumbnails,
        medias,
        mediasPreviews,
        setMediasPreviews,
        disabled,
    } = props;

    const theme: Theme = useTheme();

    const { getAudio: getSubscriberAudio } = useSubscriberMedia();

    const [lockMessagePriceString, setLockMessagePriceString] = useState(lockMessagePrice.toString());

    useEffect(() => {
        setLockMessagePriceString(lockMessagePrice.toString());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handlePreviewsSelectAllClicked = () => {
        const newPreviews = medias.map(media => media.id);

        setMediasPreviews(newPreviews);
    };

    const handlePreviewsClearSelectionClicked = () => {
        setMediasPreviews([]);
    };

    const getAudio = (url: string) => getSubscriberAudio(subscriber, url);

    return (
        <Grid container spacing={2} flexGrow={0} alignItems="center" justifyContent="center">
            <Grid item xs={12}>
                <Grid container spacing={2} flexGrow={0} alignItems="center">
                    <Grid item xs={12} sm="auto">
                        <FormControl disabled={disabled} fullWidth>
                            <InputLabel htmlFor="amount">Price</InputLabel>
                            <OutlinedInput
                                id="amount"
                                size="medium"
                                inputProps={{ inputMode: 'text', pattern: /([0-9]+([.][0-9]*)?|[.][0-9]+)/ }}
                                value={lockMessagePriceString}
                                onChange={e => {
                                    e.preventDefault();

                                    if (/^([\d]*[,.]?[\d]{0,2})$/.test(e.target.value)) {
                                        const parsed = parseFloat(e.target.value.replaceAll(',', '.'));

                                        if (isNaN(parsed) || parsed === 0) {
                                            setLockMessagePrice(0);
                                            setLockMessagePriceString('0');
                                        } else {
                                            setLockMessagePrice(parsed);
                                            setLockMessagePriceString(e.target.value.replace(/^0+/, ''));
                                        }
                                    }
                                }}
                                startAdornment={<InputAdornment position="start">$</InputAdornment>}
                                label="Price"
                                error={lockMessagePrice < 3}
                            />
                            <Typography variant="caption" color={lockMessagePrice < 3 ? 'error' : theme.palette.text.secondary}>
                                Price must be at least $3 USD
                            </Typography>
                        </FormControl>
                    </Grid>
                </Grid>
            </Grid>

            <Grid item xs={12}>
                <FormControlLabel
                    control={
                        <Checkbox
                            size="medium"
                            checked={lockedText}
                            onChange={(e, checked) => {
                                setLockedText(checked);
                            }}
                            disabled={disabled}
                        />
                    }
                    label={
                        <Stack direction="column">
                            <Typography>Lock Message Text?</Typography>
                            <Typography variant="caption" color="textSecondary">
                                If enabled, the message text will be locked and only visible to the fan after they pay.
                            </Typography>
                        </Stack>
                    }
                />
            </Grid>

            <Grid item xs={12}>
                <Divider />
            </Grid>

            <Grid item xs>
                <Typography variant="h6">Preview (Optional)</Typography>
            </Grid>
            <Grid item xs={'auto'}>
                <Tooltip title="Select All">
                    <IconButton
                        onClick={async () => {
                            handlePreviewsSelectAllClicked();
                        }}
                        disabled={disabled}
                        color="primary"
                    >
                        <DoneAllIcon fontSize="large" />
                    </IconButton>
                </Tooltip>
            </Grid>
            <Grid item xs={'auto'}>
                <Tooltip title="Clear Selection">
                    <IconButton
                        color="error"
                        onClick={() => {
                            handlePreviewsClearSelectionClicked();
                        }}
                        disabled={disabled}
                    >
                        <ClearIcon fontSize="large" />
                    </IconButton>
                </Tooltip>
            </Grid>
            <Grid item xs={12} sx={{ marginBottom: theme.spacing(1) }}>
                Select the media that will be used as a preview. These will be fully visible to the fan!
            </Grid>
            <Grid item xs={12}>
                <Grid container spacing={1} alignItems="center">
                    <Grid item xs>
                        <div style={{ color: theme.palette.grey[500] }}>Click to select</div>
                    </Grid>
                    <Grid item xs={'auto'}>
                        <Chip
                            color={mediasPreviews.length > 40 ? 'error' : 'secondary'}
                            icon={<CheckCircleIcon />}
                            label={`${mediasPreviews.length}/40 selected`}
                            disabled={disabled}
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <MessageUsersPriceLockPreviewSelector
                    medias={medias}
                    mediasPreviews={mediasPreviews}
                    setMediasPreviews={setMediasPreviews}
                    thumbnails={thumbnails}
                    getAudio={getAudio}
                    theme={theme}
                    disabled={disabled}
                />
            </Grid>
        </Grid>
    );
};

export default MessageUsersPriceLockAndPreviewSelector;
