import FavoriteIcon from '@mui/icons-material/Favorite';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import WatchIcon from '@mui/icons-material/Watch';
import { Grid, ImageList, ImageListItem, ImageListItemBar, Skeleton, Stack, useMediaQuery } from '@mui/material';
import { alpha, Theme } from '@mui/system';
import moment from 'moment';
import { useState } from 'react';
import 'react-h5-audio-player/lib/styles.css';
import { OnlyFansVaultMedias } from '../../../../hooks/useSubscriberVaultAlbums';
import { VaultMediaResponseList } from '../../../../hooks/useSubscriberVaultMedias';
import { mediaTypeIcon, secondsToMinutesAndSecondsString } from '../../../../utils/common';
import AudioPlayerDialog from '../../../common/AudioPlayerDialog';

type Props = {
    albumMedias: VaultMediaResponseList[];
    thumbnails: any[];
    medias: OnlyFansVaultMedias.RestructuredResponseItem[];
    setMedias: React.Dispatch<React.SetStateAction<OnlyFansVaultMedias.RestructuredResponseItem[]>>;
    mediasPreviews: number[];
    setMediasPreviews: React.Dispatch<React.SetStateAction<number[]>>;
    getAudio: (url: string) => Promise<Blob | null>;
    tagCreators: boolean;
    setTagCreators: (tagCreators: boolean) => void;
    taggedCreators: { id: number; name: string }[];
    setTaggedCreators: React.Dispatch<React.SetStateAction<{ id: number; name: string }[]>>;
    taggedReleaseForms: { id: number; name: string }[];
    setTaggedReleaseForms: React.Dispatch<React.SetStateAction<{ id: number; name: string }[]>>;
    theme: Theme;
    disabled?: boolean;
};

const MessageUsersFormAddMediasSelectorImageList = (props: Props) => {
    const {
        albumMedias,
        thumbnails,
        medias,
        setMedias,
        mediasPreviews,
        setMediasPreviews,
        getAudio,
        tagCreators,
        setTagCreators,
        taggedCreators,
        setTaggedCreators,
        taggedReleaseForms,
        setTaggedReleaseForms,
        theme,
        disabled,
    } = props;
    const isLargeScreen = useMediaQuery(theme.breakpoints.up('sm'));
    const [playerOpen, setPlayerOpen] = useState(false);
    const [selectedAudioUrl, setSelectedAudioUrl] = useState('');

    const handleMediaClicked = (albumMedia: VaultMediaResponseList) => {
        if (disabled) {
            return;
        }

        if (medias.find(media => media.id === albumMedia.id)) {
            setMedias(prevMedias => {
                return prevMedias.filter(media => media.id !== albumMedia.id);
            });
        } else {
            setMedias(prevMedias => {
                return [
                    ...prevMedias,
                    {
                        id: albumMedia.id,
                        type: albumMedia.type,
                        src: albumMedia.src || '',
                        cachedThumb: albumMedia.cachedThumb,
                        createdAt: albumMedia.createdAt,
                        counters: albumMedia.counters,
                        duration: albumMedia.duration || 0,
                        hasError: albumMedia.hasError,
                        thumb: albumMedia.thumb,
                        canView: albumMedia.canView,
                    },
                ];
            });

            const missingMentionedUsers: { id: number; name: string }[] = [];
            const missingReleaseForms: { id: number; name: string }[] = [];

            // Does taggedCreators already have these mentionedUsers?
            if (albumMedia.mentionedUsers && albumMedia.mentionedUsers.length > 0) {
                for (let i = 0; i < albumMedia.mentionedUsers.length; i += 1) {
                    const mentionedUser = albumMedia.mentionedUsers[i];

                    if (
                        !taggedCreators.find(taggedCreator => taggedCreator.id === mentionedUser.id) &&
                        !missingMentionedUsers.find(missingMentionedUser => missingMentionedUser.id === mentionedUser.id)
                    ) {
                        missingMentionedUsers.push({ id: mentionedUser.id, name: mentionedUser.name });
                    }
                }
            }

            // Add any missing releaseFormsUsers to taggedCreators
            const releaseFromsUsers = albumMedia.releaseForms && albumMedia.releaseForms.filter(releaseForm => releaseForm.type === 'user');

            if (releaseFromsUsers && releaseFromsUsers.length > 0) {
                for (let i = 0; i < releaseFromsUsers.length; i += 1) {
                    const releaseFormUser = releaseFromsUsers[i].user;

                    if (
                        !taggedCreators.find(taggedCreator => taggedCreator.id === releaseFormUser.id) &&
                        !missingMentionedUsers.find(missingMentionedUser => missingMentionedUser.id === releaseFormUser.id)
                    ) {
                        missingMentionedUsers.push({ id: releaseFormUser.id, name: releaseFormUser.name });
                    }
                }
            }

            // Only tag creators if the releaseForm is a document
            const releaseFormsDocuments =
                albumMedia.releaseForms && albumMedia.releaseForms.filter(releaseForm => releaseForm.type === 'document');

            if (releaseFormsDocuments && releaseFormsDocuments.length > 0) {
                // Does taggedReleaseForms already have these releaseFormsFiltered?
                for (let i = 0; i < releaseFormsDocuments.length; i += 1) {
                    const releaseForm = releaseFormsDocuments[i];

                    if (
                        !taggedReleaseForms.find(taggedReleaseForm => taggedReleaseForm.id === releaseForm.id) &&
                        !missingReleaseForms.find(missingReleaseForm => missingReleaseForm.id === releaseForm.id)
                    ) {
                        missingReleaseForms.push({ id: releaseForm.id, name: releaseForm.name });
                    }
                }
            }

            if (!tagCreators && (missingMentionedUsers.length > 0 || missingReleaseForms.length > 0)) {
                setTagCreators(true);
            }

            // Add the missing mentionedUsers to taggedCreators
            if (missingMentionedUsers.length > 0) {
                setTaggedCreators(prevTaggedCreators => {
                    return [...prevTaggedCreators, ...missingMentionedUsers];
                });
            }

            // Add the missing releaseFormsFiltered to taggedReleaseForms
            if (missingReleaseForms.length > 0) {
                setTaggedReleaseForms(prevTaggedReleaseForms => {
                    return [...prevTaggedReleaseForms, ...missingReleaseForms];
                });
            }
        }

        if (mediasPreviews.includes(albumMedia.id)) {
            setMediasPreviews(prevMediasPreviews => {
                return prevMediasPreviews.filter(mediaPreview => mediaPreview !== albumMedia.id);
            });
        }
    };

    return (
        <>
            <ImageList cols={isLargeScreen ? 4 : 3} rowHeight={164}>
                {albumMedias
                    .filter(item => !item.hasError && item.canView)
                    .map((item, index) => (
                        <ImageListItem
                            key={index}
                            sx={{
                                cursor: disabled ? 'default' : 'pointer',
                                borderStyle: 'solid',
                                borderWidth: '2px',
                                borderColor: medias.find(media => media.id === item.id)
                                    ? theme.palette.primary.main
                                    : theme.palette.common.white,
                                ':hover': disabled
                                    ? {}
                                    : { borderStyle: 'solid', borderWidth: '2px', borderColor: theme.palette.secondary.main },
                                ...(disabled && {
                                    opacity: 0.5,
                                }),
                            }}
                        >
                            {item.type !== 'audio' ? (
                                item.cachedThumb ? (
                                    <img
                                        src={item.cachedThumb}
                                        alt={item.id.toString()}
                                        loading="lazy"
                                        height={150}
                                        width={150}
                                        onClick={() => {
                                            handleMediaClicked(item);
                                        }}
                                    />
                                ) : thumbnails.find(thumbnail => thumbnail.thumbId === item.id && thumbnail.blob) ? (
                                    <img
                                        src={URL.createObjectURL(
                                            thumbnails.find(thumbnail => thumbnail.thumbId === item.id && thumbnail.blob).blob,
                                        )}
                                        alt={item.id.toString()}
                                        loading="lazy"
                                        height={150}
                                        width={150}
                                        onClick={() => {
                                            handleMediaClicked(item);
                                        }}
                                    />
                                ) : (
                                    <Skeleton />
                                )
                            ) : (
                                <Grid
                                    container
                                    justifyContent="center"
                                    alignItems="center"
                                    sx={{
                                        backgroundColor: theme.palette.grey[300],
                                        height: '100%',
                                    }}
                                    onClick={() => handleMediaClicked(item)}
                                >
                                    <Grid item xs={12} sx={{ paddingBottom: theme.spacing(5) }}>
                                        <Grid container justifyContent="center" alignItems="center">
                                            <Grid item xs={12}>
                                                <div style={{ textAlign: 'center' }}>
                                                    <PlayCircleIcon
                                                        onClick={e => {
                                                            e.stopPropagation();
                                                            if (item.src && !disabled) {
                                                                setSelectedAudioUrl(item.src);
                                                                setPlayerOpen(true);
                                                            }
                                                        }}
                                                        fontSize="large"
                                                        sx={{
                                                            ':hover': {
                                                                color: theme.palette.secondary.main,
                                                            },
                                                            height: theme.spacing(4),
                                                        }}
                                                    />
                                                </div>
                                            </Grid>
                                            {/* <Grid item xs={12}>
                                                <div style={{ textAlign: 'center' }}>
                                                    <Typography variant="body2" sx={{ color: theme.palette.grey[600] }}>
                                                        {secondsToMinutesAndSecondsString(item.duration || 0)}
                                                    </Typography>
                                                </div>
                                            </Grid> */}
                                        </Grid>
                                    </Grid>
                                </Grid>
                            )}
                            <ImageListItemBar
                                title={moment(item.createdAt).calendar({
                                    sameDay: '[Today]',
                                    nextDay: '[Tomorrow]',
                                    nextWeek: 'MMM Mo',
                                    lastDay: '[Yesterday]',
                                    lastWeek: 'MMM Mo',
                                    sameElse: 'MMM Mo',
                                })}
                                subtitle={
                                    <Stack direction="column" spacing={0.5}>
                                        <Stack direction="row" spacing={0.5} alignItems="center">
                                            <FavoriteIcon sx={{ fontSize: '8pt' }} />
                                            <div>{item.counters.likesCount.toLocaleString()} Likes</div>
                                        </Stack>
                                        <Stack direction="row" spacing={0.5} alignItems="center">
                                            <WatchIcon sx={{ fontSize: '8pt' }} />
                                            <div>{item.duration ? secondsToMinutesAndSecondsString(item.duration) : '-'}</div>
                                        </Stack>
                                    </Stack>
                                }
                                actionIcon={mediaTypeIcon(item.type, theme)}
                                onClick={() => {
                                    handleMediaClicked(item);
                                }}
                                sx={{
                                    backgroundColor: medias.find(media => media.id === item.id)
                                        ? alpha(theme.palette.primary.main, 0.8)
                                        : '#00000080',
                                    '.MuiImageListItemBar-actionIcon': {
                                        marginRight: theme.spacing(0.5),
                                    },
                                    '.MuiImageListItemBar-title': {
                                        ...(!isLargeScreen && {
                                            fontSize: '0.8rem',
                                        }),
                                    },
                                    '.MuiImageListItemBar-titleWrap': {
                                        padding: theme.spacing(1),
                                    },
                                }}
                            />
                        </ImageListItem>
                    ))}
            </ImageList>
            <AudioPlayerDialog open={playerOpen} setOpen={setPlayerOpen} url={selectedAudioUrl} getAudio={getAudio} />
        </>
    );
};

export default MessageUsersFormAddMediasSelectorImageList;
