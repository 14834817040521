import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import MouseIcon from '@mui/icons-material/Mouse';
import SubscriptionsIcon from '@mui/icons-material/Subscriptions';
import { Grid, Theme, Typography } from '@mui/material';
import dinero from 'dinero.js';
// import WarningIcon from '@mui/icons-material/Warning';
import PersonIcon from '@mui/icons-material/Person';
import StarIcon from '@mui/icons-material/Star';
import moment from 'moment';
import { useDialog } from 'muibox';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import useAxios from '../../../../../../hooks/useAxios';
import { OnlyFansMetrics } from '../../../../../../hooks/useSextforceMetricsCampaignsOverview';
import { SettingsContext } from '../../../../../../store/SettingsContext';
import { UserContext } from '../../../../../../store/UserContext';
import { d2f, handleHttpError } from '../../../../../../utils/common';
import OverviewCard from '../../../../../common/OverviewCard';
import OverviewCardSmall from '../../../../../common/OverviewCardSmall';
import PriceCardWithGoal from '../../../../../common/PriceCardWithGoal';
import SextforceMetricsEarningsSettingsCard from '../../SextforceMetricsEarningsSettingsCard';
import SextforceMetricsShareDialog from '../../SextforceMetricsShareDialog';
import SextforceMetricsSpendingTimeline from '../../SextforceMetricsSpendingTimeline';
import { parseCampaignStats } from '../../statsHelpers';
import SextforceMetricsTrialLink from '../../trials/details/SextforceMetricsTrialLink';
import SextforceMetricsTrialsOverviewGridEdit from '../../trials/overview/SextforceMetricsTrialsOverviewGridEdit';
import { TotalSales } from './SextforceMetricsCampaign';

type Props = {
    subscriber: any;
    promoCampaign: OnlyFansMetrics;
    promoCampaignCounters: any;
    promoCampaignCountersLoading: boolean;
    promoCampaignTotalSales: TotalSales;
    promoCampaignSales: any;
    promoCampaignSalesLoading: boolean;
    showEarningsWithSubscriptions: boolean;
    setShowEarningsWithSubscriptions: (value: boolean) => void;
    showEarningsAsGross: boolean;
    setShowEarningsAsGross: (value: boolean) => void;
    suspiciousUsersCount: number;
    goalSubs?: number;
    goalEarnings?: number;
    theme: Theme;
};

const SextforceMetricsCampaignOverview = (props: Props) => {
    const {
        subscriber,
        promoCampaign,
        promoCampaignCounters,
        promoCampaignCountersLoading,
        promoCampaignTotalSales,
        promoCampaignSales,
        promoCampaignSalesLoading,
        showEarningsWithSubscriptions,
        setShowEarningsWithSubscriptions,
        showEarningsAsGross,
        setShowEarningsAsGross,
        suspiciousUsersCount,
        goalSubs,
        goalEarnings,
        theme,
    } = props;

    const userContext = useContext(UserContext);
    const settingsContext = useContext(SettingsContext);
    const params = useParams();
    const dialog = useDialog();
    const axios = useAxios();

    const [metricData, setMetricData] = useState<OnlyFansMetrics | null>(null);

    useEffect(() => {
        setMetricData(promoCampaign);
    }, [promoCampaign]);

    const [editSettingsDialogOpen, setEditSettingsDialogOpen] = useState(false);
    const [shareDialogOpen, setShareDialogOpen] = useState(false);

    const stats = useMemo(
        () => parseCampaignStats(promoCampaignSales, promoCampaignCounters, promoCampaignTotalSales, showEarningsWithSubscriptions),
        [promoCampaignSales, promoCampaignCounters, promoCampaignTotalSales, showEarningsWithSubscriptions],
    );

    // ** SAVE SETTINGS **
    const [isSavingSettings, setIsSavingSettings] = useState<boolean>(false);

    const saveSettings = (
        campaignId: string,
        platform: string,
        associatedUsername: string,
        goalSpent: number,
        goalSubs: number,
        callBack: () => void,
    ) => {
        const doUpdate = async () => {
            if (userContext.jwtToken && settingsContext.apiKey && 'userId' in params && params.userId && subscriber) {
                const query: string = `${settingsContext.routes.metrics.base}${params.userId}/promocampaigns/${campaignId}`;

                setIsSavingSettings(true);

                const body: any = {
                    ...(platform !== '' && { platform }),
                    ...(associatedUsername !== '' && { associatedUsername }),
                    ...(goalSpent !== 0 && { goalSpent }),
                    ...(goalSubs !== 0 && { goalSubs }),
                };

                // Update campaign settings
                const updatedMetric: OnlyFansMetrics | void = await axios
                    .put(query, body)
                    .then(response => response.data)
                    .catch(error => {
                        console.error(error);
                        setIsSavingSettings(false);
                        handleHttpError(error, dialog);
                    });

                if (updatedMetric && metricData) {
                    if (updatedMetric.settings) {
                        metricData.settings = updatedMetric.settings;
                    } else {
                        metricData.settings = undefined;
                    }

                    if (updatedMetric.payload) {
                        metricData.payload = updatedMetric.payload;
                    } else {
                        metricData.payload = null;
                    }

                    if (updatedMetric.roi) {
                        metricData.roi = updatedMetric.roi;
                    } else {
                        metricData.roi = undefined;
                    }

                    if (updatedMetric.associatedUsername) {
                        metricData.associatedUsername = updatedMetric.associatedUsername;
                    } else {
                        metricData.associatedUsername = undefined;
                    }

                    setMetricData(metricData);
                }

                setIsSavingSettings(false);
                callBack();
            }
        };

        doUpdate();
    };

    return (
        <>
            <Grid container spacing={1} flexGrow={0} justifyContent="center" sx={{ marginBottom: theme.spacing(1) }}>
                {metricData && metricData.payload && metricData.payload.lastUpdateAt && (
                    <Grid item xs={12}>
                        <OverviewCardSmall
                            title="Last Updated"
                            value={
                                <span
                                    style={{
                                        color:
                                            moment(metricData.payload.lastUpdateAt).diff(moment(), 'days') < 0
                                                ? theme.palette.error.dark
                                                : theme.palette.success.dark,
                                    }}
                                >
                                    {moment(metricData.payload.lastUpdateAt).format('L hh:mm a')}
                                </span>
                            }
                            format="plain"
                            theme={theme}
                            loading={!metricData}
                        />
                    </Grid>
                )}
                <Grid item xs={12}>
                    <SextforceMetricsEarningsSettingsCard
                        metricData={metricData}
                        showEarningsWithSubscriptions={showEarningsWithSubscriptions}
                        setShowEarningsWithSubscriptions={setShowEarningsWithSubscriptions}
                        showEarningsAsGross={showEarningsAsGross}
                        setShowEarningsAsGross={setShowEarningsAsGross}
                        setEditSettingsDialogOpen={setEditSettingsDialogOpen}
                        setWelcomeMessageDialogOpen={() => {}}
                        setShareDialogOpen={setShareDialogOpen}
                    />

                    {editSettingsDialogOpen && metricData && (
                        <SextforceMetricsTrialsOverviewGridEdit
                            metricData={metricData}
                            onClose={() => {
                                setEditSettingsDialogOpen(false);
                            }}
                            open={editSettingsDialogOpen}
                            saveSettings={saveSettings}
                            isSavingSettings={isSavingSettings}
                        />
                    )}

                    {shareDialogOpen && metricData && (
                        <SextforceMetricsShareDialog
                            open={shareDialogOpen}
                            onClose={() => {
                                setShareDialogOpen(false);
                            }}
                            subscriber={subscriber}
                            metricData={metricData}
                        />
                    )}
                </Grid>
                <Grid item xs={12} sm={4} md={3}>
                    <OverviewCard
                        title="Subscribed"
                        value={stats.subscribed}
                        {...(goalSubs && { goal: goalSubs })}
                        loading={promoCampaignCountersLoading}
                        icon={<SubscriptionsIcon fontSize="large" htmlColor={theme.palette.success.dark} />}
                        theme={theme}
                    />
                </Grid>
                <Grid item xs={12} sm={4} md={3}>
                    <OverviewCard
                        title="Clicked"
                        value={stats.clicked}
                        loading={promoCampaignCountersLoading}
                        icon={<MouseIcon fontSize="large" htmlColor={theme.palette.success.dark} />}
                        theme={theme}
                    />
                </Grid>
                <Grid item xs={12} sm={4} md={3}>
                    {/* <OverviewCard
                    title="Suspicious Users"
                    value={suspiciousUsersCount}
                    loading={promoCampaignCountersLoading}
                    icon={<WarningIcon fontSize="large" htmlColor={theme.palette.warning.dark} />}
                    theme={theme}
                /> */}
                    <OverviewCard
                        title="Spenders"
                        value={stats.spenders}
                        loading={promoCampaignCountersLoading}
                        icon={<StarIcon fontSize="large" htmlColor={theme.palette.success.dark} />}
                        theme={theme}
                    />
                </Grid>
                <Grid item xs={12} sm={4} md={3}>
                    <PriceCardWithGoal
                        title={
                            <Typography variant="inherit" textAlign="right">
                                {showEarningsWithSubscriptions ? 'Earnings with Subs.' : 'Earnings without Subs.'}{' '}
                                <small style={{ color: theme.palette.text.secondary }}>{showEarningsAsGross ? '(gross)' : '(net)'}</small>
                            </Typography>
                        }
                        value={dinero({
                            amount: Math.trunc(showEarningsAsGross ? stats.earningsGross : stats.earningsNet),
                            currency: 'USD',
                        })}
                        {...(goalEarnings && {
                            goal: dinero({
                                amount: Math.trunc(d2f(goalEarnings) * 100 * (showEarningsAsGross ? 1 : 0.8)),
                                currency: 'USD',
                            }),
                        })}
                        loading={promoCampaignSalesLoading}
                        icon={<AttachMoneyIcon fontSize="large" htmlColor={theme.palette.success.dark} />}
                        theme={theme}
                    />
                </Grid>
                <Grid item xs={12} sm={4} md={3}>
                    {stats.earningsGross === 0 && (
                        <OverviewCard
                            title={
                                <span>
                                    Cost Per Fan <small style={{ color: theme.palette.text.secondary }}>(net)</small>
                                </span>
                            }
                            value={<span style={{ color: theme.palette.text.secondary }}>n/a</span>}
                            loading={promoCampaignCountersLoading}
                            icon={<PersonIcon fontSize="large" htmlColor={theme.palette.success.dark} />}
                            theme={theme}
                        />
                    )}
                    {stats.earningsGross !== 0 && (
                        <PriceCardWithGoal
                            title={
                                <span>
                                    Cost Per Fan <small style={{ color: theme.palette.text.secondary }}>(net)</small>
                                </span>
                            }
                            value={dinero({
                                amount: stats.cpf,
                                currency: 'USD',
                            })}
                            loading={promoCampaignSalesLoading}
                            icon={<PersonIcon fontSize="large" htmlColor={theme.palette.success.dark} />}
                            theme={theme}
                        />
                    )}
                </Grid>
                <Grid item xs={12} sm={4} md={3}>
                    {stats.earningsGross === 0 && (
                        <OverviewCard
                            title={
                                <span>
                                    Cost Per Click <small style={{ color: theme.palette.text.secondary }}>(net)</small>
                                </span>
                            }
                            value={<span style={{ color: theme.palette.text.secondary }}>n/a</span>}
                            loading={promoCampaignCountersLoading}
                            icon={<PersonIcon fontSize="large" htmlColor={theme.palette.success.dark} />}
                            theme={theme}
                        />
                    )}
                    {stats.earningsGross !== 0 && (
                        <PriceCardWithGoal
                            title={
                                <span>
                                    Cost Per Click <small style={{ color: theme.palette.text.secondary }}>(net)</small>
                                </span>
                            }
                            value={dinero({
                                amount: stats.cpc,
                                currency: 'USD',
                            })}
                            loading={promoCampaignSalesLoading}
                            icon={<PersonIcon fontSize="large" htmlColor={theme.palette.success.dark} />}
                            theme={theme}
                        />
                    )}
                </Grid>
                <Grid item xs={12} sm={4} md={3}>
                    <OverviewCard
                        title={<span>Fan To Spenders Conversion</span>}
                        value={stats.conversionRateSubscriberToSale.toFixed(2) + '%'}
                        loading={promoCampaignSalesLoading}
                        icon={<PersonIcon fontSize="large" htmlColor={theme.palette.success.dark} />}
                        theme={theme}
                    />
                </Grid>
                <Grid item xs={12} sm={4} md={3}>
                    <OverviewCard
                        title={<span>Click To Fan Conversion</span>}
                        value={stats.conversionRateClickToSubscriber.toFixed(2) + '%'}
                        loading={promoCampaignSalesLoading}
                        icon={<PersonIcon fontSize="large" htmlColor={theme.palette.success.dark} />}
                        theme={theme}
                    />
                </Grid>
            </Grid>

            <Grid container spacing={1} flexGrow={0} justifyContent="center" sx={{ marginBottom: theme.spacing(1) }}>
                <Grid item xs={12} md={6} lg={3}>
                    <OverviewCardSmall
                        title="Name"
                        value={metricData && metricData.payload && metricData.payload.name}
                        format={'plain'}
                        loading={!promoCampaign}
                        theme={theme}
                    />
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                    <OverviewCardSmall
                        title="Created On"
                        value={
                            metricData &&
                            metricData.payload &&
                            metricData.payload.createdAt &&
                            moment(metricData.payload.createdAt).format('L')
                        }
                        format={'plain'}
                        loading={!metricData}
                        theme={theme}
                    />
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                    <OverviewCardSmall
                        title="ID"
                        value={metricData && metricData.foreignId}
                        format={'plain'}
                        loading={!metricData}
                        theme={theme}
                    />
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                    <OverviewCardSmall
                        title="Code"
                        value={metricData && metricData.payload && metricData.payload.campaignCode}
                        format={'plain'}
                        loading={!promoCampaign}
                        theme={theme}
                    />
                </Grid>
            </Grid>

            {metricData && metricData.payload && metricData.payload.firstSaleDate && metricData.payload.lastSaleDate && (
                <Grid container spacing={1} flexGrow={0} justifyContent="center" sx={{ marginBottom: theme.spacing(1) }}>
                    <Grid item xs={12}>
                        <SextforceMetricsSpendingTimeline
                            promoStartDate={new Date(metricData.payload.createdAt)}
                            firstSpendDate={new Date(metricData.payload.firstSaleDate)}
                            lastSpendDate={new Date(metricData.payload.lastSaleDate)}
                        />
                    </Grid>
                </Grid>
            )}

            {metricData && metricData.payload && metricData.payload.campaignCode && (
                <Grid container spacing={1} flexGrow={0} justifyContent="center" sx={{ marginBottom: theme.spacing(2) }}>
                    <Grid item xs={12}>
                        <SextforceMetricsTrialLink
                            trialUrl={`https://onlyfans.com/${subscriber.username}/c${metricData.payload.campaignCode}`}
                            theme={theme}
                        />
                    </Grid>
                </Grid>
            )}
        </>
    );
};

export default SextforceMetricsCampaignOverview;
