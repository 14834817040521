import AddIcon from '@mui/icons-material/Add';
import RefreshIcon from '@mui/icons-material/Refresh';
import SortByAlphaIcon from '@mui/icons-material/SortByAlpha';
import { Alert, Grid, IconButton, MenuItem, Select, SxProps, Theme, Tooltip, useTheme } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useCallback, useEffect, useState } from 'react';
import useSubscriber from '../../hooks/useSubscriber';
import useSubscriberLists from '../../hooks/useSubscriberLists';
import { OnlyFansLists } from '../../types/onlyFansTypes';
import SextforceLiveStreamAddTippersToListCreateListDialog from '../services/hostess/liveStreams/addTippersToList/SextforceLiveStreamAddTippersToListCreateListDialog';

type Props = {
    targetList: { id: number | OnlyFansLists.Type; name: string } | undefined | null;
    setTargetList: (targetLists: { id: number | OnlyFansLists.Type; name: string } | undefined | null) => void;
    includeBuiltInLists: boolean;
    size?: 'small' | 'medium';
    disabled?: boolean;
    sx?: SxProps<Theme>;
};

const OnlyFansListsSelector = (props: Props) => {
    const { targetList, setTargetList, includeBuiltInLists, size, disabled, sx } = props;
    const theme: Theme = useTheme();
    const { enqueueSnackbar } = useSnackbar();

    const { data: subscriber, isLoading: isSubscriberLoading } = useSubscriber();
    const {
        data: lists,
        isLoading: listsLoading,
        isFetching: listsFetching,
        refetch: refetchLists,
        createList,
        isCreatingList,
    } = useSubscriberLists(true);

    const [isCreateListDialogOpen, setIsCreateListDialogOpen] = useState(false);

    const handleCreateList = (listName: string) => {
        createList(listName).then(newList => {
            if (!newList) {
                enqueueSnackbar('Failed to create list!', { variant: 'error' });
                return;
            }

            enqueueSnackbar('List created!', { variant: 'success' });

            setTargetList(newList);

            setIsCreateListDialogOpen(false);
        });
    };

    const [sortLists, setSortLists] = useState<boolean>(true);
    const [sortedLists, setSortedLists] = useState<OnlyFansLists.List[] | void | undefined>([]);

    // Memoize the sorting function
    const sortListsData = useCallback(
        (data: OnlyFansLists.List[]) => {
            if (!sortLists) return data;

            return [...data].sort((a, b) => {
                const x = a.name.toLowerCase();
                const y = b.name.toLowerCase();

                return x < y ? -1 : x > y ? 1 : 0;
            });
        },
        [sortLists],
    );

    // Update sorted lists whenever lists or sort preference changes
    useEffect(() => {
        if (!lists) {
            setSortedLists([]);
            return;
        }

        if (!includeBuiltInLists) {
            // Filter out lists who's id is not a number
            const filteredLists = lists.filter(list => typeof list.id === 'number');
            const newSortedLists = sortListsData(filteredLists);

            setSortedLists(newSortedLists);

            return;
        }

        const newSortedLists = sortListsData(lists);

        setSortedLists(newSortedLists);
    }, [lists, sortListsData, includeBuiltInLists]);

    const isLoading = listsLoading || listsFetching;

    return (
        <Grid container spacing={1} flexGrow={0} alignItems="center" justifyContent="center">
            <Grid item xs>
                {sortedLists && typeof sortedLists !== 'undefined' ? (
                    <Select
                        value={!isLoading && !isCreatingList && targetList ? targetList.id : -1}
                        size={size}
                        disabled={disabled}
                        fullWidth
                        onChange={event => {
                            setTargetList(
                                sortedLists.find(list => list.id === event.target.value)
                                    ? {
                                          id: event.target.value as number | OnlyFansLists.Type,
                                          name: sortedLists.find(list => list.id === event.target.value)?.name || '',
                                      }
                                    : undefined,
                            );
                        }}
                        sx={{ width: '100%', ...sx }}
                    >
                        {(isLoading || isCreatingList) && (
                            <MenuItem value={-1} disabled sx={{ opacity: 0.5 }}>
                                <em>Loading...</em>
                            </MenuItem>
                        )}
                        {!isLoading && !isCreatingList && lists && lists.length === 0 && (
                            <MenuItem value={-1} disabled sx={{ opacity: 0.5 }}>
                                <em>No Lists Found</em>
                            </MenuItem>
                        )}
                        {!isLoading && !isCreatingList && lists && lists.length > 0 && (
                            <MenuItem value={-1} disabled sx={{ opacity: 0.5 }}>
                                <em>Select a List</em>
                            </MenuItem>
                        )}
                        {!isLoading &&
                            !isCreatingList &&
                            sortedLists &&
                            sortedLists.map((list: any) => (
                                <MenuItem value={list.id} key={list.id}>
                                    <Grid container spacing={0} sx={{ margin: 0, padding: 0 }}>
                                        <Grid item xs>
                                            {list.name}
                                        </Grid>
                                        <Grid item xs="auto" sx={{ paddingRight: 2 }}>
                                            <span style={{ color: theme.palette.grey[700] }}>
                                                {(list.usersCount as number).toLocaleString()} Users
                                            </span>
                                        </Grid>
                                    </Grid>
                                </MenuItem>
                            ))}
                    </Select>
                ) : (
                    <Alert severity="error">Could not load lists!</Alert>
                )}
            </Grid>
            <Grid item xs={'auto'}>
                <Tooltip title="Sort lists alphabetically or custom like on OnlyFans">
                    <span>
                        <IconButton
                            color={sortLists ? 'primary' : 'secondary'}
                            disabled={isLoading || isCreatingList}
                            onClick={() => {
                                setSortLists(!sortLists);
                            }}
                        >
                            <SortByAlphaIcon fontSize="large" />
                        </IconButton>
                    </span>
                </Tooltip>
            </Grid>
            <Grid item xs={'auto'}>
                <Tooltip title="Reload Lists from OnlyFans">
                    <span>
                        <IconButton
                            color="secondary"
                            disabled={isLoading || isCreatingList}
                            onClick={() => {
                                refetchLists();
                            }}
                        >
                            <RefreshIcon fontSize="large" />
                        </IconButton>
                    </span>
                </Tooltip>
            </Grid>
            <Grid item xs={'auto'}>
                <Tooltip title="Create new OnlyFans list">
                    <span>
                        <IconButton
                            color="success"
                            disabled={isSubscriberLoading || !subscriber || isCreatingList || disabled}
                            onClick={() => {
                                setIsCreateListDialogOpen(true);
                            }}
                        >
                            <AddIcon fontSize="large" />
                        </IconButton>
                    </span>
                </Tooltip>
                <SextforceLiveStreamAddTippersToListCreateListDialog
                    open={isCreateListDialogOpen}
                    onClose={() => setIsCreateListDialogOpen(false)}
                    handleSubmit={handleCreateList}
                    theme={theme}
                />
            </Grid>
        </Grid>
    );
};

export default OnlyFansListsSelector;
