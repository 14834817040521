import { useDialog } from 'muibox';
import { useContext } from 'react';
import { useInfiniteQuery } from 'react-query';
import { SettingsContext } from '../store/SettingsContext';
import { UserContext } from '../store/UserContext';
import { handleHttpError } from '../utils/common';
import useAxios from './useAxios';
import useSubscriber from './useSubscriber';
import { OnlyFansVaultMedias } from './useSubscriberVaultAlbums';

export enum ReleaseFormPartnerSource {
    Document = 'document',
    Tag = 'tag',
}

export enum ReleaseFormType {
    Document = 'document',
    User = 'user',
}

export enum ReleaseFormStatus {
    Approved = 'approved',
}

export interface ReleaseFormUser {
    view: ReleaseFormView;
    id: number;
    name: string;
    username: string;
    isVerified: boolean;
    avatar: null;
    avatarThumbs: null;
    ivStatus: null;
    isFromGuest: boolean;
}

export enum ReleaseFormView {
    Tu = 'tu',
}

export type VaultMediaResponseList = {
    id: number;
    canView: boolean;
    hasError: boolean;
    createdAt: Date;
    thumb: string;
    cachedThumb: string | null;
    src?: string;
    duration?: number;
    type: string;
    counters: {
        likesCount: number;
        tipsSumm: number;
    };
    mentionedUsers?: {
        view: string;
        avatar: string;
        avatarThumbs: {
            c50: string;
            c144: string;
        };
        header: string;
        headerSize: {
            width: number;
            height: number;
        };
        headerThumbs: {
            w480: string;
            w760: string;
        };
        id: number;
        name: string;
        username: string;
        hasNotViewedStory: boolean;
        isVerified: boolean;
        hasStream: boolean;
        hasStories: boolean;
        isRestricted: boolean;
        hasPromotion: boolean;
        isFree: boolean;
    }[];
    releaseForms?: {
        id: number;
        name: string;
        partnerSource: ReleaseFormPartnerSource;
        type: ReleaseFormType;
        status: ReleaseFormStatus;
        code: null;
        signature: null;
        date: Date;
        approve_date: Date;
        user: ReleaseFormUser;
    }[];
};

export type VaultMediaResponse = {
    list: VaultMediaResponseList[];
    hasMore: boolean;
    nextOffset: number | null;
};

const useSubscriberVaultMedias = (
    service: string,
    albumId: number,
    type: OnlyFansVaultMedias.MediaType | null,
    sortDate: Date | null,
    sortType: OnlyFansVaultMedias.SortType,
    sortOrder: OnlyFansVaultMedias.SortOrder,
    enabled: boolean = true,
) => {
    const userContext = useContext(UserContext);
    const settingsContext = useContext(SettingsContext);
    const { data: subscriber } = useSubscriber();
    const axios = useAxios();
    const dialog = useDialog();

    const fetchVaultMedias = async (
        albumId: number,
        type: OnlyFansVaultMedias.MediaType | null,
        sortDate: Date | null,
        sortType: OnlyFansVaultMedias.SortType,
        sortOrder: OnlyFansVaultMedias.SortOrder,
        offset: number,
    ): Promise<VaultMediaResponse> => {
        const query: string = `${settingsContext.routes.onlyFans.base}${subscriber._id}/vaultMedias?${new URLSearchParams({
            offset: offset.toString(),
            ...(albumId && { albumId: albumId.toString() }),
            ...(type && { type }),
            ...(sortDate && { sortDate: sortDate.toISOString() }),
            sortType,
            sortOrder,
        })}`;

        return axios
            .get(query)
            .then(response => response.data)
            .catch(error => {
                console.error(error);
                handleHttpError(error, dialog);

                return { list: [], hasMore: false, nextOffset: null };
            });
    };

    return useInfiniteQuery(
        ['vaultMedias', service, albumId, type, sortDate, sortType, sortOrder, subscriber],
        params => fetchVaultMedias(albumId, type, sortDate, sortType, sortOrder, params.pageParam || 0),
        {
            getNextPageParam: lastPage => lastPage?.nextOffset,
            refetchOnWindowFocus: false,
            staleTime: Infinity,
            enabled: subscriber && userContext.jwtToken && settingsContext.apiKey && enabled,
        },
    );
};

export default useSubscriberVaultMedias;
