import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ClearIcon from '@mui/icons-material/Clear';
import RefreshIcon from '@mui/icons-material/Refresh';
import SortByAlphaIcon from '@mui/icons-material/SortByAlpha';
import {
    Alert,
    Checkbox,
    Chip,
    CircularProgress,
    Grid,
    IconButton,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Paper,
    SxProps,
    Theme,
    Tooltip,
    Typography,
} from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { FixedSizeList, ListChildComponentProps } from 'react-window';
import useSubscriberLists from '../../hooks/useSubscriberLists';
import { OnlyFansLists } from '../../types/onlyFansTypes';

type Props = {
    targetLists: { id: number | OnlyFansLists.Type; name: string }[];
    setTargetLists: (targetLists: { id: number | OnlyFansLists.Type; name: string }[]) => void;
    listItemBackgroundColor?: string;
    sx?: SxProps<Theme>;
};

const OnlyFansListsMutiSelector = (props: Props) => {
    const { targetLists, setTargetLists, listItemBackgroundColor, sx } = props;

    const { data: lists, isLoading: listsLoading, isFetching: listsFetching, refetch: refetchLists } = useSubscriberLists(true);

    const [sortLists, setSortLists] = useState<boolean>(true);
    const [sortedLists, setSortedLists] = useState<OnlyFansLists.List[] | void | undefined>([]);

    // Memoize the sorting function
    const sortListsData = useCallback(
        (data: OnlyFansLists.List[]) => {
            if (!sortLists) return data;

            return [...data].sort((a, b) => {
                const x = a.name.toLowerCase();
                const y = b.name.toLowerCase();
                return x < y ? -1 : x > y ? 1 : 0;
            });
        },
        [sortLists],
    );

    // Handle list item selection
    const handleListItemClicked = useCallback(
        (id: number | OnlyFansLists.Type) => {
            const alreadyChecked = targetLists.find(item => item.id === id);

            if (alreadyChecked) {
                setTargetLists(targetLists.filter(item => item.id !== id));
            } else if (lists) {
                const listName = lists.find(item => item.id === id)?.name;
                if (listName) {
                    setTargetLists([...targetLists, { id, name: listName }]);
                }
            }
        },
        [lists, targetLists, setTargetLists],
    );

    // Update sorted lists whenever lists or sort preference changes
    useEffect(() => {
        if (!lists) {
            setSortedLists([]);
            return;
        }

        const newSortedLists = sortListsData(lists);
        setSortedLists(newSortedLists);
    }, [lists, sortListsData]);

    const isLoading = listsLoading || listsFetching;

    const Row = ({ index, style }: ListChildComponentProps) => {
        const list = sortedLists ? sortedLists[index] : null;
        if (!list) return null;

        return (
            <ListItem
                disablePadding
                key={list.id}
                sx={{
                    backgroundColor:
                        targetLists && targetLists.find(item => item.id === list.id)
                            ? listItemBackgroundColor || 'text.gray.200'
                            : 'transparent',
                    ...style,
                }}
            >
                <ListItemButton
                    role={undefined}
                    dense
                    onClick={() => {
                        handleListItemClicked(list.id);
                    }}
                >
                    <ListItemIcon>
                        <Checkbox
                            tabIndex={-1}
                            disableRipple
                            checked={targetLists && targetLists.find(item => item.id === list.id) ? true : false}
                        />
                    </ListItemIcon>
                    <ListItemText primary={list.name} secondary={`Users: ${(list.usersCount as number).toLocaleString()}`} />
                </ListItemButton>
            </ListItem>
        );
    };

    return (
        <Grid container spacing={1} flexGrow={0} alignItems="center" justifyContent="center" sx={{ marginBottom: 1 }}>
            <Grid item xs>
                <Chip
                    color={!targetLists || targetLists.length === 0 ? 'error' : 'primary'}
                    icon={<CheckCircleIcon />}
                    label={`${targetLists ? targetLists.length : 0} selected`}
                />
            </Grid>
            <Grid item xs={'auto'}>
                <Tooltip title="Clear Selection">
                    <IconButton
                        color="error"
                        onClick={() => {
                            setTargetLists([]);
                        }}
                    >
                        <ClearIcon fontSize="large" />
                    </IconButton>
                </Tooltip>
            </Grid>
            <Grid item xs={'auto'}>
                <Tooltip title="Sort lists alphabetically or custom like on OnlyFans">
                    <IconButton
                        onClick={() => {
                            setSortLists(!sortLists);
                        }}
                    >
                        <SortByAlphaIcon fontSize="large" color={sortLists ? 'primary' : 'secondary'} />
                    </IconButton>
                </Tooltip>
            </Grid>
            <Grid item xs={'auto'}>
                <Tooltip title="Reload Lists from OnlyFans">
                    <IconButton
                        color="secondary"
                        onClick={() => {
                            refetchLists();
                        }}
                    >
                        <RefreshIcon fontSize="large" />
                    </IconButton>
                </Tooltip>
            </Grid>
            <Grid item xs={12}>
                {isLoading ? (
                    <Paper
                        variant="outlined"
                        sx={{
                            height: 200,
                            overflow: 'auto',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        <Typography variant="caption" textAlign="center">
                            Retrieving your Lists...
                            <br />
                            <br />
                            This might take a while depending on how many Lists you have and OnlyFans servers speed 🤷‍♂️
                            <br />
                            <br />
                            <CircularProgress size={24} />
                        </Typography>
                    </Paper>
                ) : (
                    <>
                        {sortedLists && typeof sortedLists !== 'undefined' ? (
                            <Paper variant="outlined" sx={{ height: 200, overflow: 'auto', ...sx }}>
                                <FixedSizeList
                                    height={200}
                                    width="100%"
                                    itemSize={57}
                                    itemCount={sortedLists.length}
                                    itemData={sortedLists}
                                >
                                    {Row}
                                </FixedSizeList>
                            </Paper>
                        ) : (
                            <Alert severity="error">Could not load lists!</Alert>
                        )}
                    </>
                )}
            </Grid>
        </Grid>
    );
};

export default OnlyFansListsMutiSelector;
