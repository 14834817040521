import { Card, CardContent, Checkbox, FormControlLabel, Grid, Typography } from '@mui/material';
import { useEffect } from 'react';
import OnlyFansTagCreatorsSelector from '../../../common/OnlyFansTagCreatorsSelector';

type Props = {
    subscriber: any;
    tagCreators: boolean;
    setTagCreators: (tagCreators: boolean) => void;
    taggedCreators: {
        id: number;
        name: string;
    }[];
    setTaggedCreators: React.Dispatch<
        React.SetStateAction<
            {
                id: number;
                name: string;
            }[]
        >
    >;
    taggedReleaseForms: {
        id: number;
        name: string;
    }[];
    setTaggedReleaseForms: React.Dispatch<
        React.SetStateAction<
            {
                id: number;
                name: string;
            }[]
        >
    >;
    session?: any;
    disabled?: boolean;
};

const MessageUsersTagCreators = (props: Props) => {
    const {
        subscriber,
        tagCreators,
        setTagCreators,
        taggedCreators,
        setTaggedCreators,
        taggedReleaseForms,
        setTaggedReleaseForms,
        session,
        disabled,
    } = props;

    useEffect(() => {
        if (session && session.payload && (session.payload.taggedCreators || session.payload.taggedReleaseForms)) {
            setTagCreators(session.payload.tagCreators);
        }

        if (session && session.payload && session.payload.taggedCreators) {
            setTaggedCreators(session.payload.taggedCreators);
        }

        if (session && session.payload && session.payload.taggedReleaseForms) {
            setTaggedReleaseForms(session.payload.taggedReleaseForms);
        }
    }, [session, setTagCreators, setTaggedCreators, setTaggedReleaseForms]);

    return (
        <Grid container spacing={1} flexGrow={0} alignItems="center" justifyContent="center" sx={{ marginBottom: 1 }}>
            <Grid item xs={12}>
                <Card variant="elevation">
                    <CardContent>
                        <Grid container spacing={1} flexGrow={0} alignItems="center" justifyContent="center">
                            <Grid item xs>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            size="medium"
                                            checked={tagCreators}
                                            onChange={(e, checked) => {
                                                setTagCreators(checked);
                                            }}
                                        />
                                    }
                                    label={<Typography variant="h6">Tag Creators?</Typography>}
                                    disabled={disabled}
                                />
                            </Grid>

                            {tagCreators && (
                                <OnlyFansTagCreatorsSelector
                                    subscriber={subscriber}
                                    tagCreators={tagCreators}
                                    setTagCreators={setTagCreators}
                                    taggedCreators={taggedCreators}
                                    setTaggedCreators={setTaggedCreators}
                                    taggedReleaseForms={taggedReleaseForms}
                                    setTaggedReleaseForms={setTaggedReleaseForms}
                                    disabled={disabled}
                                />
                            )}
                        </Grid>
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    );
};

export default MessageUsersTagCreators;
